import React from "react";
import {
  Typography,
  LinearProgress,
  Card,
  CardContent,
} from "@material-ui/core";
import { i18n } from "../../../../translate/i18n";

const StatusImport = ({ statusImport, classes }) => {
  return (
    <>
      {statusImport?.all ? (
        <div style={{ margin: "auto", marginBottom: 12 }}>
          <Card className={classes.root}>
            <CardContent
              className={classes.content}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center", // Centraliza os itens horizontalmente
                justifyContent: "center", // Centraliza os itens verticalmente
                gap: "12px", // Adiciona espaço entre os elementos
                padding: "16px", // Adiciona padding para melhor espaçamento
              }}
            >
              <h5 className={classes.cardTitleStyles}>
                {statusImport?.this === -1
                  ? i18n.t("connections.buttons.preparing")
                  : i18n.t("connections.buttons.importing")}
              </h5>
              {statusImport?.this === -1 ? (
                <Typography component="h6" variant="h6" align="center">
                  <LinearProgress style={{ width: "100%" }} />
                </Typography>
              ) : (
                <>
                  <Typography component="h6" variant="h6" align="center">
                    {`${i18n.t(`connections.typography.processed`)} ${statusImport?.this} ${i18n.t(`connections.typography.in`)} ${statusImport?.all} ${i18n.t(`connections.typography.date`)}: ${statusImport?.date}`}
                  </Typography>
                  <LinearProgress
                    style={{ width: "100%" }}
                    variant="determinate"
                    value={(statusImport?.this / statusImport?.all) * 100}
                  />
                  <Typography variant="body2" color="textSecondary">
                    {`${Math.round((statusImport?.this / statusImport?.all) * 100)}%`}
                  </Typography>
                </>
              )}
            </CardContent>
          </Card>
        </div>
      ) : null}
    </>
  );
};

export default StatusImport;
