const initialState = {
    name: "",
    greetingMessage: "",
    complationMessage: "",
    outOfHoursMessage: "",
    ratingMessage: "",
    isDefault: false,
    token: "",
    tokenwavoip: "",
    provider: "beta",
    timeSendQueue: 0,
    sendIdQueue: 0,
    expiresInactiveMessage: "",
    expiresTicket: 0,
    timeUseBotQueues: 0,
    maxUseBotQueues: 3,
    attachment: null,
    mediaName: "",
    isOfficial: false,
    number: "",
    tokenwppoficial: "",
};

export default initialState;