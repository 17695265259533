import React from 'react';
import { Instagram } from "@material-ui/icons";
import FacebookInstagramIcon from '../../../FacebookInstagramIcon';
import baileyIcon from "../../../../assets/baileys-icon.png";

const getModelLabel = (modelType) => {
    switch (modelType) {
        case "facebook":
            return {
                label: "Facebook/Instagram",
                icon: <FacebookInstagramIcon />
            };
        case "whatsapp":
            return {
                label: "WhatsApp",
                icon: <img src={baileyIcon} alt="WhatsApp Icon" style={{ width: '24px', height: '24px' }} />
            };
        case "official":
            return {
                label: "WhatsApp Official",
                icon: <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp Official Icon" style={{ width: '24px', height: '24px' }} />
            };
        case "facebookOfficial":
            return {
                label: "Facebook Official",
                icon: <img src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg" alt="Facebook Official Icon" style={{ width: '24px', height: '24px' }} />
            };
        default:
            return {
                label: "Instagram",
                icon: <Instagram />
            };
    }
};

export default getModelLabel;
