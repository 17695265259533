import React from "react";
import { Field } from "formik";
import {
    TextField,
    Switch,
    Grid,
    useTheme,
} from "@material-ui/core";
import { i18n } from "../../../../translate/i18n";
import moment from "moment";

const ImportMessage = ({
    modelType,
    enableImportMessage,
    handleEnableImportMessage,
    importOldMessagesGroups,
    setImportOldMessagesGroups,
    closedTicketsPostImported,
    setClosedTicketsPostImported,
    importOldMessages,
    setImportOldMessages,
    importRecentMessages,
    setImportRecentMessages,
    touched,
    errors,
    classes,
}) => {
    const theme = useTheme();
    return (
        <div className={classes.importMessage}>
            <div className={classes.multFieldLine}>
                {modelType === "whatsapp" && (
                    <div className="cmp-default">
                        <Grid item>
                            <label style={{ color: "gray" }}>
                                {i18n.t("whatsappModal.form.importOldMessagesEnable")}
                            </label>
                        </Grid>
                        <Grid item>
                            <Switch
                                size="medium"
                                checked={enableImportMessage}
                                onChange={handleEnableImportMessage}
                                name="importOldMessagesEnable"
                                color="primary"
                            />
                        </Grid>
                    </div>
                )}
                {modelType === "whatsapp" && enableImportMessage && <div style={{ padding: "1rem", borderRadius: "8px", border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`, margin: "1rem 0" }}>
                    {modelType === "whatsapp" && enableImportMessage ? (
                        <>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <label style={{ color: "gray" }}>
                                        {i18n.t("whatsappModal.form.importOldMessagesGroups")}
                                    </label>
                                </Grid>
                                <Grid item>
                                    <Switch
                                        size="medium"
                                        checked={importOldMessagesGroups}
                                        onChange={(e) => setImportOldMessagesGroups(e.target.checked)}
                                        name="importOldMessagesGroups"
                                        color="primary"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 10 }}>
                                <Grid item>
                                    <label style={{ color: "gray" }}>
                                        {i18n.t("whatsappModal.form.closedTicketsPostImported")}
                                    </label>
                                </Grid>
                                <Grid item>
                                    <Switch
                                        size="medium"
                                        checked={closedTicketsPostImported}
                                        onChange={(e) => setClosedTicketsPostImported(e.target.checked)}
                                        name="closedTicketsPostImported"
                                        color="primary"
                                    />
                                </Grid>
                            </Grid>
                        </>
                    ) : null}
                    {modelType === "whatsapp" && enableImportMessage ? (
                        <Grid style={{ marginTop: 18 }} container spacing={3}>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    as={TextField}
                                    label={"Inicio da importação"}
                                    type="datetime-local"
                                    name="importOldMessages"
                                    inputProps={{
                                        max: moment().add(0, "minutes").format("YYYY-MM-DDTHH:mm"),
                                        min: moment().add(-2, "years").format("YYYY-MM-DDTHH:mm"),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={touched.importOldMessages && Boolean(errors.importOldMessages)}
                                    helperText={touched.importOldMessages && errors.importOldMessages}
                                    variant="outlined"
                                    value={moment(importOldMessages).format("YYYY-MM-DDTHH:mm")}
                                    onChange={(e) => {
                                        setImportOldMessages(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Field
                                    fullWidth
                                    as={TextField}
                                    label={"Termino da importação"}
                                    type="datetime-local"
                                    name="importRecentMessages"
                                    inputProps={{
                                        max: moment().add(0, "minutes").format("YYYY-MM-DDTHH:mm"),
                                        min: moment(importOldMessages).format("YYYY-MM-DDTHH:mm"),
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={touched.importRecentMessages && Boolean(errors.importRecentMessages)}
                                    helperText={touched.importRecentMessages && errors.importRecentMessages}
                                    variant="outlined"
                                    value={moment(importRecentMessages).format("YYYY-MM-DDTHH:mm")}
                                    onChange={(e) => {
                                        setImportRecentMessages(e.target.value);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ) : null}
                    {modelType === "whatsapp" && enableImportMessage && (
                        <span style={{ color: "red", marginTop: "1rem", display: "flex" }}>
                            {i18n.t("whatsappModal.form.importAlert")}
                        </span>
                    )}
                </div>}
            </div>

        </div>
    );
};

export default React.memo(ImportMessage);
