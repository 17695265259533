import React, { useState, useEffect, useContext } from "react";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import { makeStyles, Paper, Tabs, Tab, Tooltip } from "@material-ui/core";
import {
  Settings as OptionsIcon,
  Schedule as SchedulesIcon,
  Business as CompaniesIcon,
  AccountBalance as PlansIcon,
  Help as HelpsIcon,
  Message as MessagesIcon,
  ListAlt as TabulacaoIcon,
  Star as SatisfacaoIcon,
  Call as ChamadasIcon,
  ColorLens as TemaIcon
} from "@material-ui/icons";
import TabPanel from "../../components/TabPanel";
import SchedulesForm from "../../components/SchedulesForm";
import CompaniesManager from "../../components/CompaniesManager";
import PlansManager from "../../components/PlansManager";
import HelpsManager from "../../components/HelpsManager";
import Options from "../../components/Settings/Options";
import { i18n } from "../../translate/i18n.js";
import { toast } from "react-toastify";
import useCompanies from "../../hooks/useCompanies";
import OnlyForSuperUser from "../../components/OnlyForSuperUser";
import MessagesCount from '../../components/messages/MessagesCount';
import Tabulacao from "../../components/tabulacao/tabulacao";
import RatingOptionsComponent from '../../components/SetingsRating';
import CallsByCompany from '../../components/callPainel';
import ColorSettings from "../../components/ColorSettings";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import { Lock as LockIcon } from "@material-ui/icons";
import PublicTokenManager from "../../components/PublicTokenManager";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  mainPaper: {
    ...theme.scrollbarStyles,
    overflowY: "scroll",
    flex: 1,
    boxShadow: "none"
  },
  tab: {
    backgroundColor: theme.palette.options,
    borderRadius: 4,
    marginBottom: "1rem"
  },
  paper: {
    ...theme.scrollbarStyles,
    overflowY: "scroll",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  container: {
    width: "100%",
    maxHeight: "100%",
  },
  control: {
    padding: theme.spacing(1),
  },
  textfield: {
    width: "100%",
  },
  icon: {
    color: theme.palette.primary.mainReverseByWhite,
  },
  selectedTab: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}));

const SettingsCustom = () => {
  const classes = useStyles();
  const [tab, setTab] = useState("options");
  const [schedules, setSchedules] = useState([]);
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState([]);
  const [schedulesEnabled, setSchedulesEnabled] = useState(false);

  const { user: currentUser } = useContext(AuthContext);
  const { updateSchedules } = useCompanies();

  useEffect(() => {
    const find = async (id) => {
      const { data } = await api.request({
        url: `/companies/${id}`,
        method: 'GET'
      });
      return data;
    }

    const getAllSettings = async (params) => {
      const { data } = await api.request({
        url: '/settings',
        method: 'GET',
        params
      });
      return data;
    }
    async function findData() {
      setLoading(true);
      try {
        const companyId = localStorage.getItem("companyId");
        const company = await find(companyId);
        const settingList = await getAllSettings();

        setCompany(company);
        setSchedules(company.schedules);
        setSettings(settingList);

        if (Array.isArray(settingList)) {
          const scheduleType = settingList.find((d) => d.key === "scheduleType");
          if (scheduleType) {
            setSchedulesEnabled(scheduleType.value === "company");
          }
        }
      } catch (e) {
        toast.error(e.message || "Erro ao carregar dados.");
      }
      setLoading(false);
    }
    findData();
  }, []);

  const handleTabChange = (event, newValue) => {
    if (newValue) {
      setTab(newValue);
    }
  };

  const handleSubmitSchedules = async (data) => {
    setLoading(true);
    try {
      setSchedules(data);
      await updateSchedules({ id: company.id, schedules: data });
      toast.success("Horários atualizados com sucesso.");
    } catch (e) {
      toast.error(e);
    }
    setLoading(false);
  };

  const getSettingValue = (key) => {
    const setting = settings.find((s) => s.key === key);
    return setting ? setting.value : "";
  };

  const handleChangeSetting = async (e) => {
    const selectedValue = e.target.value;
    const settingKey = e.target.name;

    try {
      await api.put(`/settings/${settingKey}`, { value: selectedValue });
      toast.success(i18n.t("settings.success"));
    } catch (err) {
      toast.error(err);
    }
  };

  const isSuper = () => {
    return currentUser?.super;
  };

  return (
    <MainContainer className={classes.root}>
      <MainHeader>
        <Title>{i18n.t("settings.title")}</Title>
      </MainHeader>
      <Paper className={classes.mainPaper} elevation={1}>
        <Tabs
          value={tab}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="on"
          variant="scrollable"
          onChange={handleTabChange}
          className={classes.tab}
        >
          <Tooltip title="Opções">
            <Tab icon={<OptionsIcon className={classes.icon} />} value="options" className={tab === "options" ? classes.selectedTab : ""} />
          </Tooltip>
          {schedulesEnabled && (
            <Tooltip title="Horários">
              <Tab icon={<SchedulesIcon className={classes.icon} />} value="schedules" className={tab === "schedules" ? classes.selectedTab : ""} />
            </Tooltip>
          )}
          {isSuper() && (
            <Tooltip title="Empresas">
              <Tab icon={<CompaniesIcon className={classes.icon} />} value="companies" className={tab === "companies" ? classes.selectedTab : ""} />
            </Tooltip>
          )}
          {isSuper() && (
            <Tooltip title="Planos">
              <Tab icon={<PlansIcon className={classes.icon} />} value="plans" className={tab === "plans" ? classes.selectedTab : ""} />
            </Tooltip>
          )}
          {isSuper() && (
            <Tooltip title="Ajuda">
              <Tab icon={<HelpsIcon className={classes.icon} />} value="helps" className={tab === "helps" ? classes.selectedTab : ""} />
            </Tooltip>
          )}
          {isSuper() && (
            <Tooltip title="Relatórios de mensagens">
              <Tab icon={<MessagesIcon className={classes.icon} />} value="mensagens" className={tab === "mensagens" ? classes.selectedTab : ""} />
            </Tooltip>
          )}
          <Tooltip title="Tabulação">
            <Tab icon={<TabulacaoIcon className={classes.icon} />} value="tabulacao" className={tab === "tabulacao" ? classes.selectedTab : ""} />
          </Tooltip>
          <Tooltip title="Satisfação">
            <Tab icon={<SatisfacaoIcon className={classes.icon} />} value="satisfacao" className={tab === "satisfacao" ? classes.selectedTab : ""} />
          </Tooltip>
          <Tooltip title="Chamadas">
            <Tab icon={<ChamadasIcon className={classes.icon} />} value="chamadas" className={tab === "chamadas" ? classes.selectedTab : ""} />
          </Tooltip>
          <Tooltip title="Tema">
            <Tab icon={<TemaIcon className={classes.icon} />} value="tema" className={tab === "tema" ? classes.selectedTab : ""} />
          </Tooltip>
          <Tooltip title="Segurança">
            <Tab 
              icon={<LockIcon className={classes.icon} />} 
              value="security" 
              className={tab === "security" ? classes.selectedTab : ""} 
            />
          </Tooltip>

        </Tabs>

        <Paper className={classes.paper} elevation={0}>
          <TabPanel className={classes.container} value={tab} name="schedules">
            <SchedulesForm
              loading={loading}
              onSubmit={handleSubmitSchedules}
              initialValues={schedules}
            />
          </TabPanel>

          <OnlyForSuperUser user={currentUser} yes={() => (
            <TabPanel className={classes.container} value={tab} name="companies">
              <CompaniesManager />
            </TabPanel>
          )} />

          <OnlyForSuperUser user={currentUser} yes={() => (
            <TabPanel className={classes.container} value={tab} name="plans">
              <PlansManager />
            </TabPanel>
          )} />

          <OnlyForSuperUser user={currentUser} yes={() => (
            <TabPanel className={classes.container} value={tab} name="helps">
              <HelpsManager />
            </TabPanel>
          )} />

          <OnlyForSuperUser user={currentUser} yes={() => (
            <TabPanel className={classes.container} value={tab} name="mensagens">
              <MessagesCount />
            </TabPanel>
          )} />

          <TabPanel className={classes.container} value={tab} name="tabulacao">
            <Tabulacao />
          </TabPanel>

          <TabPanel className={classes.container} value={tab} name="satisfacao">
            <RatingOptionsComponent />
          </TabPanel>

          <TabPanel className={classes.container} value={tab} name="chamadas">
            <CallsByCompany />
          </TabPanel>

          <TabPanel className={classes.container} value={tab} name="options">
            <Options
              settings={settings}
              handleChangeSetting={handleChangeSetting}
              getSettingValue={getSettingValue}
            />
          </TabPanel>

          <TabPanel className={classes.container} value={tab} name="tema">
            <ColorSettings />
          </TabPanel>

          
          <TabPanel className={classes.container} value={tab} name="security">
            <PublicTokenManager />
          </TabPanel>
        </Paper>

      </Paper>
    </MainContainer>
  );
};

export default SettingsCustom;
