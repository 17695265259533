import React from "react";
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
} from "@material-ui/core";

import { Edit as EditIcon } from "@material-ui/icons";

import { isArray, has } from "lodash";
import { useDate } from "../../../../hooks/useDate";

import moment from "moment";
import useStyles from "../../style";

export function CompaniesManagerGrid(props) {
    const { records, onSelect, handleOpenModal } = props;
    const classes = useStyles();
    const { dateToClient } = useDate();

    const renderStatus = (row) => {
        return row.status === false ? "Não" : "Sim";
    };

    const renderPlan = (row) => {
        return row.planId !== null ? row.plan.name : "-";
    };

    const renderCampaignsStatus = (row) => {
        if (
            has(row, "settings") &&
            isArray(row.settings) &&
            row.settings.length > 0
        ) {
            const setting = row.settings.find((s) => s.key === "campaignsEnabled");
            if (setting) {
                return setting.value === "true" ? "Habilitadas" : "Desabilitadas";
            }
        }
        return "Desabilitadas";
    };

    const renderCallsStatus = (row) => {
        if (has(row, "settings") && isArray(row.settings) && row.settings.length > 0) {
            const setting = row.settings.find((s) => s.key === "callsEnabled");
            if (setting) {
                return setting.value === "true" ? "Habilitadas" : "Desabilitadas";
            }
        }
        return "Desabilitadas";
    };


    const rowStyle = (record) => {
        if (moment(record.dueDate).isValid()) {
            const now = moment();
            const dueDate = moment(record.dueDate);
            const diff = dueDate.diff(now, "days");
            if (diff === 5) {
                return { backgroundColor: "#fffead" };
            }
            if (diff >= -3 && diff <= 4) {
                return { backgroundColor: "#f7cc8f" };
            }
            if (diff === -4) {
                return { backgroundColor: "#fa8c8c" };
            }
        }
        return {};
    };

    return (
        <Paper className={classes.tableContainer}>
            <Table
                className={classes.fullWidth}
                size="small"
                aria-label="a dense table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell align="center" className={classes.tableTitle}>
                            #
                        </TableCell>
                        <TableCell align="left" className={classes.tableTitle}>Nome</TableCell>
                        <TableCell align="left" className={classes.tableTitle}>CNPJ</TableCell> {/* Adicionando nova coluna de CNPJ */}
                        <TableCell align="left" className={classes.tableTitle}>E-mail</TableCell>
                        <TableCell align="left" className={classes.tableTitle}>Telefone</TableCell>
                        <TableCell align="left" className={classes.tableTitle}>Plano</TableCell>
                        <TableCell align="left" className={classes.tableTitle}>Campanhas</TableCell>
                        <TableCell align="left" className={classes.tableTitle}>Chamadas</TableCell>
                        <TableCell align="left" className={classes.tableTitle}>Status</TableCell>
                        <TableCell align="left" className={classes.tableTitle}>Criada Em</TableCell>
                        <TableCell align="left" className={classes.tableTitle}>Vencimento</TableCell>
                        <TableCell align="left" className={classes.tableTitle}>Mensagens</TableCell>
                        <TableCell align="left" className={classes.tableTitle}>Usuários</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {records.map((row, key) => (
                        <TableRow style={rowStyle(row)} key={key}>
                            <TableCell align="center" style={{ width: "1%" }}>
                                <IconButton onClick={() => {
                                    handleOpenModal();
                                    onSelect(row);
                                }} aria-label="delete">
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell align="left">{row.name || "-"}</TableCell>
                            <TableCell align="left">{row.cnpj || "-"}</TableCell> {/* Adicionando nova célula de CNPJ */}
                            <TableCell align="left">{row.email || "-"}</TableCell>
                            <TableCell align="left">{row.phone || "-"}</TableCell>
                            <TableCell align="left">{renderPlan(row)}</TableCell>
                            <TableCell align="left">{renderCampaignsStatus(row)}</TableCell>
                            <TableCell align="left">{renderCallsStatus(row)}</TableCell>
                            <TableCell align="left">{renderStatus(row)}</TableCell>
                            <TableCell align="left">{dateToClient(row.createdAt)}</TableCell>
                            <TableCell align="left">
                                {dateToClient(row.dueDate)}
                                <br />
                                <span>{row.recurrence}</span>
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">{row?.userCount || 0}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>

            </Table>
        </Paper>
    );
}

export default CompaniesManagerGrid;