import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  useTheme,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import {
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
} from "@material-ui/icons";
import * as Yup from "yup";
import api from "../../services/api";
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import ConfirmationModal from "../ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: 8,
    fontFamily: "Poppins, sans-serif",
    color: theme.palette.primary.mainReverseByWhite,
  },
  dialogTitle: {
    fontFamily: "Poppins, sans-serif",
    color: theme.palette.primary.mainReverseByWhite,
  },
  btnWrapper: {
    position: "relative",
  },
  button: {
    color: theme.palette.primary.mainReverseByWhite,
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      color: theme.palette.primary.mainReverseByWhite,
      backgroundColor: "transparent",
      boxShadow: "none",
      border: `2px solid ${theme.palette.primary.mainReverseByWhite}50`,
    },
  },

  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const CategorySchema = Yup.object().shape({
  name: Yup.string().required("Obrigatório"),
});

const CategoryDialog = ({ open, onClose, onSuccess, hideTable = false }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const initialState = {
    name: "",
  };

  const [categories, setCategories] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [deletingCategory, setDeletingCategory] = useState(null);

  useEffect(() => {
    if (open) {
      fetchCategories();
    }
  }, [open]);

  const fetchCategories = async () => {
    try {
      const { data } = await api.get("/quick-message-categories");
      setCategories(data);
    } catch (err) {
      toastError(err);
    }
  };

  const handleSaveCategory = async (values, actions) => {
    try {
      if (editingCategory) {
        await api.put(`/quick-message-categories/${editingCategory.id}`, values);
        toast.success(i18n.t("quickMessages.toasts.categoryUpdated"));
      } else {
        await api.post("/quick-message-categories", values);
        toast.success(i18n.t("quickMessages.toasts.categoryAdded"));
      }
      actions.resetForm();
      setEditingCategory(null);
      fetchCategories();
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleEditCategory = (category) => {
    setEditingCategory(category);
  };

  const handleDeleteCategory = async () => {
    try {
      await api.delete(`/quick-message-categories/${deletingCategory.id}`);
      toast.success(i18n.t("quickMessages.toasts.categoryDeleted"));
      setDeletingCategory(null);
      setConfirmModalOpen(false);
      fetchCategories();
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    } catch (err) {
      toastError(err);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      scroll="paper"
      classes={{ paper: classes.dialogRoot }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {i18n.t("quickMessages.categoryDialog.title")}
      </DialogTitle>

      <DialogContent dividers>
        <Formik
          initialValues={editingCategory || initialState}
          enableReinitialize
          validationSchema={CategorySchema}
          onSubmit={handleSaveCategory}
        >
          {({ touched, errors, isSubmitting, handleSubmit, resetForm }) => (
            <Form className={classes.formControl} onSubmit={handleSubmit}>
              <Field
                as={TextField}
                label={i18n.t("quickMessages.categoryDialog.name")}
                name="name"
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
                variant="outlined"
                margin="dense"
                fullWidth
              />
              <DialogActions>
                <Button
                  onClick={() => {
                    resetForm();
                    setEditingCategory(null);
                    onClose();
                  }}
                  color="secondary"
                  variant="outlined"
                  disabled={isSubmitting}
                  className={classes.button}
                >
                  {i18n.t("quickMessages.buttons.cancel")}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={`${classes.btnWrapper} ${classes.button}`}
                  disabled={isSubmitting}
                >
                  {editingCategory
                    ? i18n.t("quickMessages.buttons.save")
                    : i18n.t("quickMessages.buttons.add")}
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>

        {!hideTable && <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{i18n.t("quickMessages.categoryDialog.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("quickMessages.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
              <TableRow key={category.id}>
                <TableCell>{category.name}</TableCell>
                <TableCell align="center">
                  <IconButton
                    size="small"
                    onClick={() => handleEditCategory(category)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setDeletingCategory(category);
                      setConfirmModalOpen(true);
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>}

        <ConfirmationModal
          title={
            deletingCategory &&
            `${i18n.t("quickMessages.confirmationModal.deleteCategoryTitle")} "${deletingCategory.name
            }"?`
          }
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={handleDeleteCategory}
        >
          {i18n.t("quickMessages.confirmationModal.deleteCategoryMessage")}
        </ConfirmationModal>
      </DialogContent>
    </Dialog>
  );
};

export default CategoryDialog;
