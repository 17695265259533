import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  useTheme,
} from "@material-ui/core";

import { UsersFilter } from "../../../../components/UsersFilter";
import api from "../../../../services/api";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	modalTitle: {
		fontFamily: "Poppins",
		color: theme.palette.primary.mainReverseByWhite,
	},
	btnStyles: {
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
		borderRadius: "8px",
		color: theme.palette.primary.mainReverseByWhite,
		background: "transparent",
		"&:hover": {
			border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
			background: "transparent",
		},
	},
}));

export function ChatModal({
  open,
  chat,
  type,
  handleClose,
  handleLoadNewChat,
  handleEditChat,
  categorie
}) {
  const [users, setUsers] = useState([]);
  const [title, setTitle] = useState("");
	const theme = useTheme()
	const classes = useStyles(theme);
  useEffect(() => {
    setTitle("");
    setUsers([]);
    if (type === "edit") {
      const userList = chat.users.map((u) => ({
        id: u.user.id,
        name: u.user.name,
      }));
      setUsers(userList);
      setTitle(chat.title);
    }
  }, [chat, open, type]);

  const handleSave = async () => {
    try {
      if (!title) {
        alert("Por favor, preencha o título da conversa.");
        return;
      }

      if (!users || users.length === 0) {
        alert("Por favor, selecione pelo menos um usuário.");
        return;
      }
      if (type === "edit") {
        const { data } = await api.put(`/chats/${chat.id}`, {
          users,
          title,
          chatCategoryId: categorie.id
        });
        handleEditChat(data, categorie.id, title, chat.id);
      } else {
        const { data } = await api.post("/chats", {
          users,
          title,
          chatCategoryId: categorie.id
        });
        handleLoadNewChat(data);
      }
      setTitle("");
      setUsers([]);
      handleClose();
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      PaperProps={{
        style: { border: `2px solid ${theme.palette.primary.mainReverseByWhite}70` }
      }}
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className={classes.modalTitle} id="alert-dialog-title">Conversa</DialogTitle>
      <DialogContent>
        <div style={{display: "flex", flexDirection: "column", gap: "1rem", maxWidth: "300px"}}>
          <Grid item>
            <TextField
              label="Título"
              placeholder="Título"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item>
            <UsersFilter
              onFiltered={(users) => setUsers(users)}
              initialUsers={users}
            />
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Button className={classes.btnStyles} onClick={handleClose} color="primary">
          Fechar
        </Button>
        <Button className={classes.btnStyles} onClick={handleSave} color="primary" variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
