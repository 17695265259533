import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles(theme => ({
	mainContainer: {
		display: "flex",
		flex: 1,
		padding: theme.spacing(2),
		width: "calc(100% - 1rem)",
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
		margin: "0.5rem",
		maxWidth: "100%",
		boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 10px rgba(0, 0, 0, 0.1)",
		borderRadius: "8px",
		background: "transparent",
		minHeight: "calc(100% - 4rem)",
		fontFamily: "Poppins",

		"& > div:first-child": {
			width: "100%"
		}
	},

	contentWrapper: {
		height: "100%",
		minHeight: "100%",
		display: "flex",
		flexDirection: "column",
	},
}));

const MainContainer = ({ children, style = {} }) => {
	const classes = useStyles();

	return (
		<Container style={style} className={classes.mainContainer}>
			<div className={classes.contentWrapper}>{children}</div>
		</Container>
	);
};

export default MainContainer;
