import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    margin: "0.5rem",
    overflowY: "scroll",
    borderRadius: "8px",
    padding: "16px",
    color: theme.palette.primary.mainReverseByWhite, 
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    ...theme.scrollbarStyles,
    "& .rbc-btn-group": {
      borderRadius: "8px",
      border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    },
    "& h5": {
      fontFamily: "Poppins",
      color: theme.palette.primary.mainReverseByWhite,
      fontWeight: '500',
      fontSize: "16px"
    },
    "& button": {
      color: theme.palette.primary.mainReverseByWhite,
    },

    "& h6": {
      fontFamily: "Poppins",
      color: theme.palette.primary.mainReverseByWhite,
      fontWeight: '500 !important',
      fontSize: "14px"
    },

    "& svg": {
      color: theme.palette.primary.mainReverseByWhite,
    },

    "& .MuiPaper-rounded": {
      border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    },

    "& th, p, & span,& td": {
      fontFamily: "Poppins",
      fontWeight: '500 !important',
      fontSize: "14px"
    },

    "& .MuiTablePagination-actions": {
      display: "flex",
      gap: "1rem"
    }
  },
  btnStyles: {
    minWidth: "25px",
    width: "25px",
    height: "25px",
    minHeight: "25px",
    padding: "0",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    color: theme.palette.primary.mainReverseByWhite,
    background: "transparent",
    "&:hover": {
      background: "transparent"
    }
  },
  tablePaper: {
    "& button": {
      color: theme.palette.primary.mainReverseByWhite,
      fontWeigth: "500",
      cursor: "pointer",
      background: "transparent",
      border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
      borderRadius: "8px",
      "&:hover": {
        background: "transparent",
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        borderRadius: "8px",
      }
    },
    "& .rbc-btn-group": {
      borderRadius: "8px",
      border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    },
    "& h5": {
      fontFamily: "Poppins",
      color: theme.palette.primary.mainReverseByWhite,
      fontWeight: '500',
      fontSize: "16px"
    },
    "& .MuiBox-root": {
      [theme.breakpoints.down('sm')]: {
        padding: "1rem 0 0 0",
      }
    }
  },
  tabsIndicator: {
    backgroundColor: theme.palette.primary.main,
  },
  selectedTabIcon: {
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
