import { Box, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";

export function SingleWhatsappFilter({ onFiltered, initialWhatsapp }) {
  const [whatsapps, setWhatsapps] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await loadWhatsapps();
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (initialWhatsapp && Array.isArray(whatsapps) && whatsapps.length > 0) {
      const initialSelection = whatsapps.find(
        (whatsapp) => whatsapp.id === initialWhatsapp
      );
      if (initialSelection) {
        onChange(initialSelection);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialWhatsapp, whatsapps]);

  const loadWhatsapps = async () => {
    try {
      const { data } = await api.get(`/whatsapp`);
      const whatsappList = data.map((w) => ({
        id: w.id,
        name: w.name,
        channel: w.channel,
      }));
      setWhatsapps(whatsappList);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = (value) => {
    setSelected(value);
    onFiltered(value);
  };

  return (
    <Box>
      <Autocomplete
        size="small"
        options={whatsapps}
        value={selected}
        onChange={(e, value) => onChange(value)}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option?.id === value?.id}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={i18n.t("tickets.search.filterConnections")}
          />
        )}
      />
    </Box>
  );
}
