import React, { useState, useEffect } from "react";
import { Button, Typography, CircularProgress, TextField, useTheme } from "@material-ui/core";
import { Lock as LockIcon } from "@material-ui/icons";
import api from "../../services/api";
import { toast } from "react-toastify";

const PublicTokenManager = () => {
    const [tokens, setTokens] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newTokenName, setNewTokenName] = useState("");

    // Fetch tokens on component load
    useEffect(() => {
        const fetchTokens = async () => {
            setLoading(true);
            try {
                const response = await api.get("/public-token");
                const tokenArray = Array.isArray(response.data) ? response.data : [response.data];
                setTokens(tokenArray);
            } catch (error) {
                console.error("Erro ao buscar tokens:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTokens();
    }, []);

    // Create a new token
    const createToken = async () => {
        setLoading(true);
        try {
            const response = await api.post("/generate-public-token", { name: newTokenName });
            const newToken = {
                ...response.data,
                createdAt: new Date().toISOString(), // Definindo a data de criação aqui
            };
            setTokens([newToken]); // Add the new token to the beginning of the list
            setNewTokenName(""); // Clear input field
            toast.success("Token criado com sucesso!");
        } catch (error) {
            console.error("Erro ao criar token:", error);
        } finally {
            setLoading(false);
        }
    };

    // Delete the token
    const deleteToken = async (tokenId) => {
        if (tokens.length === 0) return alert("Não há tokens para excluir.");
        setLoading(true);
        try {
            await api.delete(`/public-token`);
            setTokens(tokens.filter(token => token.id !== tokenId)); // Remove deleted token from list
            toast.success("Token excluído com sucesso!");
        } catch (error) {
            console.error("Erro ao excluir token:", error);
        } finally {
            setLoading(false);
        }
    };

    const theme = useTheme();

    return (
        <div>
            {/* Criar Novo Token */}
            <div style={{ marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography variant="h4" style={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "500", color: theme.palette.primary.mainReverseByWhite }} gutterBottom>
                    Gerenciamento de API pública
                </Typography>
                <Button
                    style={{
                        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
                        borderRadius: "12px",  // Aumento da borda arredondada
                        fontFamily: "Poppins",
                        color: theme.palette.primary.mainReverseByWhite,
                        padding: "8px 16px",  // Ajuste de padding para um botão mais balanceado
                    }}
                    onClick={createToken}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : "Criar Token"}
                </Button>
            </div>

            <div style={{ marginBottom: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography variant="body1" style={{ fontFamily: "Poppins", fontSize: "14px", color: theme.palette.text.primary }}>
                    Após criar um token, você pode utilizá-lo para acessar a API pública.
                    <p>
                        Para fazer isso, inclua o token gerado no cabeçalho de suas requisições como um 
                    </p>
                    <strong> Bearer token </strong>. Você pode ver todos os endpoints da API e testar as requisições diretamente no Swagger da API aqui:  
                    <a href="https://connectarbddev.tarifando.com.br/api-docs/" target="_blank" rel="noopener noreferrer">
                        Swagger API
                    </a>.
                </Typography>
            </div>

            <div style={{ marginTop: "20px" }}>
                {loading ? (
                    <CircularProgress />
                ) : tokens.length === 0 ? (
                    <Typography>Nenhum token encontrado.</Typography>
                ) : (
                    <table style={{
                        width: "100%", 
                        borderCollapse: "collapse", 
                        marginTop: "10px", 
                        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`, 
                        borderRadius: "12px",  // Bordas arredondadas
                    }}>
                        <thead>
                            <tr style={{ backgroundColor: "#f5f5f5", textAlign: "center" }}>
                                <th style={{ border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`, padding: "12px", fontFamily: "Poppins", fontSize: "16px", fontWeight: "500", color: theme.palette.primary.mainReverseByWhite }}>Token</th>
                                <th style={{ border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`, padding: "12px", fontFamily: "Poppins", fontSize: "16px", fontWeight: "500", color: theme.palette.primary.mainReverseByWhite }}>Data de Criação</th>
                                <th style={{ border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`, padding: "12px", fontFamily: "Poppins", fontSize: "16px", fontWeight: "500", color: theme.palette.primary.mainReverseByWhite }}>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tokens.map((token) => (
                                <tr key={token.id} style={{ textAlign: "center" }}>
                                    <td style={{ padding: "12px", border: `1px solid #ddd` }}>{token.token}</td>
                                    <td style={{ padding: "12px", border: `1px solid #ddd` }}>
                                        {new Date(token.createdAt).toLocaleString()}
                                    </td>
                                    <td style={{ padding: "12px", border: `1px solid #ddd` }}>
                                        <Button
                                            style={{
                                                border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
                                                borderRadius: "12px",  // Aumento da borda arredondada
                                                fontFamily: "Poppins",
                                                color: theme.palette.primary.mainReverseByWhite,
                                                padding: "8px 16px",
                                            }}
                                            onClick={() => deleteToken(token.id)}
                                            disabled={loading}
                                        >
                                            {loading ? <CircularProgress size={24} /> : "Excluir Token"}
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
};

export default PublicTokenManager;
