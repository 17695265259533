import React, { useContext, useState } from "react";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import { AuthContext } from "../../context/Auth/AuthContext";
import useStyles from "./styles";
import TagsTable from "./TagsComponents/TagsTable";
import useTagState from "../../hooks/useTags";
import Title from "../../components/Title";
import { Tab, Tabs, Box, useTheme } from "@material-ui/core"; 

const Tags = () => {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0); 

  const { user } = useContext(AuthContext);
  const {
    searchParam,
    setSearchParam,
    tags,
    fetchTags
  } = useTagState({
    initialLoading: false,
    initialPageNumber: 1,
    initialHasMore: true,
    initialSelectedTag: null,
    initialDeletingTag: null,
    initialConfirmModalOpen: false,
    initialSearchParam: "",
    initialTags: [],
    initialTagModalOpen: false,
  });

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue); 
  };

  const theme = useTheme();
  return (
    <MainContainer style={{
      minHeight: "calc(100vh - 74px)",
      flexDirection: "column",
      justifyContent: "space-between",
    }}>
      <MainHeader>
        <Title className={classes.headerTexts}>Categorização</Title>
      </MainHeader>
      
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        centered
        indicatorColor="primary"
        textColor="primary"
        TabIndicatorProps={{ style: { backgroundColor: classes.mainColor, marginBottom: "1rem" } }}
        style={{ justifyContent: "space-between" }}
      >
        <Tab label="Kanban" style={{ width: "50%", minWidth: "50%", fontFamily: "Poppins", marginBottom: "1rem", color: theme.palette.primary.mainReverseByWhite }} />
        <Tab label="Tags" style={{ width: "50%", minWidth: "50%", fontFamily: "Poppins", marginBottom: "1rem", color: theme.palette.primary.mainReverseByWhite }} />
      </Tabs>

      {/* Conteúdo das abas */}
      <Box hidden={selectedTab !== 0} style={{ flex: 1, overflow: 'auto' }}> {/* Exibe o Kanban na aba 0 */}
        <div style={{ marginBottom: "0.5rem" }}>
          <TagsTable
            tags={tags}
            title={"Kanban"}
            route={"/tags/kanban-1"}
            classes={classes}
            user={user}
            fetchTags={fetchTags}
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            style={{ width: "100%", height: "100%", flex: 1 }}  // A tabela ocupa 100% da largura e altura disponível
          />
        </div>
      </Box>

      <Box hidden={selectedTab !== 1} style={{ flex: 1, overflow: 'auto' }}> {/* Exibe a tabela de tags na aba 1 */}
        <TagsTable
          user={user}
          tags={tags}
          fetchTags={fetchTags}
          title={"Tags"}
          route={"/tags/kanban-0"}
          classes={classes}
          searchParam={searchParam}
          setSearchParam={setSearchParam}
          style={{ width: "100%", height: "100%", flex: 1 }}  // A tabela ocupa 100% da largura e altura disponível
        />
      </Box>
    </MainContainer>
  );
};

export default Tags;
