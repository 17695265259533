import React from "react";

const DashboardItem = ({ title, icon: Icon, value, classes }) => {
  return (
    <div className={`cmp-dashboard__raw-data-item ${classes.dashboardHeaderItem}`}>
      <div>
        <h2>{title}</h2>
      </div>
      <div className="cmp-dashboard__raw-data-number">
        <Icon />
        <span>{value}</span>
      </div>
    </div>
  );
};

export default DashboardItem;
