import React from "react";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";

const TimelineHeader = ({
    classes, searchRef,
    searchQuery, handleSearch,
    setDropdownVisible, dropdownVisible,
    contacts, selectedContact,
    handleSelectContact }) => {
    return (
            <div className={classes.header}>
                <h1 className={classes.title}>Timeline Contatos</h1>
                <div className={classes.searchBarContainer} ref={searchRef}>
                    <input
                        type="text"
                        placeholder="Pesquisar..."
                        className={classes.searchBar}
                        value={searchQuery}
                        onChange={handleSearch}
                        onFocus={() => setDropdownVisible(searchQuery.trim() !== "")}
                    />
                    {dropdownVisible && (
                        <div className={classes.dropdown}>
                            {contacts.length > 0 ? (
                                contacts.map((contact) => (
                                    <div
                                        key={contact.contactId}
                                        className={`${classes.contactItem} ${selectedContact?.id === contact.contactId ? classes.selectedContact : ""}`}
                                        onClick={() => handleSelectContact(contact)}
                                    >
                                        <div className={classes.avatarContainer}>
                                            {contact.profilePicUrl ? (
                                                <Avatar
                                                    alt={contact.contactName}
                                                    src={contact.profilePicUrl}
                                                    className={classes.avatar}
                                                />
                                            ) : (
                                                <Avatar className={classes.defaultAvatar}>
                                                    <PersonIcon />
                                                </Avatar>
                                            )}
                                        </div>
                                        <div className={classes.contactInfo}>
                                            <h3 className={classes.contactName}>
                                                {contact.contactName || "Contato não encontrado"}
                                            </h3>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className={classes.noResultsMessage}>
                                    <p>Nenhum contato encontrado</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
    );
};

export default TimelineHeader;
