import { useState, useEffect, useRef } from "react";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const statusObject = {
    "200": "Chamada aceita",
    "403": "Chamada rejeitada",
    "480": "Contato indisponível"
}

export const useWavoip = () => {
    const [whatsappInstance, setWhatsappInstance] = useState(null);
    const [isCalling, setIsCalling] = useState(false);
    const [callDuration, setCallDuration] = useState(0);
    const audioRef = useRef(new Audio());
    const [callReceived, setCallReceived] = useState(false);
    const [wavoipUser, setWavoipUser] = useState(false);
    const [wavoipActiveContact, setWavoipActiveContact] = useState(null);
    const [activeTicket, setActiveTicket] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [exportMsgType, setExportMsgType] = useState("disabled"); // Estado para exportMsgType
    const [callStatus, setCallStatus] = useState("default");
    const [isMuted, setIsMuted] = useState(false);
    const [isContactCalledOnline, setIsCalledContactOnline] = useState(false);
    const [callStatusNumber, setCallStatusNumber] = useState(null);

    const saveCallAudio = async (callId, phoneNumber) => {
        try {
            const storedCallIds = JSON.parse(sessionStorage.getItem("processedCallIds")) || [];

            if (storedCallIds.includes(callId)) {
                return;
            }

            storedCallIds.push(callId);
            sessionStorage.setItem("processedCallIds", JSON.stringify(storedCallIds));
            const contactData = JSON.parse(sessionStorage.getItem("ticketContactInfo"));
            const statusCode = sessionStorage.getItem("statusCode") ? sessionStorage.getItem("statusCode") : 480;
            const statusMessage = statusObject[statusCode.toString()] || "Status desconhecido";

            const response = await api.post(`/api/save-audio/${callId}`, {
                companyId: wavoipUser.company?.id,
                userName: wavoipUser.name,
                contactNumber: contactData?.number || contactData?.phone ,
                contactName: contactData?.name || contactData?.from_tag,
                ticketId: !!Number(sessionStorage.getItem("ticketIdDefined")) ? Number(sessionStorage.getItem("ticketIdDefined")) : null,
                wppId: wavoipUser?.whatsappId || null,
                statusCode: Number(statusCode),
                status: statusMessage,
            });
            console.log("Áudio salvo com sucesso no servidor:", response.data);
        } catch (error) {
            console.error("Erro ao salvar o áudio no servidor:", error);

            const storedCallIds = JSON.parse(sessionStorage.getItem("processedCallIds")) || [];
            const updatedCallIds = storedCallIds.filter((id) => id !== callId);
            sessionStorage.setItem("processedCallIds", JSON.stringify(updatedCallIds));
        } finally {
            sessionStorage.removeItem("ticketIdDefined");
            sessionStorage.removeItem("ticketContactInfo");
            sessionStorage.removeItem("statusCode");

        }
    };

    const initializeInstance = async (user) => {
        try {
            const response = await api.get("/api/get-tokenwavoip", {
                params: { companyId: user.company.id },
            });
            const { tokenwavoip } = response.data;

            if (!tokenwavoip.length) {
                return;
            }

            const Wavoip = require("wavoip-api");
            const instance = new Wavoip();
            const connectedInstance = instance.connect(tokenwavoip);

            connectedInstance.socket.on("connect", () => {
                console.log("[DEBUG] - Socket conectado com sucesso!");
            });

            connectedInstance.socket.off("signaling"); // Remove listener antigo
            connectedInstance.socket.on("signaling", async (eventData) => {
                console.log("[DEBUG] - Evento de sinalização recebido:", eventData);
                switch (eventData.tag) {
                    case "accept":
                        console.log("[SIGNALING EVENT] - Chamada aceita. 200");
                        setCallStatusNumber(200);
                        sessionStorage.setItem("statusCode", "200");
                        setIsCalling(false);
                        setCallStatus("connected");
                        setWavoipActiveContact(prevState => eventData?.content?.from_tag ? eventData.content : prevState);
                        stopAudio();
                        setIsCalling(true);
                        break;

                    case "reject":
                        console.log("[SIGNALING EVENT] - Chamada rejeitada. 403");
                        setCallStatusNumber(403);
                        sessionStorage.setItem("statusCode", "403");

                        stopAudio();
                        setIsCalling(false);
                        setWavoipActiveContact(null);
                        setCallStatus("default");
                        handleEndCall();
                        const callId2 = eventData.attrs["call-id"];
                        saveCallAudio(callId2, null);
                        break;

                    case "terminate":
                        console.log("[SIGNALING EVENT] - Chamada terminada.");
                        setIsOpen(false);
                        setActiveTicket(null);
                        setWavoipActiveContact(null);
                        setCallReceived(false);
                        setIsActive(false);
                        stopAudio();
                        setIsCalling(false);
                        setPhoneNumber("");
                        setCallStatus("default");
                        handleEndCall();
                        const callId = eventData.attrs["call-id"];
                        saveCallAudio(callId, null);
                        break;

                    case "offer":
                        if(user?.callRestriction) {
                            return
                        }
                        console.log("[SIGNALING EVENT] - Chamada recebida.");
                        const intialContactState = JSON.parse(sessionStorage.getItem("ticketContactInfo")) || {}
                        if(intialContactState) {
                            sessionStorage.setItem("ticketContactInfo", JSON.stringify({...intialContactState, ...eventData.content, }));
                        } else {
                            sessionStorage.setItem("ticketContactInfo", JSON.stringify({...eventData.content, }));
                        }
                        setIsCalling(false);
                        setCallStatus("calling");
                        setWavoipActiveContact(eventData.content);
                        setCallReceived(true);
                        playAudio();
                        break;

                    case "accept_elsewhere": 
                        console.log("[SIGNALING EVENT] - Chamada atendida em outro lugar.");
                        setCallStatus("accept_elsewhere");
                        setTimeout(() => {
                            setIsOpen(false);
                            setActiveTicket(null);
                            setWavoipActiveContact(null);
                            setCallReceived(false);
                            setIsActive(false);
                            stopAudio(  );
                            setIsCalling(false);
                            setPhoneNumber("");
                            setCallStatus("default");
                            handleEndCall();
                        }, 3000)
                        stopAudio();
                        break;

                    case "reject_elsewhere":
                        console.log("[SIGNALING EVENT] - Chamada rejeitada em outro lugar.");

                        stopAudio();
                        setIsCalling(false);
                        setWavoipActiveContact(null);
                        setCallStatus("default");
                        handleEndCall();
                
                        break
                    case "preaccept":
                        console.log("[SIGNALING EVENT] - Pré Aceito. Se não chamar 480");
                        setIsCalledContactOnline(true);
                        break
                    case "relaylatency": 
                        console.log("[SIGNALING EVENT] - Chamada iniciada em algum lugar.  Se não chamar 480");
                        setIsCalledContactOnline(true);
                        break
                    default:
                        break;
                }
            });

            setWhatsappInstance(connectedInstance);
            return connectedInstance;
        } catch (error) {
            console.error("Erro ao inicializar a instância do Wavoip:", error);
        }
    };

    const playAudio = () => {
        if (audioRef.current) {
            audioRef.current.play();
        }
    };

    const stopAudio = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    };

    const startWavoipCall = async (whatsappId, user) => {
        
        try {
            let instance = whatsappInstance;
            playAudio();
            if (!instance) {
                instance = await initializeInstance(user);
            }
            if(!instance) {
                toastError("Você não tem um Token WhatsApp configurado.");
            }
            setCallStatus("calling");
            const response = await instance.callStart({ whatsappid: whatsappId });
            if(response.code === "busy") {
                stopAudio();
                toastError("Já tem alguém em chamada");
                return
            }
            setIsCalling(true);
        } catch (error) {
            stopAudio();
            setIsOpen(false);
            handleEndCall();
            toastError("Voce está sem conexão padrão!")
        }
    };

    useEffect(() => {
        let timer;
        if (isCalling && callStatus !== "calling") {
            timer = setInterval(() => {
                setCallDuration((prev) => prev + 1);
            }, 1000);
        } else {
            setCallDuration(0);
        }
        return () => clearInterval(timer);
    }, [isCalling]);

    useEffect(() => {
        let instance = whatsappInstance;
        if (wavoipUser && !instance) {
            initializeInstance(wavoipUser);
        }
    }, [wavoipUser]);

    const handleEndCall = (forceEnd = false) => {
        if (whatsappInstance || forceEnd) {
            if (whatsappInstance) {
                whatsappInstance.endCall();
            }
            setIsOpen(false);
            setActiveTicket(null);
            setWavoipActiveContact(null);
            setIsCalling(false);
            setCallReceived(false);
            setIsActive(false);
            stopAudio();
        }
    };

    const handleWavoipCall = (phoneNumber, user, ticket = null, contact = null) => {
        let whatsappId;

        if(user?.cannotCall) {
            toastError("Você está sem permissões para realizar ligações");
            return
        }
        setCallStatus("calling");
        const intialContactState = JSON.parse(sessionStorage.getItem("ticketContactInfo")) || {}
        if(ticket) {
            setActiveTicket(ticket);
            sessionStorage.setItem("ticketContactInfo", JSON.stringify({...ticket?.contact}));
        }
        
        if (contact) {
            setWavoipActiveContact(contact);
            
        }
        
        if (ticket) {
            whatsappId = `${ticket.contact.number}@s.whatsapp.net`;
        } else {
            whatsappId = `${phoneNumber.replace(/\D/g, "")}@s.whatsapp.net`;
            if(phoneNumber?.length) {
                sessionStorage.setItem("ticketContactInfo", JSON.stringify({...intialContactState, number: phoneNumber.replace(/\D/g, "")}));
                if(contact) {
                    sessionStorage.setItem("ticketContactInfo", JSON.stringify({...intialContactState, number: phoneNumber.replace(/\D/g, "")}));
                }
            }
        }
        if (isCalling) {
            handleEndCall();
        } else {
            startWavoipCall(whatsappId, user);
        }
    };

    const formatPhoneNumber = (number) => {
        const digitsOnly = number.replace(/\D/g, "");
        if (digitsOnly.length <= 2) return digitsOnly;
        if (digitsOnly.length <= 4) return `${digitsOnly.slice(0, 2)} ${digitsOnly.slice(2)}`;
        if (digitsOnly.length <= 9)
            return `${digitsOnly.slice(0, 2)} ${digitsOnly.slice(2, 4)} ${digitsOnly.slice(4)}`;
        return `${digitsOnly.slice(0, 2)} ${digitsOnly.slice(2, 4)} ${digitsOnly.slice(4, 9)}-${digitsOnly.slice(9)}`;
    };

    const acceptCall = async () => {
        setCallStatus("connected");
        let instance = whatsappInstance;
        if (!instance) {
            instance = await initializeInstance(wavoipUser);
        }
        try {
            const response = await instance.acceptCall();
            sessionStorage.setItem("statusCode", "200");

            setIsCalling(true);
            setCallReceived(false);
            stopAudio();
            return response;
        } catch (error) {
            console.error("[*] - Error to accept call", error);
        }
    };

    const rejectCall = async () => {

        let instance = whatsappInstance;
        setIsCalling(false);
        setCallReceived(false);
        stopAudio(); // Pausa o áudio corretamente
        if (!instance) {
            instance = await initializeInstance(wavoipUser);
        }
        try {
            const response = await instance.rejectCall();
            sessionStorage.setItem("statusCode", "403");

        } catch (error) {
            console.error("[*] - Error to reject call", error);
        }
    };

    const fetchSettings = async () => {
        try {
            const { data: settings } = await api.get("/settings");
            const exportSetting = settings.find(s => s.key === "exportMsgType");
            if (exportSetting) {
                setExportMsgType(exportSetting.value);
            }
        } catch (error) {
            toastError(error);
        }
    };
    useEffect(() => {
        fetchSettings();
    }, []);

    const mute = async () => {
        let instance = whatsappInstance;
        if (!instance) {
            instance = await initializeInstance(wavoipUser);
        }
        try {

            let response = await instance.mute();
            setIsMuted(true);

            return response;
        } catch (error) {
            console.error("[*] - Error to mute", error);
        }
    }

    const unMute = async () => {
        let instance = whatsappInstance;
        if (!instance) {
            instance = await initializeInstance(wavoipUser);
        }
        try {
            let response = await instance.unMute();
            setIsMuted(false);

            return response;
        } catch (error) {
            console.error("[*] - Error to unmute", error);
        }
    }

    return {
        setWavoipUser,
        whatsappInstance,
        isCalling,
        callReceived,
        callDuration,
        audioRef,
        handleWavoipCall,
        wavoipActiveContact,
        acceptCall,
        handleEndCall,
        formatPhoneNumber,
        rejectCall,
        setActiveTicket,
        isOpen,
        setIsOpen,
        activeTicket,
        isActive,
        setIsActive,
        phoneNumber,
        setPhoneNumber,
        exportMsgType,
        setExportMsgType,
        callStatus,
        mute,
        unMute,
        isMuted,
        setIsMuted
    };
};
