import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        borderRadius: "8px",
        ...theme.scrollbarStyles,
        "& th": {
            fontFamily: "Poppins",
            color: theme.palette.primary.mainReverseByWhite
        }
    },
    btnStyles: {
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        borderRadius: "8px",
        color: theme.palette.primary.mainReverseByWhite,
        background: "transparent",
        "&:hover": {
            background: "transparent"
        }
    },
    tableStyles: {
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    },
    tableContainer: {
        maxHeight: "calc(100vh - 400px)",
        minHeight: "calc(100vh - 400px)", 
        overflowY: "auto",
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        borderRadius: "8px",
        ...theme.scrollbarStyles,
        "& .rbc-btn-group": {
            borderRadius: "8px",
            border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        },
        "& h5": {
            fontFamily: "Poppins",
            color: theme.palette.primary.mainReverseByWhite,
            fontWeight: '500',
            fontSize: "16px"
        },
        "& button": {
            color: theme.palette.primary.mainReverseByWhite,
        },

        "& h6": {
            fontFamily: "Poppins",
            color: theme.palette.primary.mainReverseByWhite,
            fontWeight: '500 !important',
            fontSize: "14px"
        },

        "& svg": {
            color: theme.palette.primary.mainReverseByWhite,
        },

        "& .MuiPaper-rounded": {
            border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        },

        "& th, p, & span,& td": {
            fontFamily: "Poppins",
            fontWeight: '500 !important',
            fontSize: "14px"
        },

        "& .MuiTablePagination-actions": {
            display: "flex",
            gap: "1rem"
        }
      },
      tableLabel: {
        fontSize: "14px",
        fontWeight: "500",
        fontFamily: "Poppins",
		color: theme.palette.primary.mainReverseByWhite,
        marginBottom: "1rem", // Espaço entre o título e a tabela
      },
      headerTexts: {
        color: theme.palette.primary.mainReverseByWhite,
        fontFamily: "Poppins",
        fontWeight: "600"
      },
      fixedColumn: {
        width: "250px", /* Defina o tamanho fixo desejado */
        textAlign: "center", /* Centraliza o conteúdo */
      },
      
      actionsColumn: {
        width: "100px", /* Menor largura para ações */
        textAlign: "center",
      }
}));

export default useStyles;