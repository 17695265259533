import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Badge from "@material-ui/core/Badge";
import "./style.css";
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsListCustom";
import TabPanel from "../TabPanel";

import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Button, Snackbar } from "@material-ui/core";
import { TagsFilter } from "../TagsFilter";
import { UsersFilter } from "../UsersFilter";
import api from "../../services/api";
import { TicketsListGroup } from "../TicketsListGroup";
import TicketTabsHeader from "../TicketTabsHeader";
import SocialMediaFilter from "../SocialMediaFilter";
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';

import { StatusFilterSearch } from "../StatusFilterSearch";

const useStyles = makeStyles(theme => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTop: "none !important",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
  },
  },

  firstContainer: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70 !important`,
    borderTop: "none !important",
  },
  darkerIcons: {
    color: theme.palette.primary.third
  },

  tabsHeader: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "8px 8px 0 0",
    backgroundColor: "transparent",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderBottom: "none",
  },

  tabsInternal: {
    flex: "none",
    backgroundColor: theme.palette.tabHeaderBackground
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },
  snackbar: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    borderRadius: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8em",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1em",
    },
  },

  yesButton: {
    backgroundColor: "white",
    color: theme.palette.primary.main + " !important",
    padding: "4px 4px",
    fontSize: "1em",
    fontWeight: "bold",
    textTransform: "uppercase",
    marginRight: theme.spacing(1),
    "&:hover": {
      backgroundColor: "darkGreen",
      color: "#FFF",
    },
    borderRadius: 30,
  },
  noButton: {
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`, 
    padding: "4px 4px",
    fontSize: "1em",
    fontWeight: "bold",
    textTransform: "uppercase",
    "&:hover": {
      backgroundColor: "darkRed",
      color: "#FFF",
    },
    borderRadius: 30,
  },
  tab: {
    fontSize: "12px",
    minWidth: "auto",
    padding: "0",
    fontFamily: "Poppins",
    color: theme.palette.primary.mainReverseByWhite
  },

  internalTab: {
    minWidth: 120,
    width: 120,
    padding: 5
  },

  ticketOptionsBox: {
    display: "flex",
    padding: "1rem",
    background: "transparent",
    alignItems: "center",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderTop: "none",
    justifyContent: "space-between",
    borderBottom: "none",
  },

  ticketSearchLine: {
    padding: theme.spacing(1),
  },

  serachInputWrapper: {
    flex: 1,
    background: theme.palette.total,
    display: "flex",
    borderRadius: 40,
    margin: "1rem",
  },

  searchIcon: {
    color: "grey",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    marginTop: '1rem',
    padding: "0 1rem",
    borderRadius: "4px",
    borderBottom: `1px solid ${theme.palette.primary.main}70`
  },

  insiderTabPanel: {
    height: '100%',
    marginTop: "-72px",
    paddingTop: "72px"
  },

  insiderDoubleTabPanel: {
    display: "flex",
    flexDirection: "column",
    marginTop: "-72px",
    paddingTop: "72px",
    height: "100%"
  },

  labelContainer: {
    width: "auto",
    padding: 0
  },
  iconLabelWrapper: {
    flexDirection: "row",
    '& > *:first-child': {
      marginBottom: '3px !important',
      marginRight: 16
    }
  },
  insiderTabLabel: {
    [theme.breakpoints.down(1600)]: {
      display: 'none'
    }
  },
  smallFormControl: {
    '& .MuiOutlinedInput-input': {
      padding: "12px 10px",
    },
    '& .MuiInputLabel-outlined': {
      marginTop: "-6px"
    }
  }
}));

const TicketsManagerTabs = ({setMobileUserOpen, TicketsListCustom, setHideMessages}) => {
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    const handleElementClick = (event) => {
      const target = event.target;
      if (!target.classList.contains('cmp-tickers__close-every-ticket')) {
        const closeEveryTicketElement = document.querySelector('.cmp-tickers__close-every-ticket');

        if (closeEveryTicketElement) {
          closeEveryTicketElement.classList.add('cmp-tickers__close-every-ticket--disabled');
        }
      }
    };

    document.addEventListener('click', handleElementClick);

    return () => {
      document.removeEventListener('click', handleElementClick);
    };
  }, []);
  const [searchParam, setSearchParam] = useState("");
  const [tab, setTab] = useState("open");
  const [filterModalOpen, setFilterModal] = useState(false);
  const [tabOpen, setTabOpen] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const { profile } = user;
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);

  const userQueueIds = user?.queues?.map((q) => q.id) || [];
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showGroupTab, setShowTabGroup] = useState(false);
  const [contactType, setContactType] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState([]);
  const theme = useTheme();
  useEffect(() => {

    fetchSettings();

    if (user.profile.toUpperCase() === "ADMIN") {
      setShowAllTickets(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);

  let searchTimeout;

  const fetchSettings = async () => {
    try {
      const { data } = await api.get("/settings");
      const showGroups = data.find((s) => s.key === "CheckMsgIsGroup");
      setShowTabGroup(showGroups.value === "disabled");
    } catch (err) {
      // toastError(err);
    }
  };

  const handleSearch = (e) => {
    const searchedTerm = e.target.value.toLowerCase();
  
    clearTimeout(searchTimeout);
  
    if (searchedTerm === "") {
      setSearchParam(searchedTerm);
      return;
    }
  
    searchTimeout = setTimeout(() => {
      setSearchParam(searchedTerm);
    }, 1000);
  };
  

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleChangeTabOpen = (e, newValue) => {
    setTabOpen(newValue);
  };

  const applyPanelStyle = (status) => {
    if (tabOpen !== status) {
      return { width: 0, height: 0 };
    }
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  };

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map((t) => t.id);
    setSelectedTags(tags);
  };
  const handleSelectedStatus = (selecteds) => {
    setSelectedStatus(selecteds);
  }

  const handleSelectedUsers = (selecteds) => {
    const users = selecteds.map((t) => t.id);
    setSelectedUsers(users);
  };

  const CloseAllTicket = async () => {
    try {
      await api.post("/tickets/closeAll", {
        status: tabOpen,
        selectedQueueIds,
      });

      handleSnackbarClose();

    } catch (err) {
      console.log("Error: ", err);
    }
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSocialClick = (type) => {
    setContactType(type);
  }

  return (
    <>
      <div style={{paddingTop: "1rem", border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`, borderRadius: "8px"}}>
        <SocialMediaFilter handleSocialButtonClick={handleSocialClick} contactType={contactType} />
      </div>
      <Paper elevation={0} variant="outlined" className={`${classes.ticketsWrapper} cmp-tickets__rigth-container`}>
        { newTicketModalOpen && <NewTicketModal
          modalOpen={newTicketModalOpen}
          onClose={(ticket) => {
            handleCloseOrOpenTicket(ticket);
          }}
        />}
        <TicketTabsHeader
          tab={tab}
          classes={classes}
          handleChangeTab={handleChangeTab}
          setFilterModal={setFilterModal}
          showGroupTab={showGroupTab}
          filterModalOpen={filterModalOpen}
          setNewTicketModalOpen={setNewTicketModalOpen} />

        <Paper
          square
          elevation={0}
          className={`${classes.ticketOptionsBox} ${filterModalOpen
            ? "cmp-tickers__close-every-ticket"
            : "cmp-tickers__close-every-ticket--disabled"}`}>
          {(tab !== "search" && tab !== "closed") && <div className={`cmp-tickets__filter-modal `}>
            <Snackbar
              open={snackbarOpen}
              onClose={handleSnackbarClose}
              message={i18n.t("tickets.inbox.closedAllTickets")}
              ContentProps={{
                className: `${classes.snackbar}`,
              }}
              action={
                <>
                  <Button
                    className={classes.yesButton}
                    size="small"
                    onClick={CloseAllTicket}
                  >
                    {i18n.t("tickets.inbox.yes")}
                  </Button>
                  <Button
                    className={classes.noButton}
                    size="small"
                    onClick={handleSnackbarClose}
                  >
                    {i18n.t("tickets.inbox.no")}
                  </Button>
                </>
              }
            />
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSnackbarOpen}
              style={{
                minWidth: "90px", fontSize: "0.7rem",
                "@media (max-width: 950px)": {
                  fontSize: "0.5rem",
                },
                color: theme.palette.primary.mainReverseByWhite, border: `1px solid ${theme.palette.primary.mainReverseByWhite}`
              }} // Define o tamanho mínimo e a fonte
            >
              {i18n.t("ticketsManager.buttons.closeallTicket")}

            </Button>

          </div>}
          <TicketsQueueSelect
            style={{ marginLeft: 6 }}
            selectedQueueIds={selectedQueueIds}
            userQueues={user?.queues}
            onChange={(values) => setSelectedQueueIds(values)}
            display={(tab !== "search" && tab !== "closed") ? "flex" : "none"}
          />
        </Paper>

        <TabPanel value={tab} name="open" className={`${classes.ticketsWrapper} ${classes.firstContainer}`}>
          <Tabs
            value={tabOpen}
            onChange={handleChangeTabOpen}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className="cmp-tickets__header-buttons"
          >
            <Tab
              label={
                <Badge
                  className={`${classes.badge}`}
                  badgeContent={openCount}
                  color="primary"
                >
                  <ConfirmationNumberIcon className={classes.darkerIcons} />
                </Badge>
              }
              value={"open"}
            />
            <Tab
              label={
                <Badge
                  className={classes.badge}
                  badgeContent={pendingCount}
                  color="secondary"
                >
                  <NotificationsIcon className={classes.darkerIcons} />
                </Badge>
              }
              value={"pending"}
            />
          </Tabs>
          <Paper className={`${classes.ticketsWrapper}`}>
            <TicketsList
              status="open"
              showAll={showAllTickets}
              setHideMessages={setHideMessages}
              selectedQueueIds={selectedQueueIds}
              setMobileUserOpen={setMobileUserOpen}
              TicketsListCustom={TicketsListCustom}
              contactType={contactType}
              updateCount={(val) => setOpenCount(val)}
              style={applyPanelStyle("open")}
            />
            <TicketsList
              status="pending"
              contactType={contactType}
              selectedQueueIds={selectedQueueIds}
              updateCount={(val) => setPendingCount(val)}
              style={applyPanelStyle("pending")}
            />
          </Paper>
        </TabPanel>
        <TabPanel value={tab} name="group" className={`${classes.ticketsWrapper} ${classes.firstContainer}`}>
          <Tabs
            value={tabOpen}
            onChange={handleChangeTabOpen}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            className="cmp-tickets__header-buttons"
          >
            <Tab
              label={
                <Badge
                  className={classes.badge}
                  badgeContent={openCount}
                  color="primary"
                >
                  <ConfirmationNumberIcon className={classes.darkerIcons} />
                </Badge>
              }
              value={"open"}
            />
            <Tab
              label={
                <Badge
                  className={classes.badge}
                  badgeContent={pendingCount}
                  color="secondary"
                >
                  <NotificationsIcon className={classes.darkerIcons} />
                </Badge>
              }
              value={"pending"}
            />
          </Tabs>
          <Paper className={`${classes.ticketsWrapper}`}>
            <TicketsListGroup
              status="open"
              showAll={true}
              setHideMessages={setHideMessages}
              selectedQueueIds={selectedQueueIds}
              updateCount={(val) => setOpenCount(val)}
              setMobileUserOpen={setMobileUserOpen}
              style={applyPanelStyle("open")}
            />
            <TicketsListGroup
              status="pending"
              selectedQueueIds={selectedQueueIds}
              updateCount={(val) => setPendingCount(val)}
              style={applyPanelStyle("pending")}
            />
          </Paper>
        </TabPanel>

        <TabPanel value={tab} name="closed" className={`${classes.ticketsWrapper} ${classes.firstContainer}`}>
          <TicketsList
            status="closed"
            showAll={true}
            contactType={contactType}
            selectedQueueIds={selectedQueueIds}
          />
        </TabPanel>

        <TabPanel value={tab} name="search" className={`${classes.ticketsWrapper} ${classes.firstContainer}`}>
          {tab === "search" ? (
            <div className={classes.serachInputWrapper}>
              <InputBase
                className={classes.searchInput}
                inputRef={searchInputRef}
                placeholder={i18n.t("tickets.search.placeholder") + "..."}
                type="search"
                defaultValue={searchParam}
                onChange={handleSearch}
              />
            </div>
          ) : (
            <>
            </>
          )}
          <div className="cmp-tickets__search-fiter-container">
            <div style={{display: "flex", gap: "1rem", flexDirection: "column"}}>
              {profile === "admin" && (
                <UsersFilter selectedUsers={selectedUsers} onFiltered={handleSelectedUsers} />
              )}
              <StatusFilterSearch setSelecteds={setSelectedStatus} selecteds={selectedStatus} onFiltered={handleSelectedStatus} />
            </div>
              <TagsFilter selectedTags={selectedTags} onFiltered={handleSelectedTags} />
          </div>
          <TicketsList
            searchParam={searchParam}
            searching={searchParam.length > 0}
            showAll={true}
            tab={tab}
            setMobileUserOpen={setMobileUserOpen}
            tags={selectedTags}
            contactType={contactType}
            users={selectedUsers}
            selectedStatus={selectedStatus}
            selectedQueueIds={selectedQueueIds}
          />
        </TabPanel>
      </Paper>
    </>
  );
};

export default TicketsManagerTabs;
