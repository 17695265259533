import React, { useState, useEffect } from "react";
import {
  Paper,
  Grid,
  Button,
  Dialog,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import ConfirmationModal from "../ConfirmationModal";

import { toast } from "react-toastify";
import useCompanies from "../../hooks/useCompanies";
import useStyles from "./style";
import CompanyForm from "./CompaniesManagerComponents/components/CompanyForm";
import CompaniesManagerGrid from "./CompaniesManagerComponents/components/CompaniesManagerGrid";

const useModalStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    boxShadow: theme.shadows[5],
  },
  btnStyles: {
    minWidth: "25px",
    width: "25px",
    height: "25px",
    minHeight: "25px",
    padding: "0",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    color: theme.palette.primary.mainReverseByWhite,
    background: "transparent",
    "&:hover": {
      background: "transparent"
    }
  },
}));

export default function CompaniesManager() {
  const theme = useTheme();
  const classes = useStyles(theme);
  const modalClasses = useModalStyles(theme);

  const { list, save, update, remove } = useCompanies();
  const initialReport = {
    name: "",
    email: "",
    phone: "",
    planId: "",
    callsEnabled: false,
    campaignsEnabled: false,
    status: true,
    dueDate: "",
    recurrence: "",
    cnpj: "",
    corporateName: "",
    street: "",
    number: "",
    neighborhood: "",
    city: "",
    state: "",
    postalCode: "",
    contactName: "",
    contactEmail: "",
    contactPhone: "",
    stateRegistration: "",
    municipalRegistration: "",
    website: "",
  }
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [record, setRecord] = useState(initialReport);

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    loadPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadPlans = async () => {
    setLoading(true);
    try {
      const companyList = await list();
      setRecords(companyList);
    } catch (e) {
      toast.error("Não foi possível carregar a lista de registros");
    }
    setLoading(false);
  };

  const handleSubmit = async (data) => {
    setLoading(true);
    try {
      if (data.id !== undefined) {
        await update(data);
      } else {
        await save(data);
      }
      await loadPlans();
      handleCloseModal();
      toast.success("Operação realizada com sucesso!");
    } catch (e) {
      toast.error(
        "Não foi possível realizar a operação. Verifique se já existe uma empresa com o mesmo nome ou se os campos foram preenchidos corretamente"
      );
    }
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      await remove(record.id);
      await loadPlans();
      handleCloseModal();
      toast.success("Operação realizada com sucesso!");
    } catch (e) {
      toast.error("Não foi possível realizar a operação");
    }
    setLoading(false);
  };

  const handleOpenDeleteDialog = () => {
    setShowConfirmDialog(true);
  };

  const handleCancel = () => {
    setRecord((prev) => ({
      ...prev,
      name: "",
      email: "",
      phone: "",
      planId: "",
      status: true,
      campaignsEnabled: false,
      dueDate: "",
      recurrence: "",
    }));
  };

  const handleSelect = (data) => {
    let campaignsEnabled = false;
    let callsEnabled = false;

    const campaignSetting = data.settings.find(
      (s) => s.key === "campaignsEnabled"
    );
    if (campaignSetting) {
      campaignsEnabled = campaignSetting.value === "true";
    }

    const callSetting = data.settings.find(
      (s) => s.key === "callsEnabled"
    );
    if (callSetting) {
      callsEnabled = callSetting.value === "true"; // Verifica se está habilitado
    }

    setRecord({
      id: data?.id,
      name: data?.name || "",
      phone: data?.phone || "",
      email: data?.email || "",
      planId: data?.planId || "",
      status: data?.status === false ? false : true,
      campaignsEnabled,
      callsEnabled, // Atualize o estado de 'callsEnabled' aqui
      dueDate: data?.dueDate || "",
      recurrence: data?.recurrence || "",
      cnpj: data?.cnpj || "",
      corporateName: data?.corporateName || "",
      street: data?.street || "",
      number: data?.number || "",
      neighborhood: data?.neighborhood || "",
      city: data?.city || "",
      state: data?.state || "",
      postalCode: data?.postalCode || "",
      contactName: data?.contactName || "",
      contactPhone: data?.contactPhone || "",
      stateRegistration: data?.stateRegistration || "",
      municipalRegistration: data?.municipalRegistration || "",
      website: data?.website || "",
    });
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    handleCancel();
  };
  return (
    <Paper className={classes.mainPaper} elevation={0}>
      <Grid container>
        <div style={{display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "1rem"}}>
          <h5 style={{fontSize: "14px", fontFamily: "Poppins", color: theme.palette.primary.mainReverseByWhite, fontWeight: "500", marginBottom: "1rem"}}>Empresas</h5>
          <Button
            className={modalClasses.btnStyles}
            onClick={() => {
              setRecord(initialReport);
              handleOpenModal();
            }}
          >
            +
          </Button>
        </div>
        <Grid xs={12} item>
          <CompaniesManagerGrid handleOpenModal={handleOpenModal} records={records} onSelect={handleSelect} />
        </Grid>
      </Grid>

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          className: modalClasses.card,
        }}
      >
        <CompanyForm
          initialValue={record}
          onDelete={handleOpenDeleteDialog}
          onSubmit={handleSubmit}
          onCancel={handleCloseModal}
          loading={loading}
        />
      </Dialog>

      <ConfirmationModal
        title="Exclusão de Registro"
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={() => handleDelete()}
      >
        Deseja realmente excluir esse registro?
      </ConfirmationModal>
    </Paper>
  );
}
