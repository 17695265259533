import React, { useState, useEffect } from "react";
import {
    Paper,
    Grid,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    useTheme,
} from "@material-ui/core";
import ConfirmationModal from "../ConfirmationModal";

import { toast } from "react-toastify";
import useHelps from "../../hooks/useHelps";
import useStyles from "./style";
import { HelpManagerForm } from "./HelpsManagerComponents/HelpManagerForm";
import { HelpsManagerGrid } from "./HelpsManagerComponents/HelpsManagerGrid";
import api from "../../services/api";

export default function HelpsManager () {
    const classes = useStyles();
    const { list, save, update, remove } = useHelps();
    const initialRecord = {
        title: '',
        description: '',
        video: ''
    };
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [records, setRecords] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [record, setRecord] = useState(initialRecord);

    useEffect(() => {
        const listEF = async (params) => {
            const { data } = await api.request({
                url: '/helps/list',
                method: 'GET',
                params
            });
            return data;
        }
        const loadHelpsEF = async () => {
            setLoading(true);
            try {
                const helpList = await listEF();
                setRecords(helpList);
            } catch (e) {
                toast.error('Não foi possível carregar a lista de registros');
            }
            setLoading(false);
        };
        async function fetchData () {
            await loadHelpsEF();
        }
        fetchData();
    }, []);

    const loadHelps = async () => {
        setLoading(true);
        try {
            const helpList = await list();
            setRecords(helpList);
        } catch (e) {
            toast.error('Não foi possível carregar a lista de registros');
        }
        setLoading(false);
    };
    const handleSubmit = async (data) => {
        setLoading(true);
        try {
            if (data.id !== undefined) {
                await update(data);
            } else {
                await save(data);
            }
            await loadHelps();
            handleCancel();
            toast.success('Operação realizada com sucesso!');
        } catch (e) {
            toast.error('Não foi possível realizar a operação. Verifique se já existe uma help com o mesmo nome ou se os campos foram preenchidos corretamente');
        }
        setLoading(false);
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            await remove(record.id);
            await loadHelps();
            handleCancel();
            toast.success('Operação realizada com sucesso!');
        } catch (e) {
            toast.error('Não foi possível realizar a operação');
        }
        setLoading(false);
    };

    const handleOpenDeleteDialog = () => {
        setShowConfirmDialog(true);
    };

    const handleCancel = () => {
        setRecord(initialRecord);
    };

    const handleSelect = (data) => {
        setRecord({
            id: data.id,
            title: data.title || '',
            description: data.description || '',
            video: data.video || ''
        });
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleDialogOpen = () => {
        setOpenDialog(true);
        setRecord(initialRecord);
    };

    const theme = useTheme();

    return (
        <Paper className={classes.mainPaper} elevation={0}>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "1rem" }}>
                <h5 style={{ fontSize: "14px", fontFamily: "Poppins", color: theme.palette.primary.mainReverseByWhite, fontWeight: "500", marginBottom: "1rem" }}>Ajudas</h5>
                <Button
                    className={classes.btnStyles}
                    onClick={handleDialogOpen}
                >
                    +
                </Button>
            </div>
            <Grid spacing={2} container>
                <Grid xs={12} item>
                    <HelpsManagerGrid
                        setOpenDialog={setOpenDialog}
                        records={records}
                        onSelect={handleSelect}
                    />
                </Grid>
            </Grid>
            <ConfirmationModal
                title="Exclusão de Registro"
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                onConfirm={() => handleDelete()}
            >
                Deseja realmente excluir esse registro?
            </ConfirmationModal>

            <Dialog
                open={openDialog}
                PaperProps={{
                    style: {
                        border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
                        borderRadius: "8px",
                    },
                }}
                onClose={handleDialogClose}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle className={classes.modalTitle}>Adicionar / Editar Ajuda</DialogTitle>
                <DialogContent>
                    <HelpManagerForm 
                        initialValue={record} 
                        onDelete={handleOpenDeleteDialog} 
                        onSubmit={handleSubmit} 
                        onCancel={handleCancel} 
                        loading={loading}
                        setDialogOpen={setOpenDialog}
                    />
                </DialogContent>
            </Dialog>
        </Paper>
    );
}
