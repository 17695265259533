import React, { useState } from 'react';
import { Tabs, Tab, Typography, Box, Divider, useTheme } from '@material-ui/core';
import { GroupAdd, GroupWork, People, EmojiPeople } from '@material-ui/icons';
import CreateGroupComponent from '../../components/createGroup';
import ManipulateGroupsComponent from '../../components/ManipularGroups';
import SaudacaoComponent from '../../components/SaudacaoComponent';
import ManipulateUsersComponent from '../../components/ManipularUsuarios';
import useStyles from './style';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

const GroupAndParticipantsPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const theme = useTheme();
  const classes = useStyles(theme);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className={classes.mainPaper}>
      <Typography variant="h5" style={{ marginBottom: '1rem' }}>
        Grupos e Participantes
      </Typography>
      <Divider style={{ marginBottom: '20px' }} />

      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="tabs"
        classes={{ indicator: classes.tabsIndicator }} // aplica o estilo customizado ao indicador
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab icon={<GroupAdd className={tabValue === 0 ? classes.selectedTabIcon : ''} />} title="Grupos e Participantes" />
        <Tab icon={<GroupWork className={tabValue === 1 ? classes.selectedTabIcon : ''} />} title="Manipular Grupos" />
        <Tab icon={<People className={tabValue === 2 ? classes.selectedTabIcon : ''} />} title="Manipular Usuários" />
        <Tab icon={<EmojiPeople className={tabValue === 3 ? classes.selectedTabIcon : ''} />} title="Mensagem de Saudação" />
      </Tabs>
      <div className={classes.tablePaper}>
        <TabPanel value={tabValue} index={0}>
          <CreateGroupComponent />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ManipulateGroupsComponent />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ManipulateUsersComponent />
        </TabPanel>
        <TabPanel value={tabValue} index={3}>
          <SaudacaoComponent />
        </TabPanel>
      </div>
    </div>
  );
};

export default GroupAndParticipantsPage;
