import React from "react";
import {
  Button,
  DialogActions,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"; // Importa o ícone de fechar
import { i18n } from "../../../../translate/i18n";

const EndButtons = ({ isSubmitting, handleClose, classes, whatsAppId }) => {
  return (
    <DialogActions>
      <IconButton
        onClick={handleClose}
        disabled={isSubmitting}
        className={classes.closeBtnStyles}
      >
        <CloseIcon />
      </IconButton>
      
      <Button
        type="submit"
        color="primary"
        disabled={isSubmitting}
        variant="contained"
        className={classes.btnWrapper}
      >
        {whatsAppId
          ? i18n.t("whatsappModal.buttons.okEdit")
          : i18n.t("whatsappModal.buttons.okAdd")}
        {isSubmitting && (
          <CircularProgress
            size={24}
            className={classes.buttonProgress}
          />
        )}
      </Button>
    </DialogActions>
  );
};

export default React.memo(EndButtons);
