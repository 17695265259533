import React, { useState, useEffect } from "react";
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Collapse,
  Typography,
  Divider,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { ExpandLess, ExpandMore, Search } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  popoverPaper: {
    width: 600,
    height: 500,
    overflowY: "auto",
    borderRadius: 4,
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
  searchField: {
    margin: theme.spacing(2, 0, 2, 0),
    "& .MuiOutlinedInput-root": {
      borderRadius: 20,
      backgroundColor: theme.palette.background.paper,
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    },
  },
  categoryHeader: {
    backgroundColor: theme.palette.background.default,
    cursor: "pointer",
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 8,
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    marginBottom: theme.spacing(1),
  },
  messageList: {
    paddingLeft: theme.spacing(2),
  },
  listItemText: {
    maxWidth: 500,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  listContainer: {
    maxHeight: 350,
    overflowY: "auto",
  },
  paperWrapper: {
    padding: theme.spacing(2),
  },
}));

const QuickMessageSelectionDialog = ({ open, onClose, onSelect, anchorEl }) => {
  const classes = useStyles();
  const [categories, setCategories] = useState([]);
  const [quickMessages, setQuickMessages] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (open) {
      fetchCategories();
      fetchQuickMessages();
    }
  }, [open]);

  const fetchCategories = async () => {
    try {
      const { data } = await api.get("/quick-message-categories");
      setCategories(data);
    } catch (err) {
      toastError(err);
    }
  };

  const fetchQuickMessages = async () => {
    try {
      const { data } = await api.get("/quick-messages");
      setQuickMessages(data.records);
    } catch (err) {
      toastError(err);
    }
  };

  const toggleCategory = (categoryId) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }));
  };

  const getFilteredCategories = () => {
    if (!searchTerm) return categories;

    return categories.filter((category) => {
      const filteredMessages = quickMessages.filter(
        (message) =>
          message.categoryId === category.id &&
          message.message.toLowerCase().includes(searchTerm.toLowerCase())
      );

      return (
        category.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        filteredMessages.length > 0
      );
    });
  };

  const filteredMessages = (categoryId) => {
    return quickMessages.filter(
      (message) =>
        message.categoryId === categoryId &&
        message.message.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const renderMessagesByCategory = (categoryId) => {
    const messages = filteredMessages(categoryId);

    return messages.length > 0 ? (
      messages.map((message) => (
        <ListItem
          button
          key={message.id}
          onClick={() => {
            onSelect({ ...message, attachment: message.mediaPath });
            onClose();
          }}
        >
          <ListItemText
            primary={`${message.shortcode} - ${message.message}`}
            classes={{ primary: classes.listItemText }}
          />
        </ListItem>
      ))
    ) : (
      <Typography variant="body2" style={{ paddingLeft: 16 }}>
        Sem mensagens nesta categoria.
      </Typography>
    );
  };

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      classes={{ paper: classes.popoverPaper }}
    >
      <div className={classes.paperWrapper}>
        <TextField
          className={classes.searchField}
          variant="outlined"
          size="small"
          placeholder="Buscar mensagens rápidas ou categorias"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search color="action" />
              </InputAdornment>
            ),
          }}
          fullWidth
        />
        <div className={classes.listContainer}>
          <List>
            {getFilteredCategories().map((category) => (
              <React.Fragment key={category.id}>
                <ListItem
                  button
                  onClick={() => toggleCategory(category.id)}
                  className={classes.categoryHeader}
                >
                  <Typography variant="h6">{category.name}</Typography>
                  <IconButton size="small">
                    {expandedCategories[category.id] ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </ListItem>
                <Collapse
                  in={expandedCategories[category.id]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List className={classes.messageList}>
                    {renderMessagesByCategory(category.id)}
                  </List>
                </Collapse>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </div>
      </div>
    </Popover>
  );
};

export default QuickMessageSelectionDialog;
