import { toast } from "react-toastify";

import api from "../../../../services/api";
import { i18n } from "../../../../translate/i18n";
import toastError from "../../../../errors/toastError";

const handleSaveWhatsApp = async (
  values,
  modelType,
  selectedQueueIds,
  selectedPrompt,
  enableImportMessage,
  importOldMessages,
  importRecentMessages,
  importOldMessagesGroups,
  closedTicketsPostImported,
  enableLocalProxy,
  proxyHost,
  proxyPort,
  proxyUsername,
  proxyPassword,
  isHubSelected,
  selectedChannel,
  availableChannels,
  whatsAppId,
  setUpdatedWhatsApps,
  whatsApp,
  setWhatsApp,
  handleClose,
  sendQueueId
) => {

  const whatsappData = {
    ...values,  
    provider: modelType === "official" ? "official" : "beta",
    status: values?.status ? values?.status : "CONNECTED",
    queueIds: selectedQueueIds,
    sendIdQueue: sendQueueId || 0,
    promptId: selectedPrompt ? selectedPrompt : null,
    importOldMessages: enableImportMessage ? importOldMessages : null,
    importRecentMessages: enableImportMessage ? importRecentMessages : null,
    importOldMessagesGroups: importOldMessagesGroups ? importOldMessagesGroups : null,
    closedTicketsPostImported: closedTicketsPostImported ? closedTicketsPostImported : null,
    localProxy: enableLocalProxy
      ? {
        host: proxyHost,
        port: proxyPort,
        username: proxyUsername,
        password: proxyPassword,
      }
      : null,
  };
  delete whatsappData["queues"];
  delete whatsappData["session"];

  try {
    if (isHubSelected && selectedChannel) {
      const companyId = localStorage.getItem("companyId");
      const selectedChannelObj = availableChannels.find((channel) => channel.id === selectedChannel);

      if (selectedChannelObj) {
        const channels = [selectedChannelObj];
        await api.post("/hub-channel/", { ...whatsappData, channels, companyId });
        setTimeout(() => {
          window.location.reload();
        }, 100);
      } else {
        throw new Error("Selected channel object not found.");
      }
    } else {
      if (values.tokenwppoficial) {
        if (whatsAppId) {
          await api.put(`/whatsapp-official/${whatsAppId}`, whatsappData);
        } else {
          const { data } = await api.post("/whatsapp-official", whatsappData);
          setUpdatedWhatsApps((prevState) => [...prevState, { ...data }]);
        }
      } else {
        if (whatsAppId) {
          if (whatsAppId && enableImportMessage && whatsApp?.status === "CONNECTED") {
            try {
              setWhatsApp({ ...whatsApp, status: "CONNECTED" });
              await api.delete(`/whatsappsession/${whatsApp.id}`);
            } catch (err) {
              toastError(err);
            }
          }
          await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
          setUpdatedWhatsApps((prevState) =>
            prevState.map((item) => (item.id === whatsappData.id ? whatsappData : item))
          );
          if (values.attachment && values.attachment.size) {
            const formData = new FormData();
            formData.append("file", values.attachment);
            await api.post(`/whatsapp/${whatsAppId}/media-upload`, formData);
          }
        } else {
          const { data } = await api.post("/whatsapp", whatsappData);
          setUpdatedWhatsApps((prevState) => [...prevState, { ...data }]);
          if (values.attachment) {
            const formData = new FormData();
            formData.append("file", values.attachment);
            await api.post(`/whatsapp/${data.id}/media-upload`, formData);
          }
        }
      }
    }

    toast.success(i18n.t("whatsappModal.success"));
    handleClose();
  } catch (err) {
    if(whatsAppId) {
      handleClose();
      return
    }
    toastError(err);
    handleClose();
  }
};

export default handleSaveWhatsApp;