import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Poppins, sans-serif',
  },
  container: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    padding: theme.spacing(2),
    borderRadius: "8px",
  },
  title: {
    fontFamily: 'Poppins, sans-serif',
    color: theme.palette.primary.mainReverseByWhite,
    fontSize: "16px"
  },
  item: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: '12px',
    color: theme.palette.primary.mainReverseByWhite,
  },
  buttonContainer: {
    display: 'flex',
    gap: "0.5rem",
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  button: {
    fontFamily: 'Poppins, sans-serif',
    color: theme.palette.primary.mainReverseByWhite,
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.mainReverseByWhite,
    },
  },
}));


export default useStyles;
