import React from "react";
import { Field } from "formik";
import {
  TextField,
  Switch,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import { i18n } from "../../../../translate/i18n";

const SwitchAndInputs = ({
  modelType,
  touched,
  errors,
  values,
  setFieldValue
}) => {

  return (
    <>
      <div>
        {modelType === "official" && (
          <FormControlLabel
            style={{ display: "none" }}
            control={
              <Switch
                checked={true}
                onChange={(e) => setFieldValue("isOfficial", true)}
                color="primary"
                style={{ display: "none" }}
              />
            }
            label="WhatsApp Oficial"
          />
        )}

        {(modelType === "whatsapp" || modelType === "official") && (
          <>
            <div className="cmp-default">
              <Grid item>
                <label>{i18n.t("whatsappModal.form.default")}</label>
              </Grid>
              <Grid item>
                <Switch
                  color="primary"
                  checked={values.isDefault === true} // O valor de checked será o valor de isDefault
                  onChange={(e) => setFieldValue("isDefault", e.target.checked)} // Atualiza o valor corretamente
                />
              </Grid>
            </div>
          </>
        )}

        {modelType === "official" && (
          <>
            <Field
              as={TextField}
              label="Número"
              name="number"
              error={touched.number && Boolean(errors.number)}
              helperText={touched.number && errors.number}
              variant="outlined"
              margin="dense"
              fullWidth
              InputLabelProps={{
                shrink: true, // Mantém o label acima do campo
            }}
            />
            <Field
              as={TextField}
              label="Token Oficial"
              name="tokenwppoficial"
              error={
                touched.tokenwppoficial && Boolean(errors.tokenwppoficial)
              }
              helperText={touched.tokenwppoficial && errors.tokenwppoficial}
              variant="outlined"
              margin="dense"
              fullWidth
              InputLabelProps={{
                shrink: true, // Mantém o label acima do campo
            }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(SwitchAndInputs);
