import { Box, TextField, useTheme } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import toastError from "../../errors/toastError";
import api from "../../services/api";

export function SingleUserFilter({ onFiltered, initialUser, selectedUser }) {
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await loadUsers();
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (initialUser && Array.isArray(users) && users.length > 0) {
      const initialSelection = users.find(user => user.id === initialUser);
      if (initialSelection) {
        onChange(initialSelection);
      }
    }
  }, [initialUser, users]);

  const loadUsers = async () => {
    try {
      const { data } = await api.get(`/users/list`);
      const userList = data.map((u) => ({ id: u.id, name: u.name }));
      setUsers(userList);
      const selectedUserObj = userList.find(user => user.id === selectedUser);
      setSelected(selectedUserObj || null);
    } catch (err) {
      toastError(err);
    }
  };

  const onChange = (value) => {
    setSelected(value);
    onFiltered(value);
  };

  const theme = useTheme();
  return (
    <Box>
      <Autocomplete
        size="small"
        options={users}
        value={selected}
        onChange={(e, value) => onChange(value)}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option?.id === value?.id}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder="User"
          />
        )}
      />
    </Box>
  );
}
