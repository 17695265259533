import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Box, Typography, Paper, useTheme } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box 
          p={3} 
          sx={{ 
            backgroundColor: "transparent", 
            borderBottomLeftRadius: '8px', 
            borderBottomRightRadius: '8px' 
          }}
        >
          <Typography sx={{ fontFamily: 'Poppins, sans-serif' }}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

export default function InfoRecomendationsTabs() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();
  
  return (
    <Paper 
      elevation={0} 
      sx={{ 
        maxWidth: 600, 
        boxShadow: 'none !important',
        margin: 'auto', 
        borderRadius: '8px', 
        overflow: 'hidden', 
        fontFamily: 'Poppins, sans-serif',
        color: theme.palette.primary.mainReverseByWhite,
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
      }}
    >
      <AppBar 
        position="static" 
        color="default" 
        elevation={0} 
        style={{ 
          backgroundColor: 'transparent', 
          borderTopLeftRadius: '8px', 
          borderTopRightRadius: '8px', 
          boxShadow: 'none',
          borderBottom: 'none'
        }}
      >
        <Tabs 
          value={value} 
          onChange={handleChange} 
          aria-label="tabs" 
          TabIndicatorProps={{ style: { display: 'none', boxShadow: 'none' } }}
        >
          <Tab style={{ 
              fontFamily: 'Poppins, sans-serif', 
              textTransform: 'none', 
              padding: '12px',
              borderRadius: '8px 8px 0 0',
              color: theme.palette.primary.mainReverseByWhite,
              border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
              borderBottom: "none"
            }} label="Informações" />
          <Tab style={{ 
              fontFamily: 'Poppins, sans-serif', 
              textTransform: 'none', 
              padding: '12px',
              borderRadius: '8px 8px 0 0',
              color: theme.palette.primary.mainReverseByWhite,
              border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
              borderBottom: "none"
            }} label="Recomendações" />
          <div style={{
              width: '101%',
              height: '50.5px', // Altura da aba
              borderBottom: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
              backgroundColor: 'transparent',
              marginBottom: '-2px', // Para evitar que a borda da aba se sobreponha
          }} />
        </Tabs>
      </AppBar>

      <TabPanel style={{
          border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
          borderTop: 'none',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
        }} value={value} index={0}>
        <ul style={{ paddingLeft: '1rem', listStyleType: 'disc', fontFamily: 'Poppins, sans-serif' }}>
          <li>Antes de conectar seu WhatsApp no sistema, remova a conexão com o WhatsApp Web.</li>
          <li>Depende do smartphone.</li>
          <li>Necessita de reconexão periódica.</li>
          <li>Não utilizar o WhatsApp no celular.</li>
        </ul>
      </TabPanel>
      <TabPanel style={{
          border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
          borderTop: 'none',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
        }} value={value} index={1}>
        <ul style={{ paddingLeft: '1rem', listStyleType: 'disc', fontFamily: 'Poppins, sans-serif' }}>
          <li>Para o perfeito funcionamento, mantenha o telefone sincronizado sempre ligado e conectado à internet, desative o descanso de tela e mantenha o app do WhatsApp aberto.</li>
          <li>Arquive pelo celular conversas dispensáveis ou mais antigas.</li>
          <li>Recomendamos o uso do navegador Chrome e do sistema operacional Windows.</li>
          <li>Não abrir o WhatsApp Web com o número sincronizado na plataforma em outro navegador. Caso isso aconteça, o funcionamento poderá ser impactado.</li>
        </ul>
      </TabPanel>
    </Paper>
  );
}
