import React from "react";
import {
  TextField,
  Switch,
  FormControlLabel,
  Grid,
  useTheme,
} from "@material-ui/core";

const WhatsAppProxy = ({
  modelType,
  enableLocalProxy,
  handleEnableLocalProxy,
  proxyHost,
  setProxyHost,
  proxyPort,
  setProxyPort,
  proxyUsername,
  setProxyUsername,
  proxyPassword,
  setProxyPassword,
}) => {
  const theme = useTheme();
  return (
    <>
      {modelType === "whatsapp" && (
        <Grid container spacing={1} alignItems="center">
          <div className="cmp-proxy" style={{width: "100%"}}>
            <FormControlLabel
              control={
                <Switch
                  checked={enableLocalProxy}
                  onChange={handleEnableLocalProxy}
                  color="primary"
                />
              }
              label="Proxy"
              labelPlacement="start" // Coloca o label à esquerda do switch
            />
          </div>
        </Grid>
      )}
      {modelType === "whatsapp" && enableLocalProxy && (
        <div style={{ padding: "1rem", borderRadius: "8px", border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`, margin: "1rem 0" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Proxy Host"
                variant="outlined"
                fullWidth
                margin="dense"
                value={proxyHost}
                onChange={(e) => setProxyHost(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Proxy Port"
                variant="outlined"
                fullWidth
                margin="dense"
                value={proxyPort}
                onChange={(e) => setProxyPort(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Proxy Username"
                variant="outlined"
                fullWidth
                margin="dense"
                value={proxyUsername}
                onChange={(e) => setProxyUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Proxy Password"
                variant="outlined"
                fullWidth
                margin="dense"
                type="password"
                value={proxyPassword}
                onChange={(e) => setProxyPassword(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default React.memo(WhatsAppProxy);
