import React, { useState, useEffect } from "react";
import { makeStyles, Backdrop, Modal, Paper } from "@material-ui/core";
import showdown from "showdown";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    position: "relative",
    width: "80%",
    maxHeight: "80%",
    overflowY: "auto",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    padding: theme.spacing(4),
    fontFamily: "'Poppins', sans-serif",
    boxShadow: theme.shadows[5],
    ...theme.scrollbarStyles,
  },
  header: {
    marginBottom: theme.spacing(4),
    textAlign: "center",
    fontWeight: 600,
    fontSize: "1.8rem",
    color: theme.palette.primary.main,
  },
  section: {
    marginBottom: theme.spacing(4),
  },
  closeBtn: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    cursor: "pointer",
    fontSize: "1.5rem",
    fontWeight: 600,
  },
  releaseTitle: {
    fontSize: "1.6rem",
    fontWeight: 700,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  releaseContent: {
    marginBottom: theme.spacing(3),
    lineHeight: 1.6,
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    "& h1, & h2": {
      color: theme.palette.primary.main,
    },
  },
}));

const ChangelogModal = ({ open, setOpen }) => {
  const classes = useStyles();
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    fetch("/Changelog.md")
      .then((response) => response.text())
      .then((markdown) => {
        const converter = new showdown.Converter();
        const html = converter.makeHtml(markdown);
        setHtmlContent(html); // Atualiza o estado com o conteúdo HTML

        // Lógica para verificar e sincronizar com localStorage
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = html;
        const firstH2 = tempDiv.querySelector("h2");
        if (firstH2) {
          const releaseVersion = firstH2.textContent.match(/release-[^ ]+/)?.[0]; // Captura a versão
          if (releaseVersion) {
            const storedRelease = localStorage.getItem("releaseKnowned");
            if (!storedRelease || storedRelease !== releaseVersion) {
              // Salva a nova versão e abre o modal se for diferente
              localStorage.setItem("releaseKnowned", releaseVersion);
              setOpen(true); // Abre o modal
            }
          }
        }
      })
      .catch((error) => {
        console.error("Erro ao carregar o arquivo Markdown:", error);
      });
  }, [setOpen]);

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modalContainer}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Paper className={classes.paper}>
        <span className={classes.closeBtn} onClick={handleClose}>
          &times;
        </span>
        <div className={classes.section}>
          <div
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            className={classes.releaseContent}
          />
        </div>
      </Paper>
    </Modal>
  );
};

export default ChangelogModal;
