import React from "react";
import { FaTicketAlt } from "react-icons/fa";
import MainContainer from "../../components/MainContainer";
import useStyles from "./styles";

const ContactTickets = ({ ticketsArray = [], onTicketClick }) => {
    const classes = useStyles();

    return (
        <MainContainer className={classes.mainContainer}>
            <div className={classes.header}>
                <h1 className={classes.title}>Tickets</h1>
            </div>

            <div className={classes.ticketsData}>
                {ticketsArray?.length > 0 ? (
                    ticketsArray.map((ticket, index) => (
                        <div key={index} className={classes.ticketItem} onClick={() => onTicketClick(ticket.ticketId)}>
                            <div className={classes.ticketIcon}>
                                <FaTicketAlt size={40} />
                            </div>
                            <div className={classes.ticketDetails}>
                                <p><strong>Ticket ID:</strong> {ticket.ticketId}</p>
                                <p><strong>Whatsapp ID:</strong> {ticket.whatsappId}</p>
                                <p><strong>Contact Type:</strong> {ticket.contactType}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>Nenhum ticket encontrado.</p>
                )}
            </div>
        </MainContainer>
    );
};

export default ContactTickets;
