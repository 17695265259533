import React, { useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Title from '../../components/Title';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0.5rem',
    padding: '1rem',
    borderRadius: "8px",
    maxHeight: 'calc(100vh - 112px)',
    height: '100%',
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    overflow: "auto",
    ...theme.scrollbarStyles,

    "& h5": {
      fontSize: "16px",
      fontWeight: "500"
    }
  },
  inputContainer: {
    display: 'flex',
    width: '100%',
    marginBottom: '1rem'
  },
  input: {
    flexGrow: 1,
    padding: 0,
    marginRight: '1rem',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.primary.mainReverseByWhite,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.mainReverseByWhite,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.mainReverseByWhite,
      },
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.mainReverseByWhite,
    },
  },
  listContainer: {
    width: '100%',
    height: '100%',
    marginTop: '1rem',
    backgroundColor: 'transparent',
    borderRadius: '8px',
    padding: '0.5rem',
  },
  list: {
    backgroundColor: 'transparent',
    borderRadius: '8px',
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}40`,
    marginBottom: '10px',
    fontFamily: "Poppins",
    color: theme.palette.primary.mainReverseByWhite,
    padding: '0.5rem 1rem',
    fontWeight: '500',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Adicionando sombra
    transition: 'box-shadow 0.3s ease-in-out', // Animação para suavidade
    '&:hover': {
      backgroundColor: `transparent`,
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)', // Sombra mais forte no hover
    },
  },
  btnStyle: {
    width: "56px",
    height: "56px",
    minWidth: "56px",
    minHeight: "56px",
    padding: "0",
    borderRadius: '8px',
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    background: 'transparent',
    color: theme.palette.primary.mainReverseByWhite,
    "&:hover": {
      background: 'transparent',
      border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    }
  },
  iconButton: {
    color: theme.palette.primary.mainReverseByWhite,
  }
}));


const ToDoList = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [task, setTask] = useState('');
  const [tasks, setTasks] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);

  useEffect(() => {
    const savedTasks = localStorage.getItem('tasks');
    if (savedTasks) {
      setTasks(JSON.parse(savedTasks));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  const handleTaskChange = (event) => {
    setTask(event.target.value);
  };

  const handleAddTask = () => {
    if (!task.trim()) {
      return;
    }

    const now = new Date();
    if (editIndex >= 0) {
      const newTasks = [...tasks];
      newTasks[editIndex] = {text: task, updatedAt: now, createdAt: newTasks[editIndex].createdAt};
      setTasks(newTasks);
      setTask('');
      setEditIndex(-1);
    } else {
      setTasks([...tasks, {text: task, createdAt: now, updatedAt: now}]);
      setTask('');
    }
  };

  const handleEditTask = (index) => {
    setTask(tasks[index].text);
    setEditIndex(index);
  };

  const handleDeleteTask = (index) => {
    const newTasks = [...tasks];
    newTasks.splice(index, 1);
    setTasks(newTasks);
  };

  return (
    <div className={classes.root}>
      <div style={{width: "100%", marginBottom: "1rem"}}>
        <Title style={{fontSize: "16px"}}>Lista de tarefas</Title>
      </div>
      <div className={classes.inputContainer}>
        <TextField
          className={classes.input}
          label="Nova tarefa"
          value={task}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={handleTaskChange}
          variant="outlined"
        />
        <Button className={classes.btnStyle} variant="contained" color="primary" onClick={handleAddTask}>
          {editIndex >= 0 ? 'Salvar' : '+'}
        </Button>
      </div>
      <div className={classes.listContainer}>
        <List>
          {tasks.map((task, index) => (
            <ListItem key={index} className={classes.list}>
              <ListItemText primary={task.text} secondary={task.updatedAt.toLocaleString()} />
              <ListItemSecondaryAction>
                <IconButton className={classes.iconButton} onClick={() => handleEditTask(index)}>
                  <EditIcon />
                </IconButton>
                <IconButton className={classes.iconButton} onClick={() => handleDeleteTask(index)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
};

export default ToDoList;
