import { useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import { socketConnection } from '../../services/socket';

const SuperBarChart = ({ data }) => {
    const theme = useTheme();
    const [formattedData, setFormattedData] = useState(
        data.map(entry => ({
            hour: entry.hour,
            count: Number(entry.count),
        }))
    );

    useEffect(() => {
        const companyId = localStorage.getItem("companyId");
        const socket = socketConnection({ companyId });

        socket.on("dashboard-updates", (newData) => {
            const updatedData =  !newData?.length ? data : newData.map(entry => ({
                hour: entry.hour,
                count: Number(entry.count),
            }));
            setFormattedData(updatedData);
        });

        return () => socket.disconnect();
    }, []);

    useEffect(() => {
        setFormattedData(data.map(entry => ({
            hour: entry.hour,
            count: Number(entry.count),
        })));
    }, [data]);

    return (
        <div style={{ 
            width: '100%', 
            height: '100%', 
            backgroundColor: 'white', 
            padding: '16px', 
            borderRadius: '8px', 
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' 
        }}>
            <h3 style={{ 
                fontFamily: 'Poppins, sans-serif', 
                color: theme.palette.primary.main, 
                marginBottom: '12px'
            }}>
                Tickets por hora
            </h3>
            <div style={{ width: '100%', height: '220px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        data={formattedData}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                            dataKey="hour" 
                            tickFormatter={(tick) => {
                                const hour = tick.split(' ')[1];
                                return hour ? `${hour}:00` : '';
                            }} 
                        />
                        <YAxis />
                        <Tooltip />
                        {/* Removido <Legend /> para esconder o "count" */}
                        <Bar dataKey="count" fill={theme.palette.primary.main} />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default SuperBarChart;
