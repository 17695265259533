import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
    BarChart,
    CartesianGrid,
    Bar,
    XAxis,
    YAxis,
    Label,
    ResponsiveContainer,
    Tooltip,
} from "recharts";
import { startOfHour, parseISO, format } from "date-fns";
import { i18n } from "../../translate/i18n";
import useTickets from "../../hooks/useTickets";

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px', borderRadius: '5px' }}>
                <p>{`Time: ${payload[0].payload.time}`}</p>
                <p>{`Tickets: ${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};

const Chart = ({ classes }) => {
    const theme = useTheme();
    const date = useRef(new Date().toISOString());
    const { tickets } = useTickets({ date: date.current });

    const [chartData, setChartData] = useState(
        Array.from({ length: 24 }, (_, i) => ({
            time: `${String(i).padStart(2, '0')}:00`,
            amount: 0,
        }))
    );

    useEffect(() => {
        const updatedChartData = Array.from({ length: 24 }, (_, i) => ({
            time: `${String(i).padStart(2, '0')}:00`,
            amount: 0,
        }));

        tickets.forEach(ticket => {
            const hour = format(startOfHour(parseISO(ticket.createdAt)), "HH:mm");
            const index = updatedChartData.findIndex(item => item.time === hour);
            if (index !== -1) {
                updatedChartData[index].amount++;
            }
        });

        setChartData(updatedChartData);
    }, [tickets]);

    return (
        <React.Fragment>
            <div className={classes.graphsBox} style={{ width: '90%', height: '252px', padding: "1rem" }}> {/* Ajuste a altura aqui */}
                <h3 className={classes.graphsBox}>{`${i18n.t("dashboard.charts.perDay.title")}${tickets.length}`}</h3>
                <ResponsiveContainer>
                    <BarChart
                        data={chartData}
                        barSize={40}
                    >
                        {/* Linhas de fundo retas */}
                        <CartesianGrid stroke="#e0e0e0" vertical={false} />
                        <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
                        <YAxis
                            type="number"
                            allowDecimals={false}
                            stroke={theme.palette.text.secondary}
                        >
                            <Label
                                angle={270}
                                position="right" // Corrigido para "right"
                                style={{ textAnchor: "middle", fill: theme.palette.primary.main }}
                            >
                                Tickets
                            </Label>
                        </YAxis>
                        <Tooltip content={<CustomTooltip />} />
                        {/* Barras em laranja bonito e transparente */}
                        <Bar dataKey="amount" fill={theme.palette.primary.main + "90"} animationDuration={500} /> {/* Laranja com 70% de opacidade */}
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </React.Fragment>
    );
};

export default Chart;
