import React from "react";

import {
  Switch,
  FormControlLabel,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";

import SwitchAndInputs from "./SwitchAndInputs";

const MultifieldInputs = ({
  whatsApp,
  modelType,
  touched,
  errors,
  values,
  setFieldValue,
  classes,
  isHubSelected,
  setIsHubSelected,
  fetchChannels,
  selectedChannel,
  setSelectedChannel,
  availableChannels
}) => {
  return (
    <div className={classes.multFieldLine}>

      <div style={{display: "flex", flexDirection:"column", justifyContent: "space-between", width: "100%"}}>
        <SwitchAndInputs 
          whatsApp={whatsApp}
          modelType={modelType} 
          touched={touched} 
          errors={errors} 
          classes={classes}
          values={values} 
          setFieldValue={setFieldValue} 
        />

        {!(modelType === "whatsapp") && modelType !== "official" && (
          <>
            <Grid style={{ paddingTop: 15 }} item>
              <FormControlLabel
                style={{ display: "none" }}
                control={
                  <Switch
                    checked={isHubSelected}
                    style={{ display: "none" }}
                    onChange={() => {
                      setIsHubSelected(true);
                      if (!isHubSelected) {
                        fetchChannels();
                      }
                    }}
                    color="primary"
                  />
                }
                label="Hub Notifcame"
              />
            </Grid>

            {!(modelType === "whatsapp") && isHubSelected && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Select
                  label="Select Channel"
                  fullWidth
                  value={selectedChannel || ""}
                  onChange={e => {
                    const value = e.target.value;
                    setSelectedChannel(value);
                  }}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Selecione um canal
                  </MenuItem>
                  {availableChannels
                    .filter(channel => channel.channel === modelType)
                    .map(channel => (
                      <MenuItem key={channel.id} value={channel.id}>
                        {channel.name}
                      </MenuItem>
                    ))}
                </Select>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default React.memo(MultifieldInputs);
