import React from "react";
import {
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    TextField,
    Select,
} from "@material-ui/core";
import { Field } from "formik";

export function FormInputs({ classes, plans }) {
    return (
        <>
            <Grid item xs={12} sm={6} md={4}>
                <Field
                    as={TextField}
                    label="Nome"
                    name="name"
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Field
                    as={TextField}
                    label="Razão Social"
                    name="corporateName"
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Field
                    as={TextField}
                    label="CNPJ"
                    name="cnpj"
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Field
                    as={TextField}
                    label="Inscrição Estadual"
                    name="stateRegistration"
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Field
                    as={TextField}
                    label="Inscrição Municipal"
                    name="municipalRegistration"
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Field
                    as={TextField}
                    label="Rua"
                    name="street"
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Field
                    as={TextField}
                    label="Número"
                    name="number"
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Field
                    as={TextField}
                    label="Bairro"
                    name="neighborhood"
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Field
                    as={TextField}
                    label="Cidade"
                    name="city"
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Field
                    as={TextField}
                    label="Estado"
                    name="state"
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Field
                    as={TextField}
                    label="CEP"
                    name="postalCode"
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Field
                    as={TextField}
                    label="Website"
                    name="website"
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <Field
                    as={TextField}
                    label="E-mail"
                    name="email"
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                    required
                />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <Field
                    as={TextField}
                    label="Telefone"
                    name="phone"
                    variant="outlined"
                    className={classes.fullWidth}
                    margin="dense"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <InputLabel htmlFor="plan-selection">Plano</InputLabel>
                    <Field
                        as={Select}
                        id="plan-selection"
                        label="Plano"
                        labelId="plan-selection-label"
                        name="planId"
                        margin="dense"
                        required
                    >
                        {plans.map((plan, key) => (
                            <MenuItem key={key} value={plan.id}>
                                {plan.name}
                            </MenuItem>
                        ))}
                    </Field>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <InputLabel htmlFor="status-selection">Status</InputLabel>
                    <Field
                        as={Select}
                        id="status-selection"
                        label="Status"
                        labelId="status-selection-label"
                        name="status"
                        margin="dense"
                    >
                        <MenuItem value={true}>Sim</MenuItem>
                        <MenuItem value={false}>Não</MenuItem>
                    </Field>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <InputLabel htmlFor="campaigns-selection">Campanhas</InputLabel>
                    <Field
                        as={Select}
                        id="campaigns-selection"
                        label="Campanhas"
                        labelId="campaigns-selection-label"
                        name="campaignsEnabled"
                        margin="dense"
                    >
                        <MenuItem value={true}>Habilitadas</MenuItem>
                        <MenuItem value={false}>Desabilitadas</MenuItem>
                    </Field>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <InputLabel htmlFor="calls-selection">Chamadas</InputLabel>
                    <Field
                        as={Select}
                        id="calls-selection"
                        label="Chamadas"
                        labelId="calls-selection-label"
                        name="callsEnabled"
                        margin="dense"
                    >
                        <MenuItem value={true}>Habilitadas</MenuItem>
                        <MenuItem value={false}>Desabilitadas</MenuItem>
                    </Field>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <FormControl variant="outlined" fullWidth>
                    <Field
                        as={TextField}
                        label="Data de Vencimento"
                        type="date"
                        name="dueDate"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        fullWidth
                        margin="dense"
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <FormControl margin="dense" variant="outlined" fullWidth>
                    <InputLabel htmlFor="recorrencia-selection">Recorrência</InputLabel>
                    <Field
                        as={Select}
                        label="Recorrência"
                        labelId="recorrencia-selection-label"
                        id="recurrence"
                        name="recurrence"
                        margin="dense"
                    >
                        <MenuItem value="MENSAL">Mensal</MenuItem>
                    </Field>
                </FormControl>
            </Grid>
        </>
    );
}

export default FormInputs;