import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Label,
} from 'recharts';

const SuperAdminDonutChart = ({ connectionCounts, classes }) => {
  const data = [
    { name: 'Baileys', value: connectionCounts?.baileys || 0 },
    { name: 'Official', value: connectionCounts?.official || 0 },
    { name: 'Instagram', value: connectionCounts?.instagram || 0 },
    { name: 'Facebook', value: connectionCounts?.facebook || 0 },
    { name: 'Connected', value: connectionCounts?.connected || 0 },
    { name: 'Total', value: connectionCounts?.total || 0 },
  ];

  const COLORS = [
    '#FF4500', // Cor para Baileys
    '#1E90FF', // Cor para Official
    '#C13584', // Cor para Instagram
    '#1877F2', // Cor para Facebook
    '#32CD32', // Cor para Connected
    '#FFD700', // Cor para Total
  ];

  return (
    <div className={classes.graphsBox} style={{ width: '100%', height: '287.41px', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1rem', justifyContent: "center" }}>
      <label>Conexões por Rede</label>
      <PieChart width={300} height={200}> {/* Dimensões ajustadas */}
        <Pie
          data={data}
          innerRadius="40%"
          outerRadius="60%"
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
          <Label value="Total" position="center" />
        </Pie>
        <Tooltip />
      </PieChart>
    </div>
  );
};

export default SuperAdminDonutChart;
