import {
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%'
    },
    mainPaper: {
        width: '100%',
        flex: 1,
    },
    fullWidth: {
        width: '100%'
    },
    tableContainer: {
        width: '100%',
        overflowX: "scroll",
        borderRadius: "8px",
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        ...theme.scrollbarStyles,
        "& .rbc-btn-group": {
            borderRadius: "8px",
            border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        },
        "& h5": {
            fontFamily: "Poppins",
            color: theme.palette.primary.mainReverseByWhite,
            fontWeight: '500',
            fontSize: "16px"
        },
        "& button": {
            color: theme.palette.primary.mainReverseByWhite,
        },

        "& h6": {
            fontFamily: "Poppins",
            color: theme.palette.primary.mainReverseByWhite,
            fontWeight: '500 !important',
            fontSize: "14px"
        },

        "& svg": {
            color: theme.palette.primary.mainReverseByWhite,
        },

        "& .MuiPaper-rounded": {
            border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        },

        "& th, p, & span,& td": {
            fontFamily: "Poppins",
            fontWeight: '500 !important',
            fontSize: "14px"
        },

        "& .MuiTablePagination-actions": {
            display: "flex",
            gap: "1rem"
        }
    },
    textfield: {
        width: '100%'
    },
    textRight: {
        textAlign: 'right'
    },
    row: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    control: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1)
    },
    buttonContainer: {
        textAlign: 'right',
        padding: theme.spacing(1)
    },
    btnStyles: {
        minWidth: "25px",
        width: "25px",
        height: "25px",
        minHeight: "25px",
        padding: "0",
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        borderRadius: "8px",
        color: theme.palette.primary.mainReverseByWhite,
        background: "transparent",
        "&:hover": {
          background: "transparent"
        }
      },
      modalTitle: {
		fontFamily: "Poppins",
		color: `${theme.palette.primary.mainReverseByWhite}`,
	},
    headerTexts: {
        color: theme.palette.primary.mainReverseByWhite,
        fontFamily: "Poppins",
        fontWeight: "600"
      }
}));

export default useStyles;