import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import useSettings from "../../hooks/useSettings";
import { toast } from 'react-toastify';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { grey, blue } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    fontFamily: "Poppins",
  },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  tab: {
    backgroundColor: theme.palette.options,
    borderRadius: 4,
    width: "100%",
    "& .MuiTab-wrapper": {
      color: theme.palette.fontecor,
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center"
    }
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    marginBottom: 12,
    width: "100%",
  },
  cardAvatar: {
    fontSize: "55px",
    color: grey[500],
    backgroundColor: "#ffffff",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  cardTitle: {
    fontSize: "18px",
    color: blue[700],
  },
  cardSubtitle: {
    color: grey[600],
    fontSize: "14px",
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  boxContainer: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    padding: "1rem",
    borderRadius: "8px",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
  boxContainerNoFlex: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    padding: "1rem",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },

}));

export default function Options(props) {
  const { settings, scheduleTypeChanged, getSettingValue, handleChangeSetting } = props;
  const classes = useStyles();
  const [userRating, setUserRating] = useState("disabled");
  const [scheduleType, setScheduleType] = useState("disabled");
  const [callType, setCallType] = useState("enabled");
  const [chatbotType, setChatbotType] = useState("");
  const [timeBotToOpen, setTimeBotToOpen] = useState("");
  const [loadingTimeBotToOpen, setLoadingTimeBotToOpen] = useState(false);
  const [callsEnabled, setCallsEnabled] = useState(false);
  const [loadingUserRating, setLoadingUserRating] = useState(false);
  const [loadingScheduleType, setLoadingScheduleType] = useState(false);
  const [loadingCallType, setLoadingCallType] = useState(false);
  const [loadingChatbotType, setLoadingChatbotType] = useState(false);
  const [isPhoneOnMenu, setIsPhoneOnMenu] = useState(false);
  const [hideMettingIconActive, setHideMettingIconActive] = useState(false);

  const [loadingCheckMsgIsGroup, setCheckMsgIsGroup] = useState("disabled");
  const [CheckMsgIsGroup, setCheckMsgIsGroupType] = useState("disabled");


  const [AlertKanban, setAlertKanban] = useState("disabled");
  const [MensagemKanban, setMensagemKanban] = useState("disabled");
  const [loadingAlertKanban, setLoadingAlertKanban] = useState(false);
  const [loadingMensagemKanban, setLoadingMensagemKanban] = useState(false);

  const [deleteTicketButton, setDeleteTicketButton] = useState("enabled");
  const [loadingDeleteTicketButton, setLoadingDeleteTicketButton] = useState(false);

  const [ipixcType, setIpIxcType] = useState("");
  const [loadingIpIxcType, setLoadingIpIxcType] = useState(false);
  const [tokenixcType, setTokenIxcType] = useState("");
  const [loadingTokenIxcType, setLoadingTokenIxcType] = useState(false);

  const [ipmkauthType, setIpMkauthType] = useState("");
  const [loadingIpMkauthType, setLoadingIpMkauthType] = useState(false);
  const [clientidmkauthType, setClientIdMkauthType] = useState("");
  const [loadingClientIdMkauthType, setLoadingClientIdMkauthType] = useState(false);
  const [clientsecretmkauthType, setClientSecrectMkauthType] = useState("");
  const [loadingClientSecrectMkauthType, setLoadingClientSecrectMkauthType] = useState(false);

  const [asaasType, setAsaasType] = useState("");
  const [loadingAsaasType, setLoadingAsaasType] = useState(false);

  const [SendGreetingAccepted, setSendGreetingAccepted] = useState("disabled");
  const [loadingSendGreetingAccepted, setLoadingSendGreetingAccepted] = useState(false);

  const [SettingsTransfTicket, setSettingsTransfTicket] = useState("disabled");
  const [contactType, setContactType] = useState("disabled");
  const [exportMsgType, setExportMsgType] = useState("disabled");

  const [loadingSettingsTransfTicket, setLoadingSettingsTransfTicket] = useState(false);
  const [UserViewTicketsWithoutQueue, setUserViewTicketsWithoutQueue] = useState("disabled");
  const [loadingUserViewTicketsWithoutQueue, setLoadingUserViewTicketsWithoutQueue] = useState(false);
  const [ticketButtonsToTop, setTicketsButtonsToTop] = useState(false);
  const [isDeleteConnectionButtonActive, setIsDeleteConnectionButtonActive] = useState(false);
  const [alwaysSignActive, setAlwaysSignActive] = useState(false);
  const [hideContactNumberActive, setHideContactNumberActive] = useState(false);

  const { update } = useSettings();

  if (loadingCheckMsgIsGroup) {
    console.log(loadingCheckMsgIsGroup);
  }

  if (loadingAlertKanban) {
    console.log(loadingAlertKanban, loadingMensagemKanban);
  }

  useEffect(() => {
    if (Array.isArray(settings) && settings.length) {
      const userRating = settings.find((s) => s.key === "userRating");
      if (userRating) {
        setUserRating(userRating.value);
      }
      const scheduleType = settings.find((s) => s.key === "scheduleType");
      if (scheduleType) {
        setScheduleType(scheduleType.value);
      }
      const callType = settings.find((s) => s.key === "call");
      if (callType) {
        setCallType(callType.value);
      }
      const contactType = settings.find((s) => s.key === "contactType");
      if (contactType) {
        setContactType(contactType.value);
      }
      const ExportMsgType = settings.find((s) => s.key === "ExportMsgType");
      if (ExportMsgType) {
        setExportMsgType(contactType.value);
      }
      const CheckMsgIsGroup = settings.find((s) => s.key === "CheckMsgIsGroup");
      if (CheckMsgIsGroup) {

        setCheckMsgIsGroupType(CheckMsgIsGroup.value);
      }

      const alertKanbanSetting = settings.find((s) => s.key === "AlertKanban");
      if (alertKanbanSetting) {
        setAlertKanban(alertKanbanSetting.value);
      }

      const mensagemKanbanSetting = settings.find((s) => s.key === "CheckMensagemKanban");
      if (mensagemKanbanSetting) {
        setMensagemKanban(mensagemKanbanSetting.value);
      }

      const deleteTicketButtonSetting = settings.find((s) => s.key === "deleteTicketButton");
      if (deleteTicketButtonSetting) {
        setDeleteTicketButton(deleteTicketButtonSetting.value);
      }
      const SendGreetingAccepted = settings.find((s) => s.key === "sendGreetingAccepted");
      if (SendGreetingAccepted) {
        setSendGreetingAccepted(SendGreetingAccepted.value);
      }
      const SettingsTransfTicket = settings.find((s) => s.key === "sendMsgTransfTicket");
      if (SettingsTransfTicket) {
        setSettingsTransfTicket(SettingsTransfTicket.value);
      }

      const chatbotType = settings.find((s) => s.key === "chatBotType");
      if (chatbotType) {
        setChatbotType(chatbotType.value);
      }

      const ipixcType = settings.find((s) => s.key === "ipixc");
      if (ipixcType) {
        setIpIxcType(ipixcType.value);
      }

      const tokenixcType = settings.find((s) => s.key === "tokenixc");
      if (tokenixcType) {
        setTokenIxcType(tokenixcType.value);
      }

      const ipmkauthType = settings.find((s) => s.key === "ipmkauth");
      if (ipmkauthType) {
        setIpMkauthType(ipmkauthType.value);
      }

      const clientidmkauthType = settings.find((s) => s.key === "clientidmkauth");
      if (clientidmkauthType) {
        setClientIdMkauthType(clientidmkauthType.value);
      }

      const clientsecretmkauthType = settings.find((s) => s.key === "clientsecretmkauth");
      if (clientsecretmkauthType) {
        setClientSecrectMkauthType(clientsecretmkauthType.value);
      }

      const asaasType = settings.find((s) => s.key === "asaas");
      if (asaasType) {
        setAsaasType(asaasType.value);
      }

      const userViewTicketsWithoutQueue = settings.find((s) => s.key === "userViewTicketsWithoutQueue");
      if (userViewTicketsWithoutQueue) {
        setUserViewTicketsWithoutQueue(userViewTicketsWithoutQueue.value);
      }

      const timeSetting = settings.find((s) => s.key === "timeBotToOpen");
      if (timeSetting) {
        setTimeBotToOpen(timeSetting.value);
      }

      const callsEnabledData = settings.find((s) => s.key === "callsEnabled");
      if (callsEnabledData) {
        setCallsEnabled(callsEnabledData.value)
        localStorage.setItem("callEnabled", JSON.stringify((callsEnabledData.value === "true")))
      }

      const ticketButtonsToTopData = settings.find((s) => s.key === "ticketButtonsToTop");
      if (ticketButtonsToTopData) {
        setTicketsButtonsToTop(ticketButtonsToTopData.value)
        localStorage.setItem("ticketButtonsToTop", JSON.stringify((ticketButtonsToTopData.value === "true")))
      }

      const isPhoneOnMenuData = settings.find((s) => s.key === "isPhoneOnMenu");
      if (isPhoneOnMenuData) {
        setIsPhoneOnMenu(isPhoneOnMenuData.value)
        localStorage.setItem("isPhoneOnMenu", JSON.stringify((isPhoneOnMenuData.value === "true")))
      }

      const isDeleteConnectionButtonActiveData = settings.find((s) => s.key === "isDeleteConnectionButtonActive");
      if (isDeleteConnectionButtonActiveData) {
        setIsDeleteConnectionButtonActive(isDeleteConnectionButtonActiveData.value)
        localStorage.setItem("isDeleteConnectionButtonActive", JSON.stringify((isDeleteConnectionButtonActiveData.value === "true")))
      }

      const hideMettingIconActiveData = settings.find((s) => s.key === "hideMettingIconActive");
      if (hideMettingIconActiveData) {
        setHideMettingIconActive(hideMettingIconActiveData.value)
        localStorage.setItem("hideMettingIconActive", JSON.stringify((hideMettingIconActiveData.value === "true")))
      }

      const alwaysSignActiveData = settings.find((s) => s.key === "alwaysSignActive");
      if (alwaysSignActiveData) {
        setAlwaysSignActive(alwaysSignActiveData.value)
        localStorage.setItem("alwaysSignActive", JSON.stringify((alwaysSignActiveData.value === "true")))
      }

      const hideContactNumberActiveData = settings.find((s) => s.key === "hideContactNumberActive");
      if (hideContactNumberActiveData) {
        setHideContactNumberActive(hideContactNumberActiveData.value)
        localStorage.setItem("hideContactNumberActive", JSON.stringify((hideContactNumberActiveData.value === "true")))
      }
    }
  }, [settings]);

  async function handleDeleteTicketButtonChange(value) {
    setDeleteTicketButton(value);
    setLoadingDeleteTicketButton(true);
    try {
      await update({
        key: "deleteTicketButton",
        value,
      });
      toast.success("Operação atualizada com sucesso.");
    } catch (error) {
      toast.error("Erro ao atualizar configuração.");
    } finally {
      setLoadingDeleteTicketButton(false);
    }
  }

  async function handleTimeBotToOpenChange(value) {
    setTimeBotToOpen(value);
    setLoadingTimeBotToOpen(true);
    await update({
      key: "timeBotToOpen",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingTimeBotToOpen(false);
  }

  async function handleUserViewTicketsWithoutQueue(value) {
    setUserViewTicketsWithoutQueue(value);
    setLoadingUserViewTicketsWithoutQueue(true);
    await update({
      key: "userViewTicketsWithoutQueue",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingUserViewTicketsWithoutQueue(false);
  }

  async function handleChangeUserRating(value) {
    setUserRating(value);
    setLoadingUserRating(true);
    await update({
      key: "userRating",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingUserRating(false);
  }

  async function handleScheduleType(value) {
    setScheduleType(value);
    setLoadingScheduleType(true);
    await update({
      key: "scheduleType",
      value,
    });
    //toast.success("Oraçãpeo atualizada com sucesso.");
    toast.success('Operação atualizada com sucesso.', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      theme: "light",
    });
    setLoadingScheduleType(false);
    if (typeof scheduleTypeChanged === "function") {
      scheduleTypeChanged(value);
    }
  }

  async function handleCallType(value) {
    setCallType(value);
    setLoadingCallType(true);
    await update({
      key: "call",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingCallType(false);
  }

  async function handleChatbotType(value) {
    setChatbotType(value);
    setLoadingChatbotType(true);
    await update({
      key: "chatBotType",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingChatbotType(false);
  }

  async function handleGroupType(value) {
    setCheckMsgIsGroupType(value);
    setCheckMsgIsGroup(true);
    await update({
      key: "CheckMsgIsGroup",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    // setCheckMsgIsGroupType(false);
    /*     if (typeof scheduleTypeChanged === "function") {
          scheduleTypeChanged(value);
        } */
  }

  async function handleAlertKanban(value) {
    setAlertKanban(value);
    setLoadingAlertKanban(true);
    await update({
      key: "AlertKanban",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingAlertKanban(false);
  }

  async function handleMensagemKanban(value) {
    setMensagemKanban(value);
    setLoadingMensagemKanban(true);
    await update({
      key: "CheckMensagemKanban",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingMensagemKanban(false);
  }

  async function handleSendGreetingAccepted(value) {
    setSendGreetingAccepted(value);
    setLoadingSendGreetingAccepted(true);
    await update({
      key: "sendGreetingAccepted",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingSendGreetingAccepted(false);
  }

  async function handleSettingsTransfTicket(value) {
    setSettingsTransfTicket(value);
    setLoadingSettingsTransfTicket(true);
    await update({
      key: "sendMsgTransfTicket",
      value,
    });

    toast.success("Operação atualizada com sucesso.");
    setLoadingSettingsTransfTicket(false);
  }

  async function handleContactType(value) {
    setContactType(value);
    setLoadingSettingsTransfTicket(true);
    await update({
      key: "contactType",
      value,
    });

    toast.success("Operação atualizada com sucesso.");
    setLoadingSettingsTransfTicket(false);
  }

  async function handleTicketsButtonsToTop(value) {
    setTicketsButtonsToTop(value);
    await update({
      key: "ticketButtonsToTop",
      value,
    });
    localStorage.setItem("ticketButtonsToTop", JSON.stringify((value === "true")))
    toast.success("Operação atualizada com sucesso.");
  }
  async function handleIsDeleteConnectionButtonActive(value) {
    setIsDeleteConnectionButtonActive(value);
    await update({
      key: "isDeleteConnectionButtonActive",
      value,
    });
    localStorage.setItem("isDeleteConnectionButtonActive", JSON.stringify((value === "true")))
    toast.success("Operação atualizada com sucesso.");
  }

  async function handleExportMsgType(value) {
    setExportMsgType(value); // Atualiza o estado corretamente
    await update({
      key: "exportMsgType",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
  }

  async function handleChangeIPIxc(value) {
    setIpIxcType(value);
    setLoadingIpIxcType(true);
    await update({
      key: "ipixc",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingIpIxcType(false);
  }

  async function handleChangeTokenIxc(value) {
    setTokenIxcType(value);
    setLoadingTokenIxcType(true);
    await update({
      key: "tokenixc",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingTokenIxcType(false);
  }

  async function handleChangeIpMkauth(value) {
    setIpMkauthType(value);
    setLoadingIpMkauthType(true);
    await update({
      key: "ipmkauth",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingIpMkauthType(false);
  }

  async function handleChangeClientIdMkauth(value) {
    setClientIdMkauthType(value);
    setLoadingClientIdMkauthType(true);
    await update({
      key: "clientidmkauth",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingClientIdMkauthType(false);
  }

  async function handleChangeClientSecrectMkauth(value) {
    setClientSecrectMkauthType(value);
    setLoadingClientSecrectMkauthType(true);
    await update({
      key: "clientsecretmkauth",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingClientSecrectMkauthType(false);
  }

  async function handleChangeAsaas(value) {
    setAsaasType(value);
    setLoadingAsaasType(true);
    await update({
      key: "asaas",
      value,
    });
    toast.success("Operação atualizada com sucesso.");
    setLoadingAsaasType(false);
  }

  const theme = useTheme();
  const handleChangeWhatsappToken = async (value) => {
    setCallsEnabled(value)
    await update({
      key: "callsEnabled",
      value,
    });
    localStorage.setItem("callEnabled", JSON.stringify((value === "true")))
  }

  const handleChangeCallsOnPopUp = async (value) => {
    setIsPhoneOnMenu(value)
    await update({
      key: "isPhoneOnMenu",
      value,
    });
    localStorage.setItem("isPhoneOnMenu", JSON.stringify((value === "true")))
  }

  const handleHideMettingIconActive = async (value) => {
    setHideMettingIconActive(value)
    await update({
      key: "hideMettingIconActive",
      value,
    });
    localStorage.setItem("hideMettingIconActive", JSON.stringify((value === "true")))
  }

  const handleAlwaysSignActive = async (value) => {
    setAlwaysSignActive(value)
    await update({
      key: "alwaysSignActive",
      value,
    });
    localStorage.setItem("alwaysSignActive", JSON.stringify((value === "true")))
  }

  const handleHideContactNumberActive = async (value) => {
    setHideContactNumberActive(value)
    await update({
      key: "hideContactNumberActive",
      value,
    });
    localStorage.setItem("hideContactNumberActive", JSON.stringify((value === "true")))
  }

  return (
    <>
      <h5 style={{ fontSize: "14px", fontFamily: "Poppins", color: theme.palette.primary.mainReverseByWhite, fontWeight: "500", marginBottom: "1rem" }}>Preferências</h5>
      <Grid spacing={3} style={{ gap: "1rem" }} className={classes.boxContainer}>
        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} >
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="always-sign-btn">Esconder número dos contato para seus Usuários</InputLabel>
            <Select
              labelId="always-sign-btn"
              value={hideContactNumberActive}
              onChange={async (e) => {
                handleHideContactNumberActive(e.target.value);
              }}
            >
              <MenuItem value={"false"}>Desabilitadas</MenuItem>
              <MenuItem value={"true"}>Habilitadas</MenuItem>
            </Select>
            <FormHelperText>
              {loadingUserRating && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} >
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="always-sign-btn">Sempre assinar</InputLabel>
            <Select
              labelId="always-sign-btn"
              value={alwaysSignActive}
              onChange={async (e) => {
                handleAlwaysSignActive(e.target.value);
              }}
            >
              <MenuItem value={"false"}>Desabilitadas</MenuItem>
              <MenuItem value={"true"}>Habilitadas</MenuItem>
            </Select>
            <FormHelperText>
              {loadingUserRating && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} >
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="meeting-button-hidden">Esconder chamadas de video do atendimento</InputLabel>
            <Select
              labelId="meeting-button-hidden"
              value={hideMettingIconActive}
              onChange={async (e) => {
                handleHideMettingIconActive(e.target.value);
              }}
            >
              <MenuItem value={"false"}>Desabilitadas</MenuItem>
              <MenuItem value={"true"}>Habilitadas</MenuItem>
            </Select>
            <FormHelperText>
              {loadingUserRating && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} >
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="delete-connections-button-hidden">Mostrar botão de deletar conexão</InputLabel>
            <Select
              labelId="delete-connections-button-hidden"
              value={isDeleteConnectionButtonActive}
              onChange={async (e) => {
                handleIsDeleteConnectionButtonActive(e.target.value);
              }}
            >
              <MenuItem value={"false"}>Desabilitadas</MenuItem>
              <MenuItem value={"true"}>Habilitadas</MenuItem>
            </Select>
            <FormHelperText>
              {loadingUserRating && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} >
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="calls-on-pop-up-label">Icone Chamadas no Menu</InputLabel>
            <Select
              labelId="calls-on-pop-up-label"
              value={isPhoneOnMenu}
              onChange={async (e) => {
                handleChangeCallsOnPopUp(e.target.value);
              }}
            >
              <MenuItem value={"false"}>Desabilitadas</MenuItem>
              <MenuItem value={"true"}>Habilitadas</MenuItem>
            </Select>
            <FormHelperText>
              {loadingUserRating && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} >
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="calls-enabled-label">Token para WhatsApp</InputLabel>
            <Select
              labelId="calls-enabled-label"
              value={callsEnabled}
              onChange={async (e) => {
                handleChangeWhatsappToken(e.target.value);
              }}
            >
              <MenuItem value={"false"}>Desabilitadas</MenuItem>
              <MenuItem value={"true"}>Habilitadas</MenuItem>
            </Select>
            <FormHelperText>
              {loadingUserRating && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} >
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="calls-enabled-label">(Atendimento) - Botões de ação dentro do Chat</InputLabel>
            <Select
              labelId="calls-enabled-label"
              value={ticketButtonsToTop}
              onChange={async (e) => {
                handleTicketsButtonsToTop(e.target.value);
              }}
            >
              <MenuItem value={"false"}>Desabilitadas</MenuItem>
              <MenuItem value={"true"}>Habilitadas</MenuItem>
            </Select>
            <FormHelperText>
              {loadingUserRating && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} >
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="ratings-label">Avaliações</InputLabel>
            <Select
              labelId="ratings-label"
              value={userRating}
              onChange={async (e) => {
                handleChangeUserRating(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desabilitadas</MenuItem>
              <MenuItem value={"enabled"}>Habilitadas</MenuItem>
            </Select>
            <FormHelperText>
              {loadingUserRating && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
          <FormControl className={classes.selectContainer}>
            <TextField
              id="timeBotToOpen"
              name="timeBotToOpen"
              margin="dense"
              label="Qualidade (s)"
              variant="outlined"
              value={timeBotToOpen}
              InputLabelProps={{
                shrink: true,
                style: { color: theme.palette.primary.mainReverseByWhite }
              }}
              onChange={async (e) => {
                handleTimeBotToOpenChange(e.target.value);
              }}
              type="number"
            />
            <FormHelperText>
              {loadingTimeBotToOpen && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="schedule-type-label">
              Gerenciamento de Expediente
            </InputLabel>
            <Select
              labelId="schedule-type-label"
              value={scheduleType}
              onChange={async (e) => {
                handleScheduleType(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desabilitado</MenuItem>
              <MenuItem value={"queue"}>Fila</MenuItem>
              <MenuItem value={"company"}>Empresa</MenuItem>
            </Select>
            <FormHelperText>
              {loadingScheduleType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="group-type-label">
              Receber mensagens de grupos
            </InputLabel>
            <Select
              labelId="group-type-label"
              value={CheckMsgIsGroup}
              onChange={async (e) => {
                handleGroupType(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Receber</MenuItem>
              <MenuItem value={"enabled"}>Não receber</MenuItem>
            </Select>
            <FormHelperText>
              {loadingScheduleType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>


        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="group-type-label">
              Alerta do kanban
            </InputLabel>
            <Select
              labelId="group-type-label"
              value={AlertKanban}
              onChange={async (e) => {
                handleAlertKanban(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Não receber</MenuItem>
              <MenuItem value={"enabled"}>Receber</MenuItem>
            </Select>
            <FormHelperText>
              {loadingScheduleType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="group-type-label">
              Enviar mensagem kanban
            </InputLabel>
            <Select
              labelId="group-type-label"
              value={MensagemKanban}
              onChange={async (e) => {
                handleMensagemKanban(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Não receber</MenuItem>
              <MenuItem value={"enabled"}>Receber</MenuItem>
            </Select>
            <FormHelperText>
              {loadingScheduleType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="call-type-label">
              Aceitar Chamada
            </InputLabel>
            <Select
              labelId="call-type-label"
              value={callType}
              onChange={async (e) => {
                handleCallType(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Não Aceitar</MenuItem>
              <MenuItem value={"enabled"}>Aceitar</MenuItem>
            </Select>
            <FormHelperText>
              {loadingCallType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="delete-ticket-button-label">Excluir Botão Deletar Ticket</InputLabel>
            <Select
              labelId="delete-ticket-button-label"
              value={deleteTicketButton}
              onChange={async (e) => {
                handleDeleteTicketButtonChange(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desabilitado</MenuItem>
              <MenuItem value={"enabled"}>Habilitado</MenuItem>
            </Select>
            <FormHelperText>
              {loadingDeleteTicketButton && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="chatbot-type-label">
              Tipo Chatbot
            </InputLabel>
            <Select
              labelId="chatbot-type-label"
              value={chatbotType}
              onChange={async (e) => {
                handleChatbotType(e.target.value);
              }}
            >
              <MenuItem value={"text"}>Texto</MenuItem>
              {/*<MenuItem value={"button"}>Botão</MenuItem>*/}
              {/*<MenuItem value={"list"}>Lista</MenuItem>*/}
            </Select>
            <FormHelperText>
              {loadingChatbotType && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        {/* ENVIAR SAUDAÇÃO AO ACEITAR O TICKET */}
        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="sendGreetingAccepted-label">Enviar saudação ao aceitar o ticket</InputLabel>
            <Select
              labelId="sendGreetingAccepted-label"
              value={SendGreetingAccepted}
              onChange={async (e) => {
                handleSendGreetingAccepted(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desabilitado</MenuItem>
              <MenuItem value={"enabled"}>Habilitado</MenuItem>
            </Select>
            <FormHelperText>
              {loadingSendGreetingAccepted && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>
        {/* ENVIAR SAUDAÇÃO AO ACEITAR O TICKET */}

        {/* ENVIAR MENSAGEM DE TRANSFERENCIA DE SETOR/ATENDENTE */}
        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="sendMsgTransfTicket-label">Enviar mensagem de transferencia de Fila/agente</InputLabel>
            <Select
              labelId="sendMsgTransfTicket-label"
              value={SettingsTransfTicket}
              onChange={async (e) => {
                handleSettingsTransfTicket(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desabilitado</MenuItem>
              <MenuItem value={"enabled"}>Habilitado</MenuItem>
            </Select>

            <FormHelperText>
              {loadingSettingsTransfTicket && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="handleContactType-label">Lista de contatos</InputLabel>

            <Select
              labelId="handleContactType-label"
              value={contactType} // Corrigir de ContactType para contactType
              onChange={async (e) => {
                handleContactType(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Usuário</MenuItem>
              <MenuItem value={"enabled"}>Global</MenuItem>
            </Select>
            <FormHelperText>
              {loadingSettingsTransfTicket && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="handleContactType-label">Exportação de mensagens</InputLabel>
            <Select
              labelId="handleExportMsgType-label"
              value={exportMsgType} // Usa o estado renomeado
              onChange={async (e) => {
                handleExportMsgType(e.target.value); // Usa a função handleExportMsgType corretamente
              }}
            >
              <MenuItem value={"disabled"}>Usuário</MenuItem>
              <MenuItem value={"enabled"}>Global</MenuItem>
            </Select>
            <FormHelperText>
              {loadingSettingsTransfTicket && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
          <FormControl className={classes.selectContainer}>
            <InputLabel style={{ color: theme.palette.primary.mainReverseByWhite }} id="userViewTicketsWithoutQueue-label">Pemitir perfil "User" visualizar tickets sem fila</InputLabel>
            <Select
              labelId="userViewTicketsWithoutQueue-label"
              value={UserViewTicketsWithoutQueue}
              onChange={async (e) => {
                handleUserViewTicketsWithoutQueue(e.target.value);
              }}
            >
              <MenuItem value={"disabled"}>Desabilitado</MenuItem>
              <MenuItem value={"enabled"}>Habilitado</MenuItem>
            </Select>
            <FormHelperText>
              {loadingUserViewTicketsWithoutQueue && "Atualizando..."}
            </FormHelperText>
          </FormControl>
        </Grid>

      </Grid>
      <h5 style={{ margin: "1rem 0", fontSize: "14px", fontFamily: "Poppins", color: theme.palette.primary.mainReverseByWhite, fontWeight: "500", marginBottom: "1rem" }}>Integrações</h5>
      <Grid spacing={3} className={classes.boxContainerNoFlex}>
        {/*-----------------IXC-----------------*/}
        <h6 style={{ margin: "1rem 0", fontSize: "12px", fontFamily: "Poppins", color: theme.palette.primary.mainReverseByWhite, fontWeight: "500", marginBottom: "1rem" }}>IXC</h6>
        <Grid spacing={3} container
          style={{ marginBottom: 10 }}>

          <Grid xs={12} sm={6} md={6} item>
            <FormControl className={classes.selectContainer}>
              <TextField
                id="ipixc"
                name="ipixc"
                margin="dense"
                label="IP do IXC"
                variant="outlined"
                value={ipixcType}
                onChange={async (e) => {
                  handleChangeIPIxc(e.target.value);
                }}
              >
              </TextField>
              <FormHelperText>
                {loadingIpIxcType && "Atualizando..."}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6} md={6} item>
            <FormControl className={classes.selectContainer}>
              <TextField
                id="tokenixc"
                name="tokenixc"
                margin="dense"
                label="Token do IXC"
                variant="outlined"
                value={tokenixcType}
                onChange={async (e) => {
                  handleChangeTokenIxc(e.target.value);
                }}
              >
              </TextField>
              <FormHelperText>
                {loadingTokenIxcType && "Atualizando..."}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {/*-----------------MK-AUTH-----------------*/}
        <h6 style={{ margin: "1rem 0", fontSize: "12px", fontFamily: "Poppins", color: theme.palette.primary.mainReverseByWhite, fontWeight: "500", marginBottom: "1rem" }}>MK-AUTH</h6>
        <Grid spacing={3} container
          style={{ marginBottom: 10 }}>
          <Grid xs={12} sm={12} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
            <FormControl className={classes.selectContainer}>
              <TextField
                id="ipmkauth"
                name="ipmkauth"
                margin="dense"
                label="Ip Mk-Auth"
                variant="outlined"
                value={ipmkauthType}
                onChange={async (e) => {
                  handleChangeIpMkauth(e.target.value);
                }}
              >
              </TextField>
              <FormHelperText>
                {loadingIpMkauthType && "Atualizando..."}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={12} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
            <FormControl className={classes.selectContainer}>
              <TextField
                id="clientidmkauth"
                name="clientidmkauth"
                margin="dense"
                label="Client Id"
                variant="outlined"
                value={clientidmkauthType}
                onChange={async (e) => {
                  handleChangeClientIdMkauth(e.target.value);
                }}
              >
              </TextField>
              <FormHelperText>
                {loadingClientIdMkauthType && "Atualizando..."}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={12} style={{ maxWidth: "49%", flexBasis: "49%" }} item>
            <FormControl className={classes.selectContainer}>
              <TextField
                id="clientsecretmkauth"
                name="clientsecretmkauth"
                margin="dense"
                label="Client Secret"
                variant="outlined"
                value={clientsecretmkauthType}
                onChange={async (e) => {
                  handleChangeClientSecrectMkauth(e.target.value);
                }}
              >
              </TextField>
              <FormHelperText>
                {loadingClientSecrectMkauthType && "Atualizando..."}
              </FormHelperText>
            </FormControl>
          </Grid>

        </Grid>
        {/*-----------------ASAAS-----------------*/}
        <h6 style={{ margin: "1rem 0", fontSize: "12px", fontFamily: "Poppins", color: theme.palette.primary.mainReverseByWhite, fontWeight: "500", marginBottom: "1rem" }}>ASAAS</h6>
        <Grid spacing={3} container
          style={{ marginBottom: 10 }}>
          <Grid xs={12} sm={12} md={12} item>
            <FormControl className={classes.selectContainer}>
              <TextField
                id="asaas"
                name="asaas"
                margin="dense"
                label="Token Asaas"
                variant="outlined"
                value={asaasType}
                onChange={async (e) => {
                  handleChangeAsaas(e.target.value);
                }}
              >
              </TextField>
              <FormHelperText>
                {loadingAsaasType && "Atualizando..."}
              </FormHelperText>
            </FormControl>
          </Grid>

        </Grid>
        <TextField
          id="hub-token-setting"
          label="Hub Token"
          name="hubToken"
          margin="dense"
          variant="outlined"
          fullWidth
          value={settings && settings.length > 0 && getSettingValue("hubToken")}
          onChange={handleChangeSetting}
          InputProps={{
            readOnly: false
          }}
        />
      </Grid>
    </>
  );
}
