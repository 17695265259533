import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Typography,
  Divider,
  Grid,
  TextField,
  Modal,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  useTheme,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { toast } from 'react-toastify';
import api from '../../services/api';
import useWhatsApps from '../../hooks/useWhatsApps';
import { AuthContext } from '../../context/Auth/AuthContext';

const SaudacaoComponent = () => {
  const [open, setOpen] = useState(false);
  const [mensagem, setMessage] = useState('');
  const [whatsappId, setWhatsappId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [editingId, setEditingId] = useState(null);
  const [groups, setGroups] = useState([]);
  const [saudacoes, setSaudacoes] = useState([]);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const { whatsApps, loading } = useWhatsApps();
  const { user } = useContext(AuthContext);

  const companyId = localStorage.getItem('companyId');

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setMessage('');
    setWhatsappId('');
    setGroupId('');
    setEditingId(null);
  };

  useEffect(() => {
    const fetchGroups = async () => {
      if (whatsappId) {
        try {
          setIsLoadingGroups(true);
          const { data } = await api.get('/api/groups/list-groups', {
            params: { whatsappId }
          });
          setGroups(data.groups);
        } catch (error) {
          toast.error('Erro ao buscar grupos.');
        } finally {
          setIsLoadingGroups(false);
        }
      }
    };

    fetchGroups();
  }, [whatsappId]);

  const fetchSaudacoes = async () => {
    try {
      const { data } = await api.get('/api/groups/saudacoes', {
        params: { companyId }
      });

      if (data.data.length === 0) {
        toast.info('Nenhuma saudação encontrada.');
      } else {
        setSaudacoes(data.data); // Atualiza o estado com as saudações
      }
    } catch (error) {
    }
  };


  useEffect(() => {
    const fetchSaudacoesEF = async () => {
      try {
        const { data } = await api.get('/api/groups/saudacoes', {
          params: { companyId }
        });
  
        if (data.data.length === 0) {
          toast.info('Nenhuma saudação encontrada.');
        } else {
          setSaudacoes(data.data); // Atualiza o estado com as saudações
        }
      } catch (error) {
      }
    };
    if (companyId) {
      fetchSaudacoesEF();
    }
  }, [companyId ]);

  const handleSave = async () => {
    if (!mensagem || (!editingId && (!whatsappId || !groupId))) {
      toast.error('Preencha todos os campos.');
      return;
    }

    try {
      if (editingId) {
        await api.put(`/api/groups/saudacao/${editingId}`, { mensagem, userId: user.id, companyId });
        toast.success('Mensagem de saudação atualizada com sucesso!');
      } else {
        await api.post('/api/groups/saudacao', { mensagem, whatsappId, groupId, userId: user.id, companyId });
        toast.success('Mensagem de saudação salva com sucesso!');
      }
      handleClose();
      fetchSaudacoes();
    } catch (error) {
      toast.error('Erro ao salvar a saudação.');
    }
  };


  const handleEdit = (saudacao) => {
    setEditingId(saudacao.id);
    setMessage(saudacao.mensagem);
    setWhatsappId(saudacao.whatsappId);
    setGroupId(saudacao.groupId);
    handleOpen();
  };

  const handleDelete = async (id) => {
    try {
      await api.delete(`/api/groups/saudacao/${id}`);
      toast.success('Saudação deletada com sucesso!');
      fetchSaudacoes();
    } catch (error) {
      toast.error('Erro ao deletar a saudação.');
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? 'Data Inválida'
      : date.toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
  };

  const theme = useTheme();

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Adicionar Saudação
          </Button>
        </Grid>
      </Grid>

      <Modal  open={open} onClose={handleClose}>
        <Box
          style={{
            background: 'white',
            padding: '30px',
            margin: '4% auto',
            maxWidth: '600px',
            borderRadius: '8px',
            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`
          }}
        >
          <Typography variant="h4" color="primary" style={{
            fontFamily: "Poppins",
            color: theme.palette.primary.mainReverseByWhite,
            fontWeight: '500',
            fontSize: "16px",
            paddingBottom: "1rem"
          }}>
            {editingId ? "Editar Saudação" : "Criar Saudação"}
          </Typography>
          <Divider style={{ marginBottom: '1.5rem' }} />

          <TextField
            fullWidth
            InputLabelProps={{
              shrink: true,
              style: { color: theme.palette.primary.mainReverseByWhite },
            }}
            InputProps={{
              style: {
                color: theme.palette.primary.mainReverseByWhite,
              },
              classes: {
                notchedOutline: {
                  borderColor: `${theme.palette.primary.mainReverseByWhite} !important`,
                },
              },
            }}
            label="Mensagem de Saudação"
            value={mensagem}
            onChange={(e) => setMessage(e.target.value)}
            variant="outlined"
            style={{ marginBottom: '1rem' }}
          />

          {editingId ? null : (
            <>
              <FormControl fullWidth variant="outlined" style={{ marginBottom: '1rem' }}>
                <InputLabel>WhatsApp ID</InputLabel>
                <Select
                  value={whatsappId}
                  onChange={(e) => setWhatsappId(e.target.value)}
                  label="WhatsApp ID"
                  disabled={loading}
                >
                  {whatsApps.filter((contact) => contact.type === null).map((whatsApp) => (
                    <MenuItem key={whatsApp.id} value={whatsApp.id}>
                      {whatsApp.name || whatsApp.number}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined" style={{ marginBottom: '1rem' }}>
                <InputLabel>Grupo</InputLabel>
                <Select
                  value={groupId}
                  onChange={(e) => setGroupId(e.target.value)}
                  label="Grupo"
                  disabled={isLoadingGroups || groups.length === 0}
                >
                  {groups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.subject}
                    </MenuItem>
                  ))}
                </Select>
                {isLoadingGroups && <CircularProgress size={24} />}
              </FormControl>
            </>
          )}

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                onClick={handleClose}
                fullWidth
                style={{
                  color: theme.palette.primary.mainReverseByWhite,
                  fontWeigth: "500",
                  cursor: "pointer",
                  background: "transparent",
                  border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
                  borderRadius: "8px",
                }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={handleSave}
                fullWidth
                style={{
                  color: theme.palette.primary.mainReverseByWhite,
                  fontWeigth: "500",
                  cursor: "pointer",
                  background: "transparent",
                  border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
                  borderRadius: "8px",
                }}
              >
                {editingId ? "Atualizar" : "Salvar"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ color: theme.palette.primary.mainReverseByWhite }}>ID</TableCell>
              <TableCell style={{ color: theme.palette.primary.mainReverseByWhite }}>Mensagem</TableCell>
              <TableCell style={{ color: theme.palette.primary.mainReverseByWhite }}>Grupo</TableCell>
              <TableCell style={{ color: theme.palette.primary.mainReverseByWhite }}>Criado Por</TableCell>
              <TableCell style={{ color: theme.palette.primary.mainReverseByWhite }}>Data Criada</TableCell>
              <TableCell style={{ color: theme.palette.primary.mainReverseByWhite }}>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {saudacoes.map((saudacao) => (
              <TableRow key={saudacao.id}>
                <TableCell>{saudacao.id}</TableCell>
                <TableCell>{saudacao.mensagem}</TableCell>
                <TableCell>{saudacao.groupId}</TableCell>
                <TableCell>{saudacao.user ? saudacao.user.name : 'Desconhecido'}</TableCell>
                <TableCell>{formatDate(saudacao.createdAt)}</TableCell>
                <TableCell>
                  <IconButton style={{ marginRight: ".5rem" }} onClick={() => handleEdit(saudacao)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(saudacao.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SaudacaoComponent;
