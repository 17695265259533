
const handleDragEnd = async (result, lanes, setLanes, handleCardMove) => {
    const { source, destination, type } = result;

    if (!destination) return;

    if (type === "column") {
        const newLanes = Array.from(lanes);
        const [removed] = newLanes.splice(source.index, 1);
        newLanes.splice(destination.index, 0, removed);
        setLanes(newLanes);
        localStorage.setItem('laneOrder', JSON.stringify(newLanes.map(lane => lane.id)));
    } else if (type === "card") {
        const sourceLane = lanes.find(lane => lane.id === source.droppableId);
        const destLane = lanes.find(lane => lane.id === destination.droppableId);

        const sourceCards = Array.from(sourceLane.cards);
        const [movedCard] = sourceCards.splice(source.index, 1);
        const destCards = Array.from(destLane.cards);

        if (sourceLane.id === destLane.id) {
            sourceCards.splice(destination.index, 0, movedCard);
            const newLanes = lanes.map(lane =>
                lane.id === sourceLane.id ? { ...lane, cards: sourceCards } : lane
            );
            setLanes(newLanes);
        } else {
            destCards.splice(destination.index, 0, movedCard);
            const newLanes = lanes.map(lane => {
                if (lane.id === sourceLane.id) return { ...lane, cards: sourceCards };
                if (lane.id === destLane.id) return { ...lane, cards: destCards };
                return lane;
            });
            setLanes(newLanes);
            await handleCardMove(movedCard.id, destLane.id, destLane.title, movedCard.contactId);
        }
    }
};

export default handleDragEnd;