import React from "react";
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    IconButton
} from "@material-ui/core";

import { Edit as EditIcon } from "@material-ui/icons";

import useStyles from "../style";

export function HelpsManagerGrid (props) {
    const { records, onSelect, setOpenDialog } = props
    const classes = useStyles()

    return (
        <Paper className={classes.tableContainer}>
            <Table className={classes.fullWidth} size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell  className={`${classes.headerTexts}`} align="center" style={{width: '1%'}}>#</TableCell>
                    <TableCell  className={`${classes.headerTexts}`} align="left">Título</TableCell>
                    <TableCell  className={`${classes.headerTexts}`} align="left">Descrição</TableCell>
                    <TableCell  className={`${classes.headerTexts}`} align="left">Vídeo</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {records.map((row) => (
                    <TableRow key={row.id}>
                        <TableCell align="center" style={{width: '1%'}}>
                            <IconButton onClick={() => {
                                setOpenDialog(true);
                                onSelect(row);
                            }} aria-label="delete">
                                <EditIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell align="left">{row.title || '-'}</TableCell>
                        <TableCell align="left">{row.description || '-'}</TableCell>
                        <TableCell align="left">{row.video || '-'}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </Paper>
    )
}