import { Chip, Paper, TextField, IconButton, useTheme, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { isArray, isString } from "lodash";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import './TagsContainer.css'; // Importar arquivo CSS
import AddIcon from '@material-ui/icons/Add'; // Importar ícone de adição

const useStyles = makeStyles((theme) => ({
    tagBox: {
        display: 'flex',
        flexWrap: 'nowrap',
        overflowX: 'auto',
        marginTop: 8,
        ...theme.scrollbarStyles
    },
  
  }));

export function TagsContainer({ ticket, titleBlue = true }) {
    const classes = useStyles();
    const [selectedKanbanTags, setSelectedKanbanTags] = useState([]);
    const [selectedNormalTags, setSelectedNormalTags] = useState([]);
    const [isKanbanInputVisible, setKanbanInputVisible] = useState(false);
    const [isNormalInputVisible, setNormalInputVisible] = useState(false);
    const isMounted = useRef(true);
    const theme = useTheme();
    const [kanbanTags, setKanbanTags] = useState([]);
    const [normalTags, setNormalTags] = useState([]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            loadTags().then(() => {
                if (Array.isArray(ticket.tags)) {
                    const ticketKanbanTags = ticket.tags.filter(tag => tag.kanban === 1);
                    const ticketNormalTags = ticket.tags.filter(tag => tag.kanban !== 1);
                    setSelectedKanbanTags(ticketKanbanTags);
                    setSelectedNormalTags(ticketNormalTags);
                } else {
                    setSelectedKanbanTags([]);
                    setSelectedNormalTags([]);
                }
            });
        }
    }, [ticket]);

    const createTag = async (data) => {
        try {
            const { data: responseData } = await api.post(`/tags`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    };

    const loadTags = async () => {
        try {
            const { data } = await api.get(`/tags/list`);
            const kanban = data.filter(tag => tag.kanban === 1); // Filtra as tags de Kanban
            const normal = data.filter(tag => tag.kanban !== 1); // Filtra as tags normais
            setKanbanTags(kanban); // Atualiza o estado das tags de Kanban
            setNormalTags(normal); // Atualiza o estado das tags normais
        } catch (err) {
            toastError(err);
        }
    };

    const syncTags = async (data) => {
        try {
            const { data: responseData } = await api.post(`/tags/sync`, data);
            return responseData;
        } catch (err) {
            toastError(err);
        }
    };

    const onChangeKanban = async (value, reason) => {
        let optionsChanged = [];
        if (reason === 'create-option') {
            if (isArray(value)) {
                for (let item of value) {
                    if (isString(item)) {
                        const newTag = await createTag({ name: item, kanban: 1 });
                        optionsChanged.push(newTag);
                    } else {
                        optionsChanged.push(item);
                    }
                }
            }
        } else {
            optionsChanged = value;
        }
        setSelectedKanbanTags(optionsChanged);
        await syncTags({ ticketId: ticket.id, tags: [ ...optionsChanged, ...selectedNormalTags] });
    };

    const onChangeNormal = async (value, reason) => {
        let optionsChanged = [];
        if (reason === 'create-option') {
            if (isArray(value)) {
                for (let item of value) {
                    if (isString(item)) {
                        const newTag = await createTag({ name: item, kanban: 0 });
                        optionsChanged.push(newTag);
                    } else {
                        optionsChanged.push(item);
                    }
                }
            }
        } else {
            optionsChanged = value;
        }
        setSelectedNormalTags(optionsChanged);
        await syncTags({ ticketId: ticket.id, tags: [ ...optionsChanged, ...selectedKanbanTags ]});
    };

    const handleDelete = async (tagToDelete, type) => {
        let updatedTags = [ ...selectedKanbanTags.filter((tag) => tag.name !== tagToDelete.name), ...selectedNormalTags.filter((tag) => tag.name !== tagToDelete.name)];
    
        if (type === "kanban") {
            setSelectedKanbanTags(selectedKanbanTags.filter((tag) => tag.name !== tagToDelete.name));
        } else {
            setSelectedNormalTags(selectedNormalTags.filter((tag) => tag.name !== tagToDelete.name));
        }
    
        await syncTags({ ticketId: ticket.id, tags: updatedTags });
    };
    return (
        <Paper style={{ padding: "1rem 0 0 0", boxShadow: "none" }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', boxShadow: "none" }}>
                <h3 style={{ color: titleBlue ? theme.palette.primary.mainReverseByWhite : "", fontFamily: titleBlue ? 'Poppins' : "unset", fontWeight: titleBlue ? "700" : "400" }}>Kanban</h3>
                <IconButton
                    onClick={() => setKanbanInputVisible(!isKanbanInputVisible)}
                    style={{
                        color: theme.palette.primary.mainReverseByWhite,
                        border: '2px solid ' + theme.palette.primary.mainReverseByWhite,
                        borderRadius: '4px',
                        width: '25px',
                        height: '25px',
                        padding: 0,
                    }}
                >
                    <AddIcon style={{ fontSize: 18 }} />
                </IconButton>
            </div>
            <div style={{
                display: isKanbanInputVisible ? 'block' : 'none',
                marginTop: isKanbanInputVisible ? 8 : 0,
            }}>
                <Autocomplete
                    multiple
                    size="small"
                    options={kanbanTags}
                    value={selectedKanbanTags}
                    freeSolo
                    onChange={(e, v, r) => onChangeKanban(v, r)}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                        <TextField {...params} variant="outlined" placeholder="Adicionar Tags Kanban" />
                    )}
                    renderTags={() => null}
                />
            </div>
            <div className={classes.tagBox}>
                {selectedKanbanTags.map((option, index) => (
                    <Chip
                        key={index}
                        variant="outlined"
                        style={{
                            border: `2px solid ${option.color || '#eee'}`,
                            backgroundColor: 'transparent',
                            color: option.color || '#eee',
                            marginRight: 4,
                            marginBottom: 4,
                            fontWeight: 600,
                            borderRadius: 3,
                            fontSize: "0.8em",
                            whiteSpace: "nowrap"
                        }}
                        label={option.name.toUpperCase()}
                        onDelete={() => handleDelete(option, "kanban")}
                    />
                ))}
            </div>

            {/* Normal Tags */}
            <div style={{ marginTop: '1.5rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <h3 style={{ color: titleBlue ? theme.palette.primary.mainReverseByWhite : "", fontFamily: titleBlue ? 'Poppins' : "unset", fontWeight: titleBlue ? "700" : "400" }}>Tags</h3>
                    <IconButton
                        onClick={() => setNormalInputVisible(!isNormalInputVisible)}
                        style={{
                            color: theme.palette.primary.mainReverseByWhite,
                            border: '2px solid ' + theme.palette.primary.mainReverseByWhite,
                            borderRadius: '4px',
                            width: '25px',
                            height: '25px',
                            padding: 0,
                        }}
                    >
                        <AddIcon style={{ fontSize: 18 }} />
                    </IconButton>
                </div>
                <div style={{
                    display: isNormalInputVisible ? 'block' : 'none',
                    marginTop: isNormalInputVisible ? 8 : 0,
                }}>
                    <Autocomplete
                        multiple
                        size="small"
                        options={normalTags}
                        value={selectedNormalTags}
                        freeSolo
                        onChange={(e, v, r) => onChangeNormal(v, r)}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField {...params} variant="outlined" placeholder="Adicionar Tags" />
                        )}
                        renderTags={() => null}
                    />
                </div>
                <div className={classes.tagBox}>
                    {selectedNormalTags.map((option, index) => (
                        <Chip
                            key={index}
                            variant="outlined"
                            style={{
                                border: `2px solid ${option.color || '#eee'}`,
                                backgroundColor: 'transparent',
                                color: option.color || '#eee',
                                marginRight: 4,
                                marginBottom: 4,
                                fontWeight: 600,
                                borderRadius: 3,
                                fontSize: "0.8em",
                                whiteSpace: "nowrap"
                            }}
                            label={option.name.toUpperCase()}
                            onDelete={() => handleDelete(option, "normal")}
                        />
                    ))}
                </div>
            </div>
        </Paper>

    );
}
