import React, { useState, useEffect } from "react";
import {
    Paper,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    useTheme
} from "@material-ui/core";
import ConfirmationModal from "../ConfirmationModal";
import { toast } from "react-toastify";
import usePlans from "../../hooks/usePlans";
import useStyles from "./style";
import PlanManagerForm from "./PlansManagerComponents/components/PlanManagerForm";
import { PlansManagerGrid } from "./PlansManagerComponents/components/PlansManagerGrid";

export default function PlansManager() {
    const classes = useStyles()
    const { list, save, update, remove } = usePlans()
    const initialRecord = {
        name: '',
        users: 0,
        connections: 0,
        messages: 0,
        queues: 0,
        value: 0,
        useCampaigns: true,
        useSchedules: true,
        useInternalChat: true,
        useExternalApi: true,
        useKanban: true,
        useOpenAi: true,
        useIntegrations: true,
        isPublic: true
    };
    const [openDialog, setOpenDialog] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const [loading, setLoading] = useState(false)
    const [records, setRecords] = useState([])
    const [record, setRecord] = useState(initialRecord);

    useEffect(() => {
        async function fetchData() {
            await loadPlans()
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record])

    const loadPlans = async () => {
        setLoading(true)
        try {
            const planList = await list()
            setRecords(planList)
        } catch (e) {
            toast.error('Não foi possível carregar a lista de registros')
        }
        setLoading(false)
    }

    const handleSubmit = async (data) => {
        setLoading(true)
        try {
            if (data.id !== undefined) {
                await update(data)
            } else {
                await save(data)
            }
            await loadPlans()
            handleCancel()
            toast.success('Operação realizada com sucesso!')
        } catch (e) {
            toast.error('Não foi possível realizar a operação. Verifique se já existe uma plano com o mesmo nome ou se os campos foram preenchidos corretamente')
        }
        setLoading(false)
    }

    const handleDelete = async (record) => {
        setLoading(true)
        try {
            await remove(record.id);
            await loadPlans();
            handleCancel();
            toast.success('Operação realizada com sucesso!');
        } catch (e) {
            toast.error('Não foi possível realizar a operação');
        }
        setLoading(false);
        setOpenDialog(false);
    }

    const handleCancel = () => {
        setRecord(initialRecord)
    }

    const handleSelect = (data) => {

        let useCampaigns = data.useCampaigns === false ? false : true
        let useSchedules = data.useSchedules === false ? false : true
        let useInternalChat = data.useInternalChat === false ? false : true
        let useExternalApi = data.useExternalApi === false ? false : true
        let useKanban = data.useKanban === false ? false : true
        let useOpenAi = data.useOpenAi === false ? false : true
        let useIntegrations = data.useIntegrations === false ? false : true

        setRecord({
            id: data.id,
            name: data.name || '',
            users: data.users || 0,
            connections: data.connections || 0,
            messages: data.messages || 0,
            queues: data.queues || 0,
            value: data.value?.toLocaleString('pt-br', { minimumFractionDigits: 0 }) || 0,
            useCampaigns,
            useSchedules,
            useInternalChat,
            useExternalApi,
            useKanban,
            useOpenAi,
            useIntegrations,
            isPublic: data.isPublic
        })
    }

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const handleDialogOpen = () => {
        setOpenDialog(true);
        setRecord(initialRecord);
    };

    const theme = useTheme();

    return (
        <Paper className={classes.mainPaper} elevation={0}>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginBottom: "1rem" }}>
                <h5 style={{ fontSize: "14px", fontFamily: "Poppins", color: theme.palette.primary.mainReverseByWhite, fontWeight: "500", marginBottom: "1rem" }}>Planos</h5>
                <Button
                    className={classes.btnStyles}
                    onClick={handleDialogOpen}
                >
                    +
                </Button>
            </div>
            <Dialog PaperProps={{
                style: {
                    border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
                    borderRadius: "8px",
                },
            }} open={openDialog} onClose={handleDialogClose}>
                <DialogTitle className={classes.modalTitle}>Adicionar Novo Plano</DialogTitle>
                <DialogContent>
                    <PlanManagerForm
                        initialValue={record}
                        onSubmit={handleSubmit}
                        onDelete={() => setShowConfirmDialog(true)}
                        onCancel={handleDialogClose}
                        loading={loading}
                    />
                </DialogContent>
            </Dialog>
            <Grid spacing={2} container>
                <Grid xs={12} item>
                    <PlansManagerGrid
                        records={records}
                        setOpenDialog={setOpenDialog}
                        onSelect={handleSelect}
                    />
                </Grid>
            </Grid>
            <ConfirmationModal
                title="Exclusão de Registro"
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                onConfirm={() => handleDelete(record)}
            >
                Deseja realmente excluir esse registro?
            </ConfirmationModal>
        </Paper>
    )
}