import { useState } from 'react';

const useTagState = () => {
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedTag, setSelectedTag] = useState(null);
  const [deletingTag, setDeletingTag] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchParam, setSearchParam] = useState("");
  const [tags, setTags] = useState([]);
  const [tagModalOpen, setTagModalOpen] = useState(false);

  return {
    loading,
    setLoading,
    pageNumber,
    setPageNumber,
    hasMore,
    setHasMore,
    selectedTag,
    setSelectedTag,
    deletingTag,
    setDeletingTag,
    confirmModalOpen,
    setConfirmModalOpen,
    searchParam,
    setSearchParam,
    tags,
    setTags,
    tagModalOpen,
    setTagModalOpen,
  };
};

export default useTagState;
