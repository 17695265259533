import React from "react";

import {
    Grid,
} from "@material-ui/core";

import ChatNavigation from "./ChatNavigation";
import MessagesContainer from "./MessagesContainer";

const FullChat = ({
    classes,
    categories,
    setShowCategorieDialog,
    setDialogType,
    setPickedCategorie, 
    setShowDialog,
    setDeleteModalOpen,
    loading,
    showMobileChat,
    setMobileChat,
    setLoading,
    selectChat,
    deleteChat,
    currentChat,
    chatsPageInfo,
    scrollToBottomRef,
    messagesPageInfo,
    messages,
    sendMessage,
    loadMoreMessages
}) => {
    return (
        <Grid className={classes.gridContainer} container>
            <ChatNavigation
                classes={classes}
                categories={categories}
                setShowCategorieDialog={setShowCategorieDialog}
                setDialogType={setDialogType}
                setPickedCategorie={setPickedCategorie}
                setShowDialog={setShowDialog}
                setDeleteModalOpen={setDeleteModalOpen}
                loading={loading}
                setMobileChat={setMobileChat}
                setLoading={setLoading}
                selectChat={selectChat}
                deleteChat={deleteChat}
                chatsPageInfo={chatsPageInfo}
            />
            <MessagesContainer
                classes={classes}
                loading={loading}
                showMobileChat={showMobileChat}
                setMobileChat={setMobileChat}
                currentChat={currentChat}
                scrollToBottomRef={scrollToBottomRef}
                messagesPageInfo={messagesPageInfo}
                messages={messages}
                sendMessage={sendMessage}
                loadMoreMessages={loadMoreMessages}
            />
        </Grid>
    );
};

export default FullChat;
