import React from "react";
import { Link as RouterLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import { i18n } from "../../../translate/i18n";
import { Grid } from "@material-ui/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import InfoSlider from "../../InfoSlider";

const LoginForm = ({classes, handleSubmit, user, handleChangeInput, setUser}) => {
  return (
      <div className="cmp-login__max-container-align">
        <section className="cmp-login__max-container">
          <div className={"container-right"}>
            <InfoSlider />
            <div className={"cmp-login__box"}>
              <div className="cmp-login__form-label-container">
                <h3 className={`cmp-login__form-title ${classes.text}`}>Faça login na sua conta</h3>
                <p className={`cmp-login__form-description ${classes.darkerTexts}`}>
                  Digite o seu E-mail e senha para fazer login
                </p>
              </div>
              <form className={classes.form} noValidate onSubmit={handleSubmit}>
                <label className={`cmp-login__label ${classes.darkerTexts}`} htmlFor="email">
                  E-mail
                </label>
                <div className="cmp-login__email-input-container">
                  <div className="cmp-login__email-icon">
                    <span className={`${classes.darkerTexts}`}>@</span>
                  </div>

                  <TextField
                    className={classes.input}
                    margin="dense"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    value={user.email}
                    onChange={handleChangeInput}
                    autoComplete="email"
                    inputProps={{
                      style: {
                        borderRadius: "0px",
                        height: "30px",
                        padding: ".375rem .75rem",
                        backgroundColor: "white",
                        color: "black",
                        border: "1px solid #dee2e6",
                        transition: "border 0.3s ease, box-shadow 0.3s ease",
                      },
                    }}
                    onFocus={(e) => {
                      e.target.classList.add(classes.inputFocus);
                    }}
                    onBlur={(e) => {
                      e.target.classList.remove(classes.inputFocus);
                      e.target.style.border = "1px solid #dee2e6";
                    }}
                  />
                </div>
                <label className={`${classes.darkerTexts} cmp-login__label`} htmlFor="password">
                  Senha
                </label>
                <div className="cmp-login__password-input-container">
                  <TextField
                    className={classes.input}
                    margin="dense"
                    required
                    fullWidth
                    name="password"
                    type="password"
                    id="password"
                    value={user.password}
                    onChange={handleChangeInput}
                    autoComplete="current-password"
                    inputProps={{
                      style: {
                        borderRadius: "0px",
                        height: "30px",
                        padding: ".375rem .75rem",
                        color: "black",
                        backgroundColor: "white",
                        border: "1px solid #dee2e6",
                        transition: "border 0.3s ease, box-shadow 0.3s ease",
                      },
                    }}
                    onFocus={(e) => {
                      e.target.classList.add(classes.inputFocus);
                    }}
                    onBlur={(e) => {
                      e.target.classList.remove(classes.inputFocus);
                      e.target.style.border = "1px solid #dee2e6";
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <label style={{ display: "flex", gap: "0.5rem", marginBottom: "1rem" }}>
                    <input
                      type="checkbox"
                      name="rememberMe"
                      className={classes.linkColor}
                      checked={user.rememberMe}
                      onChange={(e) => setUser({ ...user, rememberMe: e.target.checked })}
                    />
                    Lembrar-me
                  </label>
                </div>

                <Grid container>
                  <Grid item>
                    <Link
                      href="#"
                      component={RouterLink}
                      to="/recovery-password"
                      className={classes.linkColor}
                      style={{
                        margin: '1rem 0 0 0 ',
                        fontWeight: "500"
                      }}
                    >
                      {i18n.t("Esqueci a Senha")}
                    </Link>
                  </Grid>
                </Grid>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ margin: "1rem 0" }}
                >
                  Login
                </Button>
                <div className={"container-footer-form"}>
                  <p className={classes.darkerTexts}>
                    Não tem conta?
                    <Link
                      component={RouterLink}
                      tabIndex={0}
                      role={"button"}
                      aria-disabled={"false"}
                      className={classes.linkColor}
                      to="/signup"
                      style={{
                        fontWeight: "500"
                      }}
                    >
                      <span>Criar conta</span>
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
  );
};

export default LoginForm;

