const themeDefault = {
    REACT_APP_LIGHT_MAIN_COLOR: process.env.REACT_APP_LIGHT_MAIN_COLOR,
    REACT_APP_DARK_MAIN_COLOR: process.env.REACT_APP_DARK_MAIN_COLOR,
    REACT_APP_LIGHT_SECOND_COLOR: process.env.REACT_APP_LIGHT_SECOND_COLOR,
    REACT_APP_DARK_SECOND_COLOR: process.env.REACT_APP_DARK_SECOND_COLOR,
    REACT_APP_LIGHT_THIRD_COLOR: process.env.REACT_APP_LIGHT_THIRD_COLOR,
    REACT_APP_DARK_THIRD_COLOR: process.env.REACT_APP_DARK_THIRD_COLOR,
    REACT_APP_LIGHT_TEXT_COLOR: process.env.REACT_APP_LIGHT_TEXT_COLOR,
    REACT_APP_DARK_TEXT_COLOR: process.env.REACT_APP_DARK_TEXT_COLOR,
    REACT_APP_LIGHT_LINKS_COLOR: process.env.REACT_APP_LIGHT_LINKS_COLOR,
    REACT_APP_DARK_LINKS_COLOR: process.env.REACT_APP_DARK_LINKS_COLOR,
    REACT_APP_LIGHT_MAIN_GRADIENT: process.env.REACT_APP_LIGHT_MAIN_GRADIENT,
    REACT_APP_DARK_MAIN_GRADIENT: process.env.REACT_APP_DARK_MAIN_GRADIENT,
    REACT_APP_LIGHT_SECOND_GRADIENT: process.env.REACT_APP_LIGHT_SECOND_GRADIENT,
    REACT_APP_DARK_SECOND_GRADIENT: process.env.REACT_APP_DARK_SECOND_GRADIENT,
    REACT_APP_LIGHT_REVERSE_WHITE_BY_MAIN: process.env.REACT_APP_LIGHT_REVERSE_WHITE_BY_MAIN,
    REACT_APP_DARK_REVERSE_WHITE_BY_MAIN: process.env.REACT_APP_DARK_REVERSE_WHITE_BY_MAIN,
    REACT_APP_LIGHT_MAIN_REVERSE_BY_WHITE: process.env.REACT_APP_LIGHT_MAIN_REVERSE_BY_WHITE,
    REACT_APP_DARK_MAIN_REVERSE_BY_WHITE: process.env.REACT_APP_DARK_MAIN_REVERSE_BY_WHITE,
}

export default themeDefault;