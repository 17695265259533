import React, { useState, useEffect } from "react";
import api from "../../services/api";
import {
  Button,
  TextField,
  Grid,
  Paper,
  Typography,
  IconButton,
  Divider,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";

interface RatingOptions {
  id: number;
  companyId: number;
  option1: string;
  option2: string;
  option3: string;
}

const useStyles = makeStyles((theme: any) => ({
  paper: 
    {
      padding: "1.5rem",
      display: "flex",
      border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
      "& h6, & p, & label, & div": {
        fontSize: "14px",
        fontFamily: "Poppins"
      }
    },
  fixedHeightPaper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
  },
  tab: {
    backgroundColor: theme.palette.options,
    borderRadius: 4,
    width: "100%",
    "& .MuiTab-wrapper": {
      color: theme.palette.fontecor,
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center"
    }
  },
  alignRight: {
    textAlign: "right",
  },
  fullWidth: {
    width: "100%",
  },
  selectContainer: {
    width: "100%",
    textAlign: "left",
  },
  boxContainer: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    padding: "1rem",
    borderRadius: "8px",
    justifyContent: "space-between",
    display: "flex",
    flexWrap: "wrap",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
  boxContainerNoFlex: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    padding: "1rem",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },
  
}));

const RatingOptionsComponent = () => {
  const classes: any = useStyles();
  const [ratingOptions, setRatingOptions] = useState<RatingOptions | null>(null);
  const [editing, setEditing] = useState(false);
  const [newOptions, setNewOptions] = useState({
    option1: "",
    option2: "",
    option3: "",
  });
  const [error, setError] = useState<string | null>(null);

  // Função para buscar as opções de avaliação
  const fetchRatingOptions = async () => {
    try {
      const companyId = localStorage.getItem("companyId"); // Obtenha o companyId do localStorage

      if (!companyId) {
        setError("Company ID não encontrado.");
        return;
      }

      const response = await api.get(`/api/companies/${companyId}/rating-options`);

      setRatingOptions(response.data.data);
      setNewOptions({
        option1: response.data.data.option1,
        option2: response.data.data.option2,
        option3: response.data.data.option3,
      });
    } catch (err) {
    }
  };

  useEffect(() => {
    fetchRatingOptions();
  }, []);

  // Função para salvar as novas opções de avaliação
  const handleSaveOptions = async () => {
    if (!newOptions.option1 || !newOptions.option2 || !newOptions.option3) {
      setError("Todas as opções devem ser preenchidas.");
      return;
    }

    try {
      const companyId = localStorage.getItem("companyId"); // Obtenha o companyId do localStorage

      if (!companyId) {
        setError("Company ID não encontrado.");
        return;
      }

      await api.put(`/api/companies/${companyId}/rating-options`, {
        ...newOptions,
        companyId,
      });

      setEditing(false);
      fetchRatingOptions();
    } catch (err) {
      setError("Erro ao salvar as opções.");
      console.error("Erro ao salvar as opções:", err);
    }
  };
  const theme: any = useTheme();
  return (
    <div className={classes.mainPaper}>
      <h6 style={{ marginBottom: "1rem", fontSize: "14px", fontWeight: "500", fontFamily: "Poppins", color: theme.palette.primary.mainReverseByWhite }}>
        Opções de Avaliação
      </h6>

      {error && <Typography color="error">{error}</Typography>}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={3}>
            {!editing ? (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Typography variant="h6">Opção 1:</Typography>
                    <Typography variant="body1">{ratingOptions?.option1}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6">Opção 2:</Typography>
                    <Typography variant="body1">{ratingOptions?.option2}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="h6">Opção 3:</Typography>
                    <Typography variant="body1">{ratingOptions?.option3}</Typography>
                  </Grid>
                </Grid>
                <Divider style={{ margin: "1rem 0" }} />

                <IconButton onClick={() => setEditing(true)} color="primary">
                  <EditIcon />
                </IconButton>
              </>
            ) : (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <TextField
                      label="Opção 1"
                      value={newOptions.option1}
                      onChange={(e) =>
                        setNewOptions({ ...newOptions, option1: e.target.value })
                      }
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Opção 2"
                      value={newOptions.option2}
                      onChange={(e) =>
                        setNewOptions({ ...newOptions, option2: e.target.value })
                      }
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label="Opção 3"
                      value={newOptions.option3}
                      onChange={(e) =>
                        setNewOptions({ ...newOptions, option3: e.target.value })
                      }
                      fullWidth
                      margin="normal"
                    />
                  </Grid>
                </Grid>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveOptions}
                  startIcon={<SaveIcon />}
                  style={{ marginTop: "1rem" }}
                >
                  Salvar
                </Button>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default RatingOptionsComponent;
