import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import truncateText from '../../utils/truncateText';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    inline: {
        width: '100%'
    },
    icon: {
        color: theme.palette.primary.mainReveserseByWhite,
    }
}));

export default function ContactNotesDialogListItem (props) {
    const { note, deleteItem } = props;
    const classes = useStyles();

    const handleDelete = (item) => {
        deleteItem(item);
    }

    return (
        <ListItem alignItems="flex-start" style={{paddingLeft: "0"}}>
            <ListItemText
                primary={
                    <>
                        <Tooltip title={note.note}>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                            >
                                {truncateText(note.note, 10)}
                            </Typography>
                        </Tooltip>
                    </>
                }
                secondary={
                    <>
                        {note.user.name}, {moment(note.createdAt).format('DD/MM/YY HH:mm')}
                    </>
                }
            />
            <ListItemSecondaryAction>
                <IconButton onClick={() => handleDelete(note)} edge="end" aria-label="delete">
                    <DeleteIcon className={classes.icon} />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )   
}

ContactNotesDialogListItem.propTypes = {
    note: PropTypes.object.isRequired,
    deleteItem: PropTypes.func.isRequired
}