import React, { useState, useEffect } from "react";
import { Button, TextField, InputAdornment, Menu, MenuItem, Tooltip, Divider } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import DeleteContacts from "./DeleteContacts";
import { i18n } from "../../../../translate/i18n";
import MainHeader from "../../../../components/MainHeader";
import Title from "../../../../components/Title";
import MainHeaderButtonsWrapper from "../../../../components/MainHeaderButtonsWrapper";
import { Backup, ContactPhone } from "@material-ui/icons";
import { CSVLink } from "react-csv";

const ContactsHeader = ({
  classes,
  searchParam,
  handleSearch,
  handleClick,
  anchorEl,
  open,
  handleClose,
  setConfirmOpen,
  handleOpenImportModal,
  contacts,
  handleDownloadModelToExport,
  handleOpenContactModal,
  handleReload
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  // Atualiza a variável ao redimensionar a janela
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <MainHeader style={ isMobile ? { display: "flex", flexDirection: "column",
		alignItems: "center"}:{}}>
      <Title>{i18n.t("contacts.title")}</Title>
      <MainHeaderButtonsWrapper style={isMobile ? {margin: '0', display: "flex", alignItems: "center"} : {}}>
        {!isMobile && (
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
        )}



        <Tooltip title={"Upload Contatos"}>
          <Button
            endIcon={<ArrowDropDownIcon />}
            variant="contained"
            color="primary"
            className={classes.buttonStyles}
            onClick={handleClick}
          >
            <CloudUploadIcon />
          </Button>
        </Tooltip>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => { setConfirmOpen(true) }}>
            <ContactPhone fontSize="small" color="primary" style={{ marginRight: 10 }} />
            Importar Agenda
          </MenuItem>
          <MenuItem onClick={handleOpenImportModal}>
            <Backup fontSize="small" color="primary" style={{ marginRight: 10 }} />
            Upload Excel
          </MenuItem>
          <Divider variant="middle" />

          <CSVLink 
            style={{ textDecoration: 'none', color: 'inherit' }} 
            separator=";" 
            filename={'contatos.csv'} 
            data={contacts.map((contact) => ({ name: contact.name, number: contact.number, email: contact.email }))}>
            <MenuItem onClick={handleClose}>
              <CloudDownloadIcon fontSize="small" color="primary" style={{ marginRight: 10 }} />
              Baixar CSV
            </MenuItem>
          </CSVLink>
          <MenuItem onClick={handleDownloadModelToExport}>
            <CloudDownloadIcon fontSize="small" color="primary" style={{ marginRight: 10 }} />
            Baixar Modelo Importação
          </MenuItem>
        </Menu>

        <Tooltip title={i18n.t("contacts.buttons.add")}>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonStyles}
            onClick={handleOpenContactModal}
          >
            <PersonAddIcon />
          </Button>
        </Tooltip>

        <DeleteContacts classes={classes} onReload={handleReload} />
        {isMobile && (
          <div style={{ width: '100%', marginTop: 20 }}> {/* Altere o estilo para posicionar conforme necessário */}
            <TextField
              placeholder={i18n.t("contacts.searchPlaceholder")}
              type="search"
              value={searchParam}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "gray" }} />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </div>
        )}
      </MainHeaderButtonsWrapper>

    </MainHeader>
  );
};

export default ContactsHeader;
