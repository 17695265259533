import React, { useContext, useState, useRef, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { Replay, Phone, CallEnd, TransitEnterexit } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import TicketOptionsMenu from "../TicketOptionsMenu";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Modal, Button, Select, MenuItem, useMediaQuery } from '@material-ui/core';
import UndoRoundedIcon from '@material-ui/icons/UndoRounded';
import Tooltip from '@material-ui/core/Tooltip';
import PersonIcon from '@material-ui/icons/Person';
import CloseIcon from '@material-ui/icons/Close';
import callingSound from "../../assets/sounds/calling.mp3";
import VideocamIcon from '@material-ui/icons/Videocam'; // Importe o ícone de vídeo
import "./style.css";
import { toast } from "react-toastify";
import FileDownloadIcon from "@material-ui/icons/GetApp";
import { useHistory, useParams } from "react-router-dom";
import CheckIcon from '@material-ui/icons/Check';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { WavoipContext } from "../../context/WavoipContext/WhavoipContext";

const useStyles = makeStyles(theme => ({
	actionButtons: {
		marginRight: 6,
		flex: "none",
		alignSelf: "center",
		display: "flex",
		marginLeft: "auto",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
	userButton: {
		color: theme.palette.primary.mainReverseByWhite,
		background: 'none',
		borderRadius: '4px',
		width: '24px',
		height: '24px',
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
	},
	iconButton: {
		color: theme.palette.primary.mainReverseByWhite,
		borderRadius: '4px',
		width: '24px',
		height: '24px',
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
	},
}));

const TicketActionButtonsCustom = ({ ticket, handleDrawerOpen, handleDrawerClose, drawerOpen, contact, setMobileUserOpen, setMobileAdditionalInfoOpen }) => { // Adicionando toggleContactInfo como prop
	const [loading, setLoading] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const ticketOptionsMenuOpen = Boolean(anchorEl);
	const classes = useStyles();
	const { user } = useContext(AuthContext);
	const history = useHistory();
	const isCallsEnabled = localStorage.getItem("callEnabled") === "true";;
	const [tag, setTag] = useState([]);
	const [ticketToClose, setTicketToClose] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [channelType, setChannelType] = useState(null);
	const isMounted = useRef(true);
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedSubcategory, setSelectedSubcategory] = useState("");
	const isMobile = useMediaQuery("(max-width: 900px)");

	const waData = useContext(WavoipContext);
	const { isCalling, callDuration, handleWavoipCall, setIsActive, setIsOpen, setPhoneNumber, setActiveTicket, exportMsgType } = waData;

	useEffect(() => {
		const fetchCategories = async () => {
			try {
				const companyId = localStorage.getItem("companyId");
				if (!companyId) {
					throw new Error("CompanyId não encontrado.");
				}

				const { data } = await api.get('/api/categories/list', {
					params: { companyId }
				});

				setCategories(data);
			} catch (error) {
				console.error("Erro ao carregar as categorias:", error.response || error.message);
				toastError("Erro ao carregar as categorias.");
			}
		};
		fetchCategories();
	}, []);

	useEffect(() => {

		if (!ticket.id) return;

		const fetchChannelType = async () => {
			try {
				const { data } = await api.get(`/tickets/${ticket.id}`);

				setChannelType(data.whatsapp?.type);
			} catch (err) {

				toastError(err);
			}
		};

		fetchChannelType();

	}, [ticket.id]);

	const handleCloseTicket = async () => {

		if (!selectedCategory || !selectedSubcategory) {
			toastError('Por favor, selecione uma categoria e uma subcategoria antes de fechar o ticket.');
			return;
		}

		setLoading(true);
		try {
			await api.put(`/tickets/${ticketToClose}`, {
				status: "closed",
				userId: user?.id,
				queueId: ticket?.queue?.id,
				categoryId: selectedSubcategory,
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		setOpenModal(false);
		history.push(`/tickets/`);
	};

	const handleCloseTicketOptionsMenu = () => {
		setAnchorEl(null);
	};
	const handleSendMeet = async () => {
		try {

			const response = await api.post("/jitsi/create-meeting", {
				roomName: "sala-de-reuniao",
				userName: "Nome do Usuário"
			});

			const meetingLink = response.data.meetingLink;

			try {
				if (channelType === "official") {
					// Se o canal for oficial, usar a rota de mensagem oficial
					await api.post(`/official-message/${ticket.id}`, {
						body: `Seu link de reunião: ${meetingLink}`
					});
				} else if (channelType !== null) {
					// Se for um canal de hub
					await api.post(`/hub-message/${ticket.id}`, {
						body: `Seu link de reunião: ${meetingLink}`
					});
				} else {
					// Se for um canal padrão
					await api.post(`/messages/${ticket.id}`, {
						body: `Seu link de reunião: ${meetingLink}`
					});
				}


				toast.success("Link da reunião enviado com sucesso!");
			} catch (error) {
				toast.error("Erro ao enviar o link da reunião");
			}
		} catch (error) {
			// Tratamento de erro para a criação da reunião
			toast.error("Erro ao criar a reunião Jitsi");
		}
	};

	const handleExportMessage = async () => {
		// Verificação: se exportMsgType estiver habilitado, apenas administradores podem exportar
		if (exportMsgType === "enabled" && user.profile !== "admin") {
			toast.error("Apenas administradores podem exportar mensagens.");
			return;
		}

		try {
			const response = await api.get(`/tickets/${ticket.id}/messages/export`, {
				responseType: 'blob',
			});

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", `Ticket_${ticket.id}_Messages.xlsx`);
			document.body.appendChild(link);
			link.click();

			toast.success("Exportação de mensagens iniciada com sucesso!");
		} catch (error) {
			toastError(error);
		}
	};

	const handleUpdateTicketStatus = async (e, status, userId) => {
		try {
			setLoading(true); // Start loading
			await api.put(`/tickets/${ticket.id}`, { status, userId });
			setLoading(false); // Stop loading
		} catch (error) {
			setLoading(false);
			toastError(error);
		}
	};

	const handleUserIconClick = () => {
		setMobileAdditionalInfoOpen(true)
		drawerOpen ? handleDrawerClose() : handleDrawerOpen();
	};

	const theme = useTheme();
	const handleOpenCloseModal = (ticketId) => {
		setTicketToClose(ticketId); // Define o ticket que será fechado
		setOpenModal(true); // Abre o modal
	};

	const handleCloseTicketSemModal = async (id) => {
		setTag(ticket?.tags);
		setLoading(true);
		try {
			await api.put(`/tickets/${id}`, {
				status: "closed",
				userId: user?.id,
				queueId: ticket?.queue?.id,
				useIntegration: false,
				promptId: null,
				integrationId: null
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/`);
	};

	const ticketButtonsToTop = localStorage.getItem("ticketButtonsToTop") === "true";
	const hideMettingIconActive = localStorage.getItem("hideMettingIconActive") === "true";

	return (
		<div className={classes.actionButtons}>
			{(ticket.status === "open" && ticketButtonsToTop) && (
				<>
					<Tooltip arrow title="Resolver ticket">
						<IconButton
							onClick={(e) => {
								e.stopPropagation();
								return ticket.isGroup ? handleCloseTicketSemModal(ticket.id) : handleOpenCloseModal(ticket.id)
							}
							}
							style={{ color: theme.palette.primary.mainReverseByWhite, borderRadius: "4px", width: "24px", height: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}
							color="primary"
							className={`${classes.userButton}`}
						>
							<CheckIcon style={{ fontSize: "18px" }} />
						</IconButton>
					</Tooltip>
				</>
			)}

			{(ticket.status !== "closed" && ticketButtonsToTop) && (
				<Tooltip arrow title="Finalizar ticket">
					<IconButton
						onClick={e => {
							e.stopPropagation();
							handleCloseTicketSemModal(ticket.id);
						}}
						style={{ color: theme.palette.primary.mainReverseByWhite, borderRadius: "4px", width: "24px", height: "24px", display: "flex", justifyContent: "center", alignItems: "center" }}
						color="primary"
						className={`${classes.userButton}`}
					>
						<HighlightOffIcon style={{ fontSize: "18px" }} />
					</IconButton>
				</Tooltip>
			)}

			{ticket.status === "closed" && (
				<ButtonWithSpinner
					loading={loading}
					startIcon={<Replay />}
					size="small"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.reopen")}
				</ButtonWithSpinner>
			)}
			{ticket.status === "open" && (
				<>
					<Tooltip title="Fechar">
						<IconButton
							onClick={() => setMobileUserOpen(false)}
							className={`${classes.userButton} cmp-mobile-button`}
						>
							<CloseIcon />
						</IconButton>
					</Tooltip>

					{(exportMsgType === "disabled" && (user.profile === "admin" || user.super)) && (
						<Tooltip title="Exportar Mensagens">
							<IconButton
								onClick={handleExportMessage}

								className={`${classes.userButton} cmp-desktop-button`}
							>
								<FileDownloadIcon />
							</IconButton>
						</Tooltip>
					)}

					<Tooltip title="Informações do Contato">
						<IconButton
							onClick={handleUserIconClick}
							style={{ background: drawerOpen ? theme.palette.primary.main : "transparent", color: drawerOpen ? "white" : theme.palette.primary.mainReverseByWhite }}
							className={`${classes.userButton} cmp-desktop-button`}
						>
							<PersonIcon />
						</IconButton>
					</Tooltip>

					{ticket.whatsapp?.type === null && ticket.contact.number && isCallsEnabled && !ticket?.isGroup && (
						<Tooltip title={isCalling ? "Finalizar chamada" : "Realizar chamada"}>
							<IconButton
								onClick={() => {
									setIsOpen(true);
									setIsActive(true);
									setPhoneNumber("");
									setActiveTicket(ticket);
									sessionStorage.setItem("ticketIdDefined", ticket?.id || "")
									handleWavoipCall("", user, ticket);
								}}
								className={`${classes.iconButton} cmp-call`}
								color={false ? "secondary" : "primary"}
								style={{
									fontSize: '14.4px',
									backgroundColor: false ? '#FF6F61' : 'initial', // Altera a cor para vermelho
									color: 'white', // Cor do texto, pode ser ajustado
									width: false ? "80px" : "25px"
								}}
							>
								{false ? <CallEnd style={{ color: theme.palette.primary.mainReverseByWhite }} /> : <Phone style={{ color: theme.palette.primary.mainReverseByWhite }} />}
								{false && <div>{`${callDuration}s`}</div>}
							</IconButton>
						</Tooltip>
					)}
					{!hideMettingIconActive && <Tooltip title="Enviar link da reunião">
						<IconButton
							onClick={handleSendMeet}
							className={classes.iconButton}
						>
							<VideocamIcon style={{ color: theme.palette.primary.mainReverseByWhite }} />
						</IconButton>
					</Tooltip>}

					<Tooltip title={i18n.t("messagesList.header.buttons.return")}>
						<IconButton
							onClick={e => handleUpdateTicketStatus(e, "pending", null)}
							className={classes.iconButton}
							style={{ fontSize: '14.4px' }}
						>
							<UndoRoundedIcon />
						</IconButton>
					</Tooltip>

					<TicketOptionsMenu
						ticket={ticket}
						anchorEl={anchorEl}
						menuOpen={ticketOptionsMenuOpen}
						handleClose={handleCloseTicketOptionsMenu}
					/>
				</>
			)}
			{ticket.status === "pending" && (
				<ButtonWithSpinner
					loading={loading}
					size="small"
					variant="contained"
					color="primary"
					onClick={e => handleUpdateTicketStatus(e, "open", user?.id)}
				>
					{i18n.t("messagesList.header.buttons.accept")}
				</ButtonWithSpinner>
			)}
			<Modal open={openModal} onClose={() => setOpenModal(false)}>
				<div style={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: isMobile ? '300px' : '500px',
					backgroundColor: '#f9f9f9',
					padding: '30px',
					borderRadius: '10px',
					boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
					outline: 'none',
				}}>
					<h2 style={{
						marginBottom: '20px',
						color: '#333',
						textAlign: 'center',
						fontSize: '1.5rem',
						fontWeight: '500',
					}}>
						Selecione a Categoria e Subcategoria
					</h2>

					{/* Seleção de Categoria Principal */}
					<div style={{ marginBottom: '20px' }}>
						<label style={{ fontSize: '1.1rem', color: '#555' }}>Categoria Principal</label>
						<Select
							value={selectedCategory}
							onChange={(e) => setSelectedCategory(e.target.value)}
							fullWidth
							variant="outlined"
							style={{
								marginTop: '10px',
								borderRadius: '8px',
								backgroundColor: '#fff',
							}}
						>
							{categories.filter(category => category.parent_id === null).map(category => (
								<MenuItem key={category.id} value={category.id}>
									{category.name}
								</MenuItem>
							))}
						</Select>
					</div>

					{/* Seleção de Subcategoria (Baseada na Categoria Principal Selecionada) */}
					<div style={{ marginBottom: '20px' }}>
						<label style={{ fontSize: '1.1rem', color: '#555' }}>Subcategoria</label>
						<Select
							value={selectedSubcategory}
							onChange={(e) => setSelectedSubcategory(e.target.value)}
							fullWidth
							variant="outlined"
							disabled={!selectedCategory} // Desabilitar se nenhuma categoria principal for selecionada
							style={{
								marginTop: '10px',
								borderRadius: '8px',
								backgroundColor: selectedCategory ? '#fff' : '#f0f0f0',
							}}
						>
							{categories
								.find(category => category.id === selectedCategory)?.subcategories
								?.map(subcategory => (
									<MenuItem key={subcategory.id} value={subcategory.id}>
										{subcategory.name}
									</MenuItem>
								))}
						</Select>
					</div>

					<div style={{ marginTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							variant="outlined"
							onClick={() => setOpenModal(false)}
							style={{
								marginRight: '10px',
								borderRadius: '8px',
								padding: '10px 20px',
								fontSize: '1rem',
								background: "#00000030"
							}}
						>
							Cancelar
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={handleCloseTicket}
							style={{
								borderRadius: '8px',
								padding: '10px 20px',
								fontSize: '1rem',
							}}
						>
							Fechar Ticket
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default TicketActionButtonsCustom;
