import React, { useState, useEffect } from 'react';
import { FaPen, FaUserAlt } from 'react-icons/fa'; // Biblioteca de ícones Font Awesome
import api from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastError from '../../errors/toastError';

const ProfileImageUploader = ({ userId }) => {
    const [preview, setPreview] = useState("");
    const [isUploading, setIsUploading] = useState(false);

    const MAX_FILE_SIZE = 5 * 1024 * 1024;

    // Fetch inicial para obter a imagem do perfil
    useEffect(() => {
        const fetchProfileImage = async () => {
            try {
                const response = await api.get(`/users/${userId}/profile-picture`);
                if (response.status >= 200 && response.status < 300) {
                    const data = response.data;
                    setPreview(data.profilePicture);
                }
            } catch (error) {
                toastError(error);
            }
        };

        fetchProfileImage();
    }, []);

    const handleImageChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        if (!file.type.startsWith('image/')) {
            toast.error('Por favor, selecione um arquivo de imagem válido.');
            return;
        }

        if (file.size > MAX_FILE_SIZE) {
            toast.error('O arquivo é muito grande. O tamanho máximo permitido é 5MB.');
            return;
        }

        const reader = new FileReader();
        reader.onloadend = () => setPreview(reader.result);
        reader.readAsDataURL(file);

        try {
            await uploadImage(file);
        } catch (error) {
            console.error('Erro ao fazer upload da imagem:', error);
        }
    };

    const uploadImage = async (file) => {
        setIsUploading(true);
        const formData = new FormData();
        formData.append('profilePicture', file);

        try {
            const response = await api.put(`/users/${userId}/profile-picture`, formData);

            if (response.status >= 200 && response.status < 300) {
                const data = response.data;
                setPreview(data.profilePicture);
            } else {
                toastError(response.statusText);
            }
        } catch (error) {
            toastError(error);
        } finally {
            setIsUploading(false);
        }
    };

    const isValidImage = (image) => {
        return image && !image.includes('undefined');
    };

    return (
        <div style={styles.container}>
            <div style={styles.imageWrapper}>
                {(isValidImage(preview) && !isUploading) ? (
                    <img src={`${process.env.REACT_APP_BACKEND_URL}${preview}`} alt="Profile Preview" style={styles.image} />
                ) : (
                    <div style={styles.placeholder}>
                        <FaUserAlt style={styles.icon} />
                    </div>
                )}
                <label htmlFor="imageUpload" style={styles.overlay}>
                    <FaPen style={styles.icon} />
                </label>
                <input
                    id="imageUpload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={styles.input}
                    disabled={isUploading}
                />
            </div>
            {isUploading && <p style={styles.uploading}>Carregando imagem...</p>}
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
        paddingBottom: "1rem"
    },
    imageWrapper: {
        position: 'relative',
        width: '150px',
        height: '150px',
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        objectFit: 'cover',
    },
    placeholder: {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        backgroundColor: '#ccc',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: '50%',
        opacity: 0,
        cursor: 'pointer',
        transition: 'opacity 0.3s',
    },
    icon: {
        color: '#fff',
        fontSize: '20px',
    },
    input: {
        display: 'none',
    },
    uploading: {
        color: '#ec6724',
        fontSize: '14px',
    },
};

export default ProfileImageUploader;
