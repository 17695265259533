function generateStrongFromString(email) {
    function hashEmail(email) {
        let hash = 0;
        for (let i = 0; i < email.length; i++) {
            hash = email.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    function ensureStrongColor(r, g, b) {
        let max = Math.max(r, g, b);
        let factor = 255 / max;

        r = Math.min(255, Math.floor(r * factor));
        g = Math.min(255, Math.floor(g * factor));
        b = Math.min(255, Math.floor(b * factor));

        return { r, g, b };
    }

    let hash = hashEmail(email);
    let r = (hash & 0xFF0000) >> 16;
    let g = (hash & 0x00FF00) >> 8;
    let b = (hash & 0x0000FF);

    const strongColor = ensureStrongColor(r, g, b);

    return `rgb(${strongColor.r}, ${strongColor.g}, ${strongColor.b})`;
}

export default generateStrongFromString;