import React, { useState } from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    TextField,
    useTheme,
} from "@material-ui/core";

import api from "../../../../services/api";

const useStyles = makeStyles(theme => ({

	modalTitle: {
		fontFamily: "Poppins",
		color: theme.palette.primary.mainReverseByWhite,
	},
	btnStyles: {
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
		borderRadius: "8px",
		color: theme.palette.primary.mainReverseByWhite,
		background: "transparent",
		"&:hover": {
			border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
			background: "transparent",
		},
	},
}));

export function CategoriesModal({
    open,
    handleClose,
    categorie,
    type = "",
    handleShowNewCategories,
}) {
    const [title, setTitle] = useState("");
    const theme = useTheme()
    const classes = useStyles(theme);
    const handleSave = async () => {
        try {
          if (!title) {
            alert("Por favor, preencha o título da conversa.");
            return;
          }
    
          if (type === "edit") {
            await api.put(`/chat-categories/${categorie.id}`, {
              name: title,
            });
          } else {
            const { data } = await api.post("/chat-categories", {
              name: title,
            });
            handleShowNewCategories(data)
          }
          handleClose();
        } catch (err) { }
      };
      
    return (
        <Dialog
            open={open}
            PaperProps={{
              style: { border: `2px solid ${theme.palette.primary.mainReverseByWhite}70` }
            }}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className={classes.modalTitle} id="alert-dialog-title">Agrupamento</DialogTitle>
            <DialogContent>
                <div style={{ display: "flex", flexDirection: "column", gap: "1rem", maxWidth: "300px" }}>
                    <Grid item>
                        <TextField
                            label="Título"
                            placeholder="Título"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <Button className={classes.btnStyles} onClick={handleClose} color="primary">
                    Fechar
                </Button>
                <Button className={classes.btnStyles} onClick={handleSave} color="primary" variant="contained">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
