import React from "react";
import { CheckCircle, HourglassEmpty, Schedule, ConfirmationNumber } from "@material-ui/icons";
import OnlineAttendants from "../OnlineAttendants";
import DashboardItem from "../DashboardItem";
import GroupIcon from "@material-ui/icons/Group";

const DashboardHeader = ({ attendants, counters, classes, serviceQuality, isSuper }) => {
  return (
    <div className="cmp-dashboard__raw-data">
      {/* Atendentes online */}
      {isSuper ?
        <OnlineAttendants attendants={counters?.onlineUsers} classes={classes} />
        : (
          <OnlineAttendants attendants={attendants.length > 0 ? attendants.filter((attendant) => attendant.online)?.length : 0} classes={classes} />
        )}

      {/* Outros itens */}
      {!isSuper && <DashboardItem
        title="Atd. Pendentes"
        icon={Schedule}
        value={counters.supportPending ? counters.supportPending : 0}
        classes={classes}
      />}
      {!isSuper && <DashboardItem
        title="Atd. Acontecendo"
        icon={HourglassEmpty}
        value={counters.supportHappening ? counters.supportHappening : 0}
        classes={classes}
      />}
      {!isSuper && <DashboardItem
        title="Atd. Realizados"
        icon={CheckCircle}
        value={counters.supportFinished ? counters.supportFinished : 0}
        classes={classes}
      />}
      {!isSuper && <DashboardItem
        title="Q. Serviço"
        icon={CheckCircle} // Pode ser qualquer ícone, altere conforme desejado
        value={serviceQuality !== null ? `${serviceQuality.toFixed(2)}%` : 'N/A'}
        classes={classes}
      />}

      {/* Super itens */}

      {isSuper && <DashboardItem
        title="Usuários"
        icon={GroupIcon}
        value={counters?.totalUsers || 0}
        classes={classes}
      />}

      {isSuper && <DashboardItem
        title="Empresas"
        icon={GroupIcon}
        value={counters?.totalCompanies || 0}
        classes={classes}
      />}

      {isSuper && <DashboardItem
        title="Tickets Abertos"
        icon={HourglassEmpty}
        value={counters?.openTickets || 0}
        classes={classes}
      />}

      {isSuper && <DashboardItem
        title="Total Tickets"
        icon={ConfirmationNumber}
        value={counters?.totalTickets || 0}
        classes={classes}
      />}
    </div>
  );
};

export default DashboardHeader;
