import React from "react";

const Footer = ({classes}) => {

  return (
      <footer className="cmp-login__footer">
        <div className="cmp-login__max-container">
          <a className={classes.darkerTexts} href={"https://policies.google.com/privacy"} rel="noopener noreferrer" target={"_blank"}>
            Privacy Policy
          </a>
          <a className={classes.darkerTexts} href={"https://policies.google.com/terms"} rel="noopener noreferrer" target={"_blank"}>
            Terms of Service
          </a>
        </div>
      </footer>
  );
};

export default Footer;

