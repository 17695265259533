import React, { useState, useEffect } from "react";
import {
    Grid,
    TextField,
} from "@material-ui/core";
import { Formik, Form, Field } from 'formik';
import ButtonWithSpinner from "../../ButtonWithSpinner";
import useStyles from "../style";

export function HelpManagerForm(props) {
    const { onSubmit, onDelete, initialValue, loading, setDialogOpen } = props;
    const classes = useStyles();

    const [record, setRecord] = useState(initialValue);

    useEffect(() => {
        setRecord(initialValue);
    }, [initialValue]);

    const handleSubmit = async (data) => {
        onSubmit(data);
        setDialogOpen(false);
    };

    const handleDelete = async (data) => {
        onDelete(data);
        setDialogOpen(false);
    };

    return (
        <Formik
            enableReinitialize
            className={classes.fullWidth}
            initialValues={record}
            onSubmit={(values, { resetForm }) =>
                setTimeout(() => {
                    handleSubmit(values);
                    resetForm();
                }, 500)
            }
        >
            {(values) => (
                <Form className={classes.fullWidth}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                label="Título"
                                name="title"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                label="Código do Vídeo"
                                name="video"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                label="Descrição"
                                name="description"
                                variant="outlined"
                                fullWidth
                                margin="dense"
                            />
                        </Grid>

                        {record.id !== undefined ? (
                            <Grid item xs={12}>
                                <ButtonWithSpinner
                                    fullWidth
                                    loading={loading}
                                    onClick={() => handleDelete(record)}
                                    variant="contained"
                                    color="secondary"
                                >
                                    Excluir
                                </ButtonWithSpinner>
                            </Grid>
                        ) : null}

                        <Grid item xs={12}>
                            <ButtonWithSpinner
                                fullWidth
                                loading={loading}
                                type="submit"
                                variant="contained"
                                color="primary"
                            >
                                Salvar
                            </ButtonWithSpinner>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}
