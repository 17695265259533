import React from "react";
import { Doughnut } from 'react-chartjs-2';

const TrafficDonutChart = ({ trafegoSite }) => {
	const chartStyle = {
		height: '100%',
		width: '100%'
	};

	if (trafegoSite.length === 0) {
		const data = {
			labels: ["Nenhum"],
			datasets: [
				{
					label: 'Tráfego do Site',
					data: [1],
					backgroundColor: ['#FF6384'],
					hoverOffset: 4,
				},
			],
		};

		return <Doughnut data={data} options={{ plugins: { legend: { display: false } } }} style={chartStyle} />;
	}

	const trafficCounts = trafegoSite.reduce((acc, type) => {
		acc[type] = (acc[type] || 0) + 1;
		return acc;
	}, {});

	const data = {
		labels: Object.keys(trafficCounts),
		datasets: [
			{
				label: 'Tráfego do Site',
				data: Object.values(trafficCounts),
				backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
				hoverOffset: 4,
			},
		],
	};

	return <Doughnut data={data} options={{ plugins: { legend: { display: false } } }} style={chartStyle} />;
};

export default TrafficDonutChart;