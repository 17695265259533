import React from "react";

import {  useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FilterListIcon from '@material-ui/icons/FilterList';

import { i18n } from "../../translate/i18n";
import { Button } from "@material-ui/core";

const TicketTabsHeader = ({ tab, classes, handleChangeTab, setFilterModal, filterModalOpen, setNewTicketModalOpen, showGroupTab }) => {
    const theme = useTheme()
    return (
        <Paper elevation={0} square className={classes.tabsHeader}>
            <Tabs
                value={tab}
                onChange={handleChangeTab}
                variant="fullWidth"
                indicatorColor="primary"
                className="cmp-atendimento__header"
                aria-label="icon label tabs example"
                style={{
                    alignItems: "center"
                }}
            >
                <Tab
                    value={"open"}
                    label={i18n.t("tickets.tabs.open.title")}
                    classes={{ root: classes.tab }}
                    style={{ width: 80, color: theme.palette.primary.mainReverseByWhite }}
                />

                {showGroupTab && (
                    <Tab
                        value={"group"}
                        label={i18n.t("tickets.tabs.group.title")}
                        classes={{ root: classes.tab }}
                        style={{ width: 80 }}
                    />
                )}

                <Tab
                    value={"search"}
                    icon={<SearchIcon />}
                    title={i18n.t("tickets.tabs.search.title")}
                    classes={{ root: classes.tab }}
                />
                <Tab
                    value={"closed"}
                    icon={<CheckBoxIcon colorSecondary="red" colorPrimary="blue" style={{
                        backgroundColor: "transparent",
                        border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
                        borderRadius: "4px",
                        color: theme.palette.primary.mainReverseByWhite
                    }} />}
                    title={i18n.t("tickets.tabs.closed.title")}
                    classes={{ root: classes.tab }}
                />

                {(tab !== "search" && tab !== "closed") && (
                    <Button
                        variant="outlined"
                        color="primary"
                        title="Filtros"
                        onClick={() => {
                            setFilterModal(!filterModalOpen);
                        }}
                        style={{
                            minWidth: "24px",
                            width: "24px",
                            height: "24px",
                            fontSize: "0.5rem",
                            padding: "0",
                            border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
                            color: theme.palette.primary.mainReverseByWhite,
                            fontWeight: "bold"
                        }}
                    >
                        <FilterListIcon />
                    </Button>
                )}

                <Button
                    variant="outlined"
                    color="primary"
                    title={i18n.t("ticketsManager.buttons.newTicket")}
                    onClick={() => setNewTicketModalOpen(true)}
                    style={{
                        minWidth: "24px",
                        width: "24px",
                        height: "24px",
                        fontSize: "0.7rem",
                        padding: "0",
                        border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
                        color: theme.palette.primary.mainReverseByWhite,
                        fontWeight: "bold"
                    }}
                >
                    {i18n.t("ticketsManager.buttons.newTicket")}
                </Button>
            </Tabs>
        </Paper>
    );
};

export default TicketTabsHeader;
