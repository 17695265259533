import React from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import "./style.css";
import App from "./App";

import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
	<BrowserRouter>
		<CssBaseline>
			<App />
		</CssBaseline>
	</BrowserRouter>,
	document.getElementById('root')
);
