import React, { useState, useEffect, useContext, useRef } from "react";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Tooltip, IconButton, Avatar } from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import truncateText from "../../utils/truncateText";
import TagModal from "../../components/TagModal";
import useStyles from "./style";
import handleDragEnd from "./KanbanComponents/utils/handleDragEnd";
import TicketMessagesDialog from "../../components/TicketMessagesDialog";
import { useHistory } from "react-router-dom";
import getSettingValue from "../../utils/getSettingValue";

const Kanban = () => {
  const classes = useStyles();
  const history = useHistory();
  const [tags, setTags] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState({ id: 0 });
  const [tickets, setTickets] = useState([]);
  const [lanes, setLanes] = useState([]);
  const { user } = useContext(AuthContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [ticketModalOpen, setTicketModalOpen] = useState(false);
  const [selectedTag, setSelectedTag] = useState(0);
  const jsonString = user.queues.map(queue => queue.UserQueue.queueId);
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentLane, setCurrentLane] = useState(null);
  const [settings, setSettings] = useState(null);

  const fetchTags = async () => {
    try {
      const response = await api.get("/tags/kanban");
      const fetchedTags = response.data.lista || [];
      setTags(fetchedTags);
      await fetchTickets(jsonString);
    } catch (error) {
      console.log(error);
    }
  };

  const loadLaneOrder = () => {
    const savedLaneOrder = localStorage.getItem('laneOrder');
    if (savedLaneOrder) {
      const laneOrderArray = JSON.parse(savedLaneOrder);
      const orderedLanes = laneOrderArray.map(id => lanes.find(lane => lane.id === id)).filter(Boolean);
      setLanes(orderedLanes);
    }
  };

  const fetchTagsRef = useRef(fetchTags);
  const loadLaneOrderRef = useRef(loadLaneOrder);

  useEffect(() => {
    const getAll = async (params) => {
      const { data } = await api.request({
          url: '/settings',
          method: 'GET',
          params
      });
      setSettings(data);
    };
    
    getAll();

    fetchTagsRef.current();
    loadLaneOrderRef.current();
  }, []);

  const fetchTickets = async (jsonString) => {
    try {
      const { data } = await api.get("/ticket/kanban", {
        params: { queueIds: JSON.stringify(jsonString), teste: true }
      });
      setTickets(data.tickets);
      
    } catch (err) {
      console.log(err);
      setTickets([]);
    }
  };

  useEffect(() => {
    const popularCards = () => {
      const initialLanes = [
        {
          id: "lane0",
          title: i18n.t("Em aberto"),
          cards: tickets.filter(ticket => !ticket.tags.some((tag) => tag.kanban === 1)),
        },
        ...tags.map(tag => ({
          id: tag.id.toString(),
          title: tag.name,
          cards: tickets.filter(ticket => ticket.tags.map(t => t.id).includes(tag.id)),
          style: { backgroundColor: tag.color },
        })),
      ];
      setLanes(initialLanes);
    };
    popularCards();
  }, [tags, tickets]);



  const handleCardMove = async (ticketId, targetTagId, columnName) => {
    const checkMensagem = getSettingValue("CheckMensagemKanban", settings) === "enabled";
    const alertKanban = getSettingValue("AlertKanban", settings) === "enabled";

    try {
      await api.delete(`/ticket-tags/${ticketId}`);
      if(alertKanban) {
        toast.success("Ticket movido com sucesso");
      }

      if(checkMensagem) {
        await api.post(`/messages/${ticketId}`, { fromMe: true, mediaUrl: "", body: `O seu status foi alterado para: ${columnName}`, read: 1, quotedMsg: null});
        toast.success("Mensagem enviada ao usuário");
      }
      await api.put(`/ticket-tags/${ticketId}/${targetTagId}`);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseModal = () => {
    setSelectedTag(null);
    setModalOpen(false);
  };
  const toggleMenu = (lane) => {
    setCurrentLane(lane);
    setMenuOpen(!menuOpen);
  };
  const handleOpenModal = () => setModalOpen(true);

  const handleEdit = (tag) => {
    setSelectedTag(tag);
    setModalOpen(true);
  };

  const handleDelete = async (laneId) => {
    try {
      await api.delete(`/tags/${laneId}`);
      setLanes(prevState => prevState.filter((lane) => lane.id !== laneId))
      toast.success(i18n.t("tags.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
  };
  const hideContactNumberActive = localStorage.getItem("hideContactNumberActive") === "true";

  return (
    <DragDropContext onDragEnd={(result) => handleDragEnd(result, lanes, setLanes, handleCardMove)}>
      <TicketMessagesDialog
        open={ticketModalOpen}
        handleClose={(e) => {
          e.stopPropagation();
          setTicketModalOpen(false)
        }}
        ticketId={selectedTicket.id}
      />
      <Droppable droppableId="all-columns" direction="horizontal" type="column">
        {(provided) => (
          <div
            className={classes.root}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {lanes.map((lane, index) => (
              <Draggable key={lane.id} draggableId={lane.id} index={index}>
                {(provided) => (
                  <div
                    className={classes.laneContainer}
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                  >
                    <div style={{ position: "relative", display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>
                      <h3 {...provided.dragHandleProps}>{lane.title}</h3>
                      { index !== 0 && <IconButton
                        className={classes.moreIcon}
                        onClick={() => toggleMenu(lane)}
                      >
                        <MoreVert />
                      </IconButton>}
                      {menuOpen && currentLane?.id === lane.id && (
                        <div style={{ position: "absolute", right: 0, top: "1rem", backgroundColor: "#fff", border: "1px solid #ccc", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "4px", zIndex: 10 }}>
                          <p onClick={() => handleEdit(lane)} style={{ padding: "8px", cursor: "pointer" }}>Editar</p>
                          <p onClick={() => handleDelete(lane.id)} style={{ padding: "8px", cursor: "pointer" }}>Deletar</p>
                        </div>
                      )}
                    </div>
                    <Droppable droppableId={lane.id} type="card">
                      {(provided) => (
                        <div className={classes.lane} ref={provided.innerRef} {...provided.droppableProps}>
                          {lane.cards.length === 0 ? (
                            <div className={classes.emptyCard}>Arraste</div>
                          ) : (
                            lane.cards.map((ticket, index) => (
                              <Draggable key={ticket.id} draggableId={ticket.id.toString()} index={index}>
                                {(provided) => (
                                  <div
                                    className={classes.card}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                      <div style={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
                                        <Avatar alt={ticket.contact.name} src={ticket.contact.profilePicUrl} />
                                        <h4>{ticket.contact.name}</h4>
                                      </div>
                                      <span style={{ fontSize: "10px" }}>Nº {ticket.id}</span>
                                    </div>
                                    <span style={{ fontSize: "10px" }}>{(hideContactNumberActive && user.profile === "user") ? "" : ticket?.contact?.number}</span>
                                    <Tooltip title={ticket.lastMessage}>
                                      <div style={{ display: "flex", alignItems: "center" }}>
                                        <IconButton 
                                          onClick={() => {
                                            setSelectedTicket(ticket)
                                            setTicketModalOpen(true)
                                          }}
                                          style={{ padding: "0", marginRight: "8px" }}
                                        >
                                          <VisibilityIcon />
                                        </IconButton>
                                        <p>{truncateText(ticket.lastMessage, 20)}</p>
                                      </div>
                                    </Tooltip>
                                    <div style={{display: "flex", justifyContent: "end"}}>
                                    </div>
                                    <button className={classes.button} onClick={() => {
                                      history.push(`/tickets/${ticket.uuid}`)
                                    }}>
                                      Abrir Ticket
                                    </button>
                                  </div>
                                )}
                              </Draggable>
                            ))
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            {user.profile === "admin" && <div className={classes.addColumnButton} onClick={handleOpenModal}>
              <h4 style={{ fontWeight: "500" }}>Adicionar Coluna +</h4>
            </div>}
          </div>
        )}
      </Droppable>

      <TagModal
        kanbanSelected={true}
        open={modalOpen}
        onClose={handleCloseModal}
        reload={fetchTags}
        aria-labelledby="form-dialog-title"
        tagId={selectedTag && selectedTag.id}
      />
    </DragDropContext>
  );
}

export default Kanban;
