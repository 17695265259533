import React, { useState, useEffect } from "react";
import {
    DialogTitle,
    Grid,
} from "@material-ui/core";
import { Formik, Form } from "formik";
import ButtonWithSpinner from "../../../ButtonWithSpinner";


import { toast } from "react-toastify";
import ModalUsers from "../../../ModalUsers";
import api from "../../../../services/api";
import { head, isArray } from "lodash";

import moment from "moment";
import useStyles from "../../style";
import FormInputs from "./FormInputs";

export function CompanyForm(props) {
    const { onSubmit, onDelete, onCancel, initialValue, loading } = props;
    const classes = useStyles();
    const [plans, setPlans] = useState([]);
    const [modalUser, setModalUser] = useState(false);
    const [firstUser, setFirstUser] = useState({});

    const [record, setRecord] = useState({
        name: "",
        email: "",
        phone: "",
        planId: "",
        status: true,
        campaignsEnabled: false,
        dueDate: "",
        recurrence: "",
        ...initialValue,
    });

    useEffect(() => {
        const listPlans = async (params) => {
            const { data } = await api.request({
                url: '/plans/all',
                method: 'GET',
                params
            });
            return data;
        }
        async function fetchData() {
            const list = await listPlans();
            setPlans(list);
        }
        fetchData();
    }, []);

    useEffect(() => {
        setRecord((prev) => {
            if (moment(initialValue).isValid()) {
                initialValue.dueDate = moment(initialValue.dueDate).format(
                    "YYYY-MM-DD"
                );
            }
            return {
                ...prev,
                ...initialValue,
            };
        });
    }, [initialValue]);

    const handleSubmit = async (data) => {
        if (data.dueDate === "" || moment(data.dueDate).isValid() === false) {
            data.dueDate = null;
        }
        onSubmit(data);
        setRecord({ ...initialValue, dueDate: "" });
    };

    const handleOpenModalUsers = async () => {
        try {
            const { data } = await api.get("/users/list", {
                params: {
                    companyId: initialValue.id,
                },
            });
            if (isArray(data) && data.length) {
                setFirstUser(head(data));
            }
            setModalUser(true);
        } catch (e) {
            toast.error(e);
        }
    };

    const handleCloseModalUsers = () => {
        setFirstUser({});
        setModalUser(false);
    };

    const incrementDueDate = () => {
        const data = { ...record };
        if (data.dueDate !== "" && data.dueDate !== null) {
            switch (data.recurrence) {
                case "MENSAL":
                    data.dueDate = moment(data.dueDate)
                        .add(1, "month")
                        .format("YYYY-MM-DD");
                    break;
                case "BIMESTRAL":
                    data.dueDate = moment(data.dueDate)
                        .add(2, "month")
                        .format("YYYY-MM-DD");
                    break;
                case "TRIMESTRAL":
                    data.dueDate = moment(data.dueDate)
                        .add(3, "month")
                        .format("YYYY-MM-DD");
                    break;
                case "SEMESTRAL":
                    data.dueDate = moment(data.dueDate)
                        .add(6, "month")
                        .format("YYYY-MM-DD");
                    break;
                case "ANUAL":
                    data.dueDate = moment(data.dueDate)
                        .add(12, "month")
                        .format("YYYY-MM-DD");
                    break;
                default:
                    break;
            }
        }
        setRecord(data);
    };

    return (
        <>
            <DialogTitle className={classes.modalTitle} id="form-dialog-title">
				{ !record.id ? `Adicionar Empresa` : `Editar Empresa`}
			</DialogTitle>
            <ModalUsers
                userId={firstUser.id}
                companyId={initialValue.id}
                open={modalUser}
                onClose={handleCloseModalUsers}
            />
            <Formik
                enableReinitialize
                className={classes.fullWidth}
                initialValues={record}
                onSubmit={(values, { resetForm }) =>
                    setTimeout(() => {
                        handleSubmit(values);
                        resetForm();
                    }, 500)
                }
            >
                {(values, setValues) => (
                    <Form className={classes.fullWidth}>
                        <Grid container spacing={2} justifyContent="flex-end">
                            <FormInputs classes={classes} plans={plans} />

                            <Grid item xs={12}>
                                <Grid
                                    container
                                    justifyContent="flex-end"
                                    spacing={0}
                                    style={{ columnGap: '0.5rem', rowGap: '0.5rem' }}
                                >
                                    <Grid item>
                                        <ButtonWithSpinner
                                            className={classes.simpleBtnStyles}
                                            style={{ marginTop: 7 }}
                                            loading={loading}
                                            onClick={() => onCancel()}
                                        >
                                            Limpar
                                        </ButtonWithSpinner>
                                    </Grid>

                                    {record.id !== undefined && (
                                        <>
                                            <Grid item>
                                                <ButtonWithSpinner
                                                    style={{ marginTop: 7 }}
                                                    className={classes.simpleBtnStyles}
                                                    loading={loading}
                                                    onClick={() => incrementDueDate()}
                                                >
                                                    Vencimento
                                                </ButtonWithSpinner>
                                            </Grid>
                                            <Grid item>
                                                <ButtonWithSpinner
                                                    style={{ marginTop: 7 }}
                                                    className={classes.simpleBtnStyles}
                                                    loading={loading}
                                                    onClick={() => onDelete(record)}
                                                >
                                                    Excluir
                                                </ButtonWithSpinner>
                                            </Grid>
                                            <Grid item>
                                                <ButtonWithSpinner
                                                    style={{ marginTop: 7 }}
                                                    className={classes.simpleBtnStyles}
                                                    loading={loading}
                                                    onClick={() => handleOpenModalUsers()}
                                                >
                                                    Usuário
                                                </ButtonWithSpinner>
                                            </Grid>
                                        </>
                                    )}

                                    <Grid item>
                                        <ButtonWithSpinner
                                            className={classes.simpleBtnStyles}
                                            style={{ marginTop: 7 }}
                                            loading={loading}
                                            type="submit"
                                        >
                                            Salvar
                                        </ButtonWithSpinner>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Form>
                )}

            </Formik>
        </>
    );
}

export default CompanyForm;