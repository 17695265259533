import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useRef,
} from "react";

import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import * as XLSX from "xlsx";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ContactListItemModal from "../../components/ContactListItemModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import useContactLists from "../../hooks/useContactLists";
import { Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddIcon from "@material-ui/icons/Add";
import FileDownloadIcon from "@material-ui/icons/GetApp";
import planilhaExemplo from "../../assets/planilha.xlsx";
import { socketConnection } from "../../services/socket";
import truncateText from "../../utils/truncateText";
import Pagination from "@material-ui/lab/Pagination";

const reducer = (state, action) => {
  if (action.type === "LOAD_CONTACTS") {
    const contacts = action.payload;
    const newContacts = [];

    contacts.forEach((contact) => {
      const contactIndex = state.findIndex((c) => c.id === contact.id);
      if (contactIndex !== -1) {
        state[contactIndex] = contact;
      } else {
        newContacts.push(contact);
      }
    });

    return newContacts;
  }

  if (action.type === "UPDATE_CONTACTS") {
    const contact = action.payload;
    const contactIndex = state.findIndex((c) => c.id === contact.id);

    if (contactIndex !== -1) {
      state[contactIndex] = contact;
      return [...state];
    } else {
      return [contact, ...state];
    }
  }

  if (action.type === "DELETE_CONTACT") {
    const contactId = action.payload;

    const contactIndex = state.findIndex((c) => c.id === contactId);
    if (contactIndex !== -1) {
      state.splice(contactIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowX: "scroll",
    ...theme.scrollbarStyles,
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    marginTop: "2rem",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    "& th": {
      fontFamily: "Poppins",
      fontWeight: "500",
      color: theme.palette.primary.mainReverseByWhite
    }
  },

  buttonStyles: {
    width: "25px",
    height: "25px",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}`,
    color: theme.palette.primary.mainReverseByWhite,
    borderRadius: "8px",
    minWidth: "auto",
    background: "transparent",
    padding: 0
  }
}));

const ContactListItems = () => {
  const classes = useStyles();

  const { user } = useContext(AuthContext);
  const { contactListId } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [contacts, dispatch] = useReducer(reducer, []);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const [contactListItemModalOpen, setContactListItemModalOpen] =
    useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [contactList, setContactList] = useState({});
  const [count, setCount] = useState(0);
  const pageSize = 20;
  const fileUploadRef = useRef(null);

  const { findById: findContactList } = useContactLists();

  useEffect(() => {
    findContactList(contactListId).then((data) => {
      setContactList(data);
    });
  }, [contactListId]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get(`contact-list-items`, {
            params: { searchParam, pageNumber, contactListId },
          });
          dispatch({ type: "LOAD_CONTACTS", payload: data.contacts });
          setCount(data.count);
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, contactListId]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    const socket = socketConnection({ companyId });

    socket.on(`company-${companyId}-ContactListItem`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_CONTACTS", payload: data.record });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_CONTACT", payload: +data.id });
      }

      if (data.action === "reload") {
        dispatch({ type: "LOAD_CONTACTS", payload: data.records });
      }
    });

    socket.on(
      `company-${companyId}-ContactListItem-${contactListId}`,
      (data) => {
        if (data.action === "reload") {
          dispatch({ type: "LOAD_CONTACTS", payload: data.records });
        }
      }
    );

    return () => {
      socket.disconnect();
    };
  }, [contactListId]);

  const handleOpenContactListItemModal = () => {
    setSelectedContactId(null);
    setContactListItemModalOpen(true);
  };

  const handleCloseContactListItemModal = () => {
    setSelectedContactId(null);
    setContactListItemModalOpen(false);
  };

  const hadleEditContact = (contactId) => {
    setSelectedContactId(contactId);
    setContactListItemModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contact-list-items/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleImportContacts = async () => {
    try {
      const formData = new FormData();
      formData.append("file", fileUploadRef.current.files[0]);
      await api.request({
        url: `contact-lists/${contactListId}/upload`,
        method: "POST",
        data: formData,
      });
    } catch (err) {
      toastError(err);
    }
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleDownloadModelToExport = () => {
    const allDatas = [
      {
        nome: "Contato 1",
        numero: "554899999999",
        email: "contato1@email.com",
        cpf: "11111111111",
        "número contrato": "33333333331235E+016",
        "valor dívida": 221000.5,
        "dias atraso": 222,
        observacao: "Observação 1",
        campo1: "Valor 123",
        campo2: "Valor 2",
        campo3: "Valor 3",
      },
      {
        nome: "Contato 2",
        numero: "554899999999",
        email: "contato2@email.com",
        cpf: "22222222222222",
        "número contrato": "444444654321",
        "valor dívida": 33500.75,
        "dias atraso": 3315,
        observacao: "Observação 2",
        campo1: "Valor A",
        campo2: "Valor f",
        campo3: "Valor a",
      }
    ];

    const exportData = allDatas.map(data => {
      return { ...data };
    });

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(wb, ws, "Modelo_Importação");
    XLSX.writeFile(wb, "modelo_importacao.xlsx");
  };


  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  const goToContactLists = () => {
    history.push("/contact-lists");
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <ContactListItemModal
        open={contactListItemModalOpen}
        onClose={handleCloseContactListItemModal}
        aria-labelledby="form-dialog-title"
        contactId={selectedContactId}
      ></ContactListItemModal>
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t("contactListItems.confirmationModal.deleteTitle")} ${deletingContact.name
            }?`
            : `${i18n.t("contactListItems.confirmationModal.importTitlte")}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={() =>
          deletingContact
            ? handleDeleteContact(deletingContact.id)
            : handleImportContacts()
        }
      >
        {deletingContact ? (
          `${i18n.t("contactListItems.confirmationModal.deleteMessage")}`
        ) : (
          <>
            {i18n.t("contactListItems.confirmationModal.importMessage")}
            <a href={planilhaExemplo} download="planilha.xlsx">
              Clique aqui para baixar planilha exemplo.
            </a>
          </>
        )}
      </ConfirmationModal>
      <MainHeader>
        <Grid container spacing={2} style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "start" }} alignItems="center">
          <h5 style={{ margin: "0.8rem 0 0 1rem" }}>Contatos da campanha - {contactList.name}</h5>
          <Grid item>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item xs={6} sm={3}>
                <Tooltip title={"Voltar"}>
                  <IconButton
                    fullWidth
                    color="primary"
                    className={classes.buttonStyles}
                    onClick={goToContactLists}
                  >
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Tooltip title={"Upload"}>
                  <IconButton
                    fullWidth
                    className={classes.buttonStyles}
                    color="primary"
                    onClick={async () => {
                      if(user) {
                        try {
                          const { data } = await api.get(`/users/${user?.id}`);
                          if(!data?.hasProxy) {
                            toastError("Você precisa adicionar um Proxy a sua conexão padrão");
                            return
                          }
                          fileUploadRef.current.value = null;
                          fileUploadRef.current.click();
                        } catch (e) {
                          toastError("User não encontrado com proxy")
                        }
                      }
                    }}
                  >
                    <FileDownloadIcon style={{ transform: 'rotate(180deg)' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Tooltip title={i18n.t("contactListItems.buttons.add")}>
                  <IconButton
                    fullWidth
                    className={classes.buttonStyles}
                    color="primary"
                    onClick={handleOpenContactListItemModal}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Tooltip title="Modelo">
                  <IconButton
                    fullWidth
                    className={classes.buttonStyles}
                    color="primary"
                    onClick={handleDownloadModelToExport}
                  >
                    <FileDownloadIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainHeader>

      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <>
          <input
            style={{ display: "none" }}
            id="upload"
            name="file"
            type="file"
            accept=".xls,.xlsx"
            onChange={() => {
              setConfirmOpen(true);
            }}
            ref={fileUploadRef}
          />
        </>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ width: "0%" }}>
                #
              </TableCell>
              <TableCell>{i18n.t("contactListItems.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contactListItems.table.number")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("contactListItems.table.email")}
              </TableCell>
              <TableCell align="center">CPF</TableCell>
              <TableCell align="center">N.Contrato</TableCell>
              <TableCell align="center">Val.Dívida</TableCell>
              <TableCell align="center">D.Atraso</TableCell>
              <TableCell align="center">Observação</TableCell>
              <TableCell align="center">Campo 1</TableCell>
              <TableCell align="center">Campo 2</TableCell>
              <TableCell align="center">Campo 3</TableCell>
              <TableCell align="center">
                {i18n.t("contactListItems.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {contacts.map((contact) => (
                <TableRow key={contact.id}>
                  <TableCell align="center" style={{ width: "0%" }}>
                    <IconButton>
                      {contact.isWhatsappValid ? (
                        <CheckCircleIcon titleAccess="Whatsapp Válido" htmlColor="green" />
                      ) : (
                        <BlockIcon titleAccess="Whatsapp Inválido" htmlColor="grey" />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={contact.name}>
                      <span>{truncateText(contact.name || "", 20)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={contact.number}>
                      <span>{contact.number}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={contact.email}>
                      <span>{truncateText(contact.email || "", 20)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={contact.cpf}>
                      <span>{truncateText(contact.cpf || "", 14)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={contact.contractNumber}>
                      <span>{truncateText(contact.contractNumber || "", 10)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={contact.debtValue }>
                      <span>{contact.debtValue}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={contact.overdueDays}>
                      <span>{contact.overdueDays}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={contact.observation}>
                      <span>{truncateText(contact.observation || "", 20)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={contact.field1}>
                      <span>{truncateText(contact.field1 || "", 10)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={contact.field2}>
                      <span>{truncateText(contact.field2 || "", 10)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={contact.field3}>
                      <span>{truncateText(contact.field3 || "", 10)}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => hadleEditContact(contact.id)}
                    >
                      <EditIcon />
                    </IconButton>
                    <Can
                      role={user.profile}
                      perform="contacts-page:deleteContact"
                      yes={() => (
                        <IconButton
                          size="small"
                          onClick={() => {
                            setConfirmOpen(true);
                            setDeletingContact(contact);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={12} />}
            </>
          </TableBody>
        </Table>
        <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: "1rem" }}>
          <Pagination
            count={Math.ceil(count / pageSize)}
            page={pageNumber}
            onChange={(event, value) => setPageNumber(value)}
          />
        </div>
      </Paper>
    </MainContainer>
  );
};

export default ContactListItems;
