import React from "react";
import { Button, useTheme } from "@material-ui/core";
import baileyIcon from "../../assets/baileys-icon.png";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PublicIcon from "@material-ui/icons/Public";
import getSocialBackgroundColor from "../../utils/socialMediaBackground";

const SocialMediaFilter = ({ handleSocialButtonClick, contactType, style }) => {
    const theme = useTheme();
    const getButtonStyle = (type) => ({
        filter: contactType !== type ? "grayscale(100%)" : "none",
        background: contactType === type ? getSocialBackgroundColor(type, theme) : `${theme.palette.primary.main}50`,
        color: "#fff",
        minWidth: "25px",
        width: "25px",
        minHeigth: "25px",
        height: "25px",
        padding: "0.5rem",
        fontFamily: "Poppins"
    });

    return (
        <div style={{ display: "flex", gap: "1rem", justifyContent: "center", marginBottom: "1rem", ...style }}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => handleSocialButtonClick(null)}
                style={getButtonStyle(null)}
            >
                <PublicIcon />
            </Button>

            <Button
                variant="contained"
                color="primary"
                onClick={() => handleSocialButtonClick("facebook")}
                style={getButtonStyle("facebook")}
            >
                <FacebookIcon />
            </Button>

            <Button
                variant="contained"
                color="secondary"
                onClick={() => handleSocialButtonClick("instagram")}
                style={getButtonStyle("instagram")}
            >
                <InstagramIcon />
            </Button>

            <Button
                variant="contained"
                onClick={() => handleSocialButtonClick("baileys")}
                style={getButtonStyle("baileys")}
            >
                <img style={{ width: "25px" }} src={baileyIcon} alt="WhatsApp Official" />
            </Button>

            <Button
                variant="contained"
                color="default"
                onClick={() => handleSocialButtonClick("official")}
                style={getButtonStyle("official")}
            >
                <WhatsAppIcon />
            </Button>
        </div>
    );
};

export default SocialMediaFilter;
