import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Paper,
  Button,
  TextField,
  IconButton,
  Typography,
  Collapse,
  List,
  ListItem,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  Add as AddIcon,
  ExpandLess,
  ExpandMore,
} from "@material-ui/icons";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import api from "../../services/api";
import ListItemText from "@material-ui/core/ListItemText";

interface Category {
  id: number;
  name: string;
  parent_id: number | null;
  companyId?: number | null;
  subcategories?: Category[];
}

const useStyles = makeStyles((theme: any) => ({
  mainPaper: {
    flex: 1,
    overflowY: "auto",
    width: "100%",
    marginTop: theme.spacing(2),
    fontFamily: "Poppins",
    margin: "0 !important"
  },
  categoryHeader: {
    cursor: "pointer",
    padding: theme.spacing(1),
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h6": {
      color: theme.palette.primary.mainReverseByWhite,
    },
    "& span": {
      fontFamily: "Poppins"
    }
  },
  buttonAdd: {
    marginBottom: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  // Diferenciar botões de ação
  categoryAction: {
    color: theme.palette.primary.mainReverseByWhite,
  },
  subcategoryAction: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.secondary.mainReverseByWhite,
  },
  btnStyles: {
    minWidth: "25px",
    width: "25px",
    height: "25px",
    minHeight: "25px",
    padding: "0",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    color: theme.palette.primary.mainReverseByWhite,
    background: "transparent",
    "&:hover": {
      background: "transparent"
    }
  },
  simpleButton: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    color: theme.palette.primary.mainReverseByWhite,
    background: "transparent",
    "&:hover": {
      background: "transparent"
    }
  },
  modalTitle: {
		fontFamily: "Poppins",
		color: theme.palette.primary.mainReverseByWhite,
	},
}));

const Categories = () => {
  const classes = useStyles();
  const [categories, setCategories] = useState<Category[]>([]);
  const [expandedCategories, setExpandedCategories] = useState<{ [key: number]: boolean }>({});
  const [error, setError] = useState<string | null>(null);

  // Modal states
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCategoryName, setModalCategoryName] = useState<string>('');
  const [modalCategoryId, setModalCategoryId] = useState<number | null>(null);
  const [subcategoryModalOpen, setSubcategoryModalOpen] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState<number | null>(null);
  const [newSubcategoryName, setNewSubcategoryName] = useState<string>('');
  const [editingSubcategoryId, setEditingSubcategoryId] = useState<number | null>(null);

  const fetchCategories = async () => {
    try {
      const companyId = localStorage.getItem('companyId');
      const response = await api.get('/api/categories/list', { params: { companyId } });

      setCategories(response.data);
    } catch (err) {
      setError('Erro ao buscar categorias');
      console.error('Erro ao carregar categorias:', err);
    }
  };

  useEffect(() => {
    const fetchCategoriesF = async () => {
      try {
        const companyId = localStorage.getItem('companyId');
        const response = await api.get('/api/categories/list', { params: { companyId } });

        setCategories(response.data);
      } catch (err) {
        setError('Erro ao buscar categorias');
        console.error('Erro ao carregar categorias:', err);
      }
    };
    fetchCategoriesF();
  }, []);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalCategoryName('');
    setModalCategoryId(null);
    setError(null);
  };

  const handleEditCategory = (category: Category) => {
    setModalCategoryId(category.id);
    setModalCategoryName(category.name);
    setModalOpen(true);
  };

  const handleSaveCategory = async () => {
    if (!modalCategoryName) {
      setError('O nome da categoria não pode estar vazio.');
      return;
    }

    try {
      if (modalCategoryId) {
        await api.put(`/api/categories/edit/${modalCategoryId}`, { name: modalCategoryName });
      } else {
        const companyId = localStorage.getItem('companyId');
        await api.post('/api/categories/create', { name: modalCategoryName, companyId });
      }
      setModalCategoryName('');
      setModalCategoryId(null);
      fetchCategories();
      handleCloseModal(); // Fechar o modal aqui

    } catch (err) {
      setError('Erro ao salvar categoria.');
      console.error('Erro ao salvar categoria:', err);
    }
  };

  const handleDeleteCategory = async (id: number) => {
    try {
      const category = categories.find((cat) => cat.id === id);
      if (category && category.subcategories && category.subcategories.length > 0) {
        setError('Não é possível deletar uma categoria que possui subcategorias.');
        return;
      }

      await api.delete(`/api/categories/delete/${id}`);
      fetchCategories();
    } catch (err) {
      setError('Erro ao deletar categoria.');
      console.error('Erro ao deletar categoria:', err);
    }
  };

  const handleOpenSubcategoryModal = (categoryId: number) => {
    setCurrentCategoryId(categoryId);
    setSubcategoryModalOpen(true);
  };

  const handleCloseSubcategoryModal = () => {
    setSubcategoryModalOpen(false);
    setNewSubcategoryName('');
    setEditingSubcategoryId(null);
  };

  const handleAddOrEditSubcategory = async () => {
    if (!newSubcategoryName || currentCategoryId === null) {
      setError('O nome da subcategoria não pode estar vazio.');
      return;
    }

    try {
      const companyId = localStorage.getItem('companyId');
      if (editingSubcategoryId) {
        await api.put(`/api/categories/edit/${editingSubcategoryId}`, {
          name: newSubcategoryName,
          parentId: currentCategoryId,
          companyId,
        });
      } else {
        await api.post('/api/categories/create', {
          name: newSubcategoryName,
          parentId: currentCategoryId,
          companyId,
        });
      }
      setNewSubcategoryName('');
      setCurrentCategoryId(null);
      setEditingSubcategoryId(null);
      handleCloseSubcategoryModal();
      fetchCategories();
    } catch (err) {
      setError('Erro ao adicionar ou editar subcategoria.');
      console.error('Erro ao adicionar ou editar subcategoria:', err);
    }
  };

  const handleEditSubcategory = (subcategory: Category) => {
    setEditingSubcategoryId(subcategory.id);
    setNewSubcategoryName(subcategory.name);
    setCurrentCategoryId(subcategory.parent_id);
    setSubcategoryModalOpen(true);
  };

  const toggleCategory = (categoryId: number) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }));
  };
  const theme: any = useTheme();
  return (
    <>
      <MainHeader>
        <Grid container justifyContent="space-between" alignItems="center">
          <h6 style={{ fontSize: "14px", fontWeight: "500", fontFamily: "Poppins", color: theme.palette.primary.mainReverseByWhite }}>Categorias</h6>
          <Grid item>
            <Button
              className={classes.btnStyles}
              onClick={handleOpenModal}
            >
              +
            </Button>
          </Grid>
        </Grid>
      </MainHeader>
      <MainContainer style={{ margin: "0" }}>
        <Paper className={classes.mainPaper}>
          {error && <Typography color="error">{error}</Typography>}

          <List>
            {categories.map((category) => (
              <React.Fragment key={category.id}>
                <ListItem
                  className={classes.categoryHeader}
                  onClick={() => toggleCategory(category.id)}
                >
                  <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
                    <IconButton size="small">
                      {expandedCategories[category.id] ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                    <Typography variant="h6" style={{ flex: 1 }}>
                      {category.name}
                    </Typography>
                  </div>
                  <IconButton
                    size="small"
                    className={classes.categoryAction}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditCategory(category);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    className={classes.categoryAction}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCategory(category.id);
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    className={classes.categoryAction}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenSubcategoryModal(category.id);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </ListItem>

                {/* Renderiza as subcategorias diretamente da resposta da API */}
                <Collapse in={expandedCategories[category.id]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {category.subcategories && category.subcategories.length > 0 ? (
                      category.subcategories.map((sub) => (
                        <ListItem key={sub.id} style={{ paddingLeft: '2rem', background: theme.palette.background.default }}>
                          <ListItemText primary={sub.name} />
                          <IconButton
                            onClick={() => handleEditSubcategory(sub)}
                            className={classes.categoryAction}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteCategory(sub.id)}
                            className={classes.categoryAction}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </ListItem>
                      ))
                    ) : (
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ paddingLeft: '2rem' }}
                      >
                        Nenhuma subcategoria encontrada.
                      </Typography>
                    )}
                  </List>
                  <Divider className={classes.divider} />
                </Collapse>
              </React.Fragment>
            ))}
          </List>


        </Paper>

        {/* Modal for Creating/Editing Categories */}
        <Dialog PaperProps={{
				style: { border: `2px solid ${theme.palette.primary.mainReverseByWhite}70` }
			}} open={modalOpen} onClose={handleCloseModal} maxWidth="sm" fullWidth>
          <DialogTitle className={classes.modalTitle}>{modalCategoryId ? 'Editar Categoria' : 'Adicionar Categoria'}</DialogTitle>
          <DialogContent>
            <TextField
              label="Nome da Categoria"
              value={modalCategoryName}
              onChange={(e) => setModalCategoryName(e.target.value)}
              fullWidth
              style={{ marginBottom: '1rem' }}
            />
          </DialogContent>
          <DialogActions>
            <Button className={classes.simpleButton} onClick={handleCloseModal}>Cancelar</Button>
            <Button className={classes.simpleButton} onClick={handleSaveCategory} color="primary">
              {modalCategoryId ? 'Salvar Alterações' : 'Adicionar'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Modal for Adding/Edit Subcategory */}
        <Dialog PaperProps={{
				style: { border: `2px solid ${theme.palette.primary.mainReverseByWhite}70` }
			}} open={subcategoryModalOpen} onClose={handleCloseSubcategoryModal} maxWidth="sm" fullWidth>
          <DialogTitle className={classes.modalTitle}>{editingSubcategoryId ? 'Editar Subcategoria' : 'Adicionar Subcategoria'}</DialogTitle>
          <DialogContent>
            <TextField
              label="Nome da Subcategoria"
              value={newSubcategoryName}
              onChange={(e) => setNewSubcategoryName(e.target.value)}
              fullWidth
              style={{ marginBottom: '1rem' }}
            />
          </DialogContent>
          <DialogActions>
            <Button className={classes.simpleButton} onClick={handleCloseSubcategoryModal} color="secondary">Cancelar</Button>
            <Button className={classes.simpleButton} onClick={handleAddOrEditSubcategory} color="primary">
              {editingSubcategoryId ? 'Salvar Alterações' : 'Adicionar'}
            </Button>
          </DialogActions>
        </Dialog>
      </MainContainer>
    </>
  );
};

export default Categories;
