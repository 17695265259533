import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        padding: "20px",
        backgroundColor: "#f4f4f4",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    header: {
        textAlign: "center",
        marginBottom: "20px",
    },
    title: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#333",
    },
    ticketsData: {
        marginTop: "20px",
        cursor: "pointer"
    },
    ticketItem: {
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
        padding: "10px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    },
    ticketIcon: {
        marginRight: "15px",
    },
    ticketDetails: {
        fontSize: "16px",
    },
}));

export default useStyles;
