import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import api from '../../services/api'; // Importando o serviço de API
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
  modalTitle: {
    fontFamily: "Poppins",
    color: theme.palette.primary.mainReverseByWhite,
  },
  btnStyles: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    color: theme.palette.primary.mainReverseByWhite,
    background: "transparent",
    "&:hover": {
      border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
      background: "transparent",
    },
  },
}));

const UserSelectModal = ({ contactId, onClose }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    api
      .get('/users/list')
      .then((response) => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError('Falha ao buscar usuários');
        setLoading(false);
      });
  }, []);

  const handleUserSelection = (event) => {
    setSelectedUserId(event.target.value);
  };

  const attachUserToContact = () => {
    if (!selectedUserId) return;

    const companyId = localStorage.getItem('companyId');

    api
      .put(`/contacts/attach-user/${contactId}`, { userId: selectedUserId, companyId })
      .then((response) => {
        toast.success('Usúario anexado ao contato');
        onClose(); // Close the modal
      })
      .catch((error) => {
        toastError('Failed to attach user');
        console.error('Failed to attach user:', error);
        setError('Failed to attach user');
      });
  };

  return (
    <Dialog PaperProps={{
      style: { border: `2px solid ${theme.palette.primary.mainReverseByWhite}70` }
  }} open={true} onClose={onClose} fullWidth>
      <DialogTitle className={classes.modalTitle}>Selecione o Usuário</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <p>{error}</p>
        ) : (
          <FormControl fullWidth>
            <InputLabel>Usuário</InputLabel>
            <Select value={selectedUserId} onChange={handleUserSelection}>
              {users.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button className={classes.btnStyles} onClick={onClose}>
          Fechar
        </Button>
        <Button className={classes.btnStyles}  onClick={attachUserToContact} disabled={!selectedUserId}>
          Vincular ao usuário
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserSelectModal;
