import React, { useState, useEffect, useRef } from "react";
import MainContainer from "../../components/MainContainer";
import useStyles from "./styles";
import api from "../../services/api";
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import ContactTickets from "../../components/ContactTickets";
import TimelineHeader from "./TimelineComponents/TimelineHeader";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import TimelineTicketDetails from "../../components/TimelineTicketDetails";

const Timeline = () => {
    const classes = useStyles();
    const [searchQuery, setSearchQuery] = useState("");
    const [contacts, setContacts] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [ticketsData, setTicketsData] = useState([]);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Estado de loading
    const [selectedTicket, setSelectedTicket] = useState(null); // Armazena o ticket selecionado para o modal
    const [modalOpen, setModalOpen] = useState(false); // Estado para abrir/fechar o modal

    const searchRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchRef.current && !searchRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const fetchContactTickets = async (contactId, page = 1, pageSize = 9999999) => {
        setIsLoading(true); // Inicia o loading
        try {
            const response = await api.get(`/timeline/tickets/${contactId}?page=${page}&pageSize=${pageSize}`);
            setTicketsData(response.data);
        } catch (error) {
            console.error("Error fetching contact tickets:", error);
            setTicketsData([]);
        } finally {
            setIsLoading(false); // Finaliza o loading
        }
    };

    const fetchFilteredContacts = async (query) => {
        setIsLoading(true); // Inicia o loading
        try {
            const response = await api.get(`/contact-timeline?contactName=${query}`);
            setContacts(response.data);
        } catch (error) {
            console.error("Error fetching filtered contacts:", error);
        } finally {
            setIsLoading(false); // Finaliza o loading
        }
    };

    const handleSearch = (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        setDropdownVisible(query.trim() !== "");

        if (query.trim() !== "") {
            fetchFilteredContacts(query);
        }
    };

    const handleSelectContact = (contact) => {
        setSelectedContact(contact);
        setSearchQuery(contact.contactName);
        setDropdownVisible(false);
        fetchContactTickets(contact.contactId);
    };

    // Função para abrir o modal e carregar os dados do ticket
    const handleTicketClick = async (ticketId) => {
        try {
            const response = await api.get(`/timeline/${ticketId}?page=1&pageSize=999999999`);
            setSelectedTicket(response.data); // Define os dados do ticket selecionado
            setModalOpen(true); // Abre o modal
        } catch (error) {
            console.error("Error fetching ticket details:", error);
        }
    };

    // Função para fechar o modal
    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedTicket(null); // Limpa os dados do ticket ao fechar o modal
    };

    return (
        <MainContainer className={classes.mainContainer}>
            <TimelineHeader
                classes={classes}
                searchRef={searchRef}
                searchQuery={searchQuery}
                handleSearch={handleSearch}
                setDropdownVisible={setDropdownVisible}
                dropdownVisible={dropdownVisible}
                contacts={contacts}
                selectedContact={selectedContact}
                handleSelectContact={handleSelectContact}
            />

            <div className={classes.selectedContactContainer}>
                {selectedContact ? (
                    <div className={classes.selectedContact}>
                        <div className={classes.avatarContainer}>
                            {selectedContact.profilePicUrl ? (
                                <Avatar
                                    alt={selectedContact.contactName}
                                    src={selectedContact.profilePicUrl}
                                    className={classes.avatar}
                                />
                            ) : (
                                <Avatar className={classes.defaultAvatar}>
                                    <PersonIcon />
                                </Avatar>
                            )}
                        </div>
                        <div className={classes.contactNameAndActivities}>
                            <h3 className={classes.contactName}>{selectedContact.contactName}</h3>
                            <p className={classes.activitiesCount}>
                                {selectedContact.ticketTrakingCount || 0} ações
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className={`${classes.noContactSelected} ${classes.selectedContact}`}>
                        <Avatar className={classes.defaultAvatar}>
                            <PersonIcon />
                        </Avatar>
                        <p>Selecione um contato</p>
                    </div>
                )}
            </div>

            <div className={classes.ticketsData}>
                {isLoading ? (
                    <div className={classes.loadingMessage}>Carregando tickets...</div>
                ) : (
                    <ContactTickets ticketsArray={ticketsData} onTicketClick={handleTicketClick} />
                )}
            </div>

            {/* Modal para exibir os detalhes do ticket */}
            <Modal
                open={modalOpen}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
               <TimelineTicketDetails modalOpen={modalOpen} selectedTicket={selectedTicket} handleCloseModal={handleCloseModal}/> 
            </Modal>
        </MainContainer>
    );
};

export default Timeline;
