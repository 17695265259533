import React from "react";

const FacebookInstagramIcon = () => {
  const iconStyle = {
    position: 'relative',
    width: '35px',
    height: '35px',
  };

  const overlayIconStyle = {
    position: 'absolute',
    top: '15px', // Ajuste a posição conforme necessário
    left: '15px', // Ajuste a posição conforme necessário
    width: '30px',
    zIndex: '1',
    height: '30px',
  };

  return (
    <div style={{ position: 'relative', }}>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
        alt="Facebook Icon"
        style={overlayIconStyle}
        />
      <img
        style={iconStyle}
        src="https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png"
        alt="Instagram Icon"
      />
    </div>
  );
};

export default FacebookInstagramIcon;