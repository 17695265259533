const reducer = (state, action) => {
    if (action.type === "LOAD_CONTACTS") {
        // Em vez de concatenar, substituímos o estado por novos contatos
        return action.payload; // Substitui todos os contatos pelo novo payload
    }

    if (action.type === "UPDATE_CONTACTS") {
        const contact = action.payload;
        const contactIndex = state.findIndex((c) => c.id === contact.id);

        if (contactIndex !== -1) {
            // Se o contato existe, atualiza-o
            state[contactIndex] = contact;
            return [...state]; // Retorna o estado atualizado
        } else {
            // Se não existe, adiciona o novo contato
            return [contact, ...state]; // Adiciona o novo contato no início
        }
    }

    if (action.type === "DELETE_CONTACT") {
        const contactId = action.payload;
        const contactIndex = state.findIndex((c) => c.id === contactId);
        if (contactIndex !== -1) {
            // Se o contato for encontrado, remove-o
            state.splice(contactIndex, 1);
        }
        return [...state]; // Retorna o estado atualizado
    }

    if (action.type === "RESET") {
        return []; // Reseta o estado para um array vazio
    }

    return state; // Retorna o estado original se nenhuma ação corresponder
};

export default reducer;
