import React, { useState, useEffect, useReducer, useContext, useRef } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Typography,
  Collapse,
  List,
  ListItem,
  Divider,
} from "@material-ui/core";
import {
  Search as SearchIcon,
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  ExpandLess,
  ExpandMore,
  Add as AddIcon,
  Category as CategoryIcon,
} from "@material-ui/icons";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import QuickMessageDialog from "../../components/QuickMessageDialog";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { socketConnection } from "../../services/socket";
import { AuthContext } from "../../context/Auth/AuthContext";
import CategoryDialog from "../../components/QuickMessageCategories";

const reducer = (state, action) => {
  switch (action.type) {
    case "LOAD_QUICKMESSAGES":
      return [...action.payload];

    case "UPDATE_QUICKMESSAGE":
      const updatedQuickMessage = action.payload;
      const index = state.findIndex((q) => q.id === updatedQuickMessage.id);
      if (index !== -1) {
        const newState = [...state];
        newState[index] = updatedQuickMessage;
        return newState;
      } else {
        return [updatedQuickMessage, ...state];
      }

    case "DELETE_QUICKMESSAGE":
      return state.filter((q) => q.id !== action.payload);

    case "RESET":
      return [];

    default:
      return state;
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    overflowY: "auto",
    width: "100%",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    ...theme.scrollbarStyles,
    "& h6": {
      fontFamily: "Poppins",
      color: theme.palette.primary.mainReverseByWhite
    }
  },
  categoryHeader: {
    backgroundColor: "transparent",
    cursor: "pointer",
    padding: theme.spacing(1),
    display: "flex",
    fontFamily: "Poppins",
    justifyContent: "space-between",
    alignItems: "center",
  },
  messageDetails: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1),
  },
  titlesContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200] + "70",
  },
  actions: {
    textAlign: "center",
  },
  buttonAdd: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  btnStyle: {
    width: "40px",
    height: "40px",
    minWidth: "40px",
    minHeight: "40px",
    padding: "0",
    borderRadius: '8px',
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    background: 'transparent',
    color: theme.palette.primary.mainReverseByWhite,
    "&:hover": {
      background: 'transparent',
      border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    }
  },
  editButton: {
    width: "25px",
    height: "25px",
    minWidth: "25px",
    minHeight: "25px",
    borderRadius: '8px',
    color: theme.palette.primary.mainReverseByWhite,
  },
  deleteButton: {
    width: "25px",
    height: "25px",
    borderRadius: '8px',
    minWidth: "25px",
    minHeight: "25px",
    color: theme.palette.primary.mainReverseByWhite,
  },
}));

const QuickMessages = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [quickmessages, dispatch] = useReducer(reducer, []);
  const [categories, setCategories] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState({});
  const [selectedQuickmessage, setSelectedQuickmessage] = useState(null);
  const [deletingQuickmessage, setDeletingQuickmessage] = useState(null);
  const [quickmessageModalOpen, setQuickMessageDialogOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [deletingCategory, setDeletingCategory] = useState(null);
  const [searchParam, setSearchParam] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { user } = useContext(AuthContext);

  const prevSearchParam = useRef();

  useEffect(() => {
    if (searchParam !== prevSearchParam.current) {
      const fetchQuickMessagesEF = async () => {
        setLoading(true);
        try {
          const { data } = await api.get("/quick-messages", {
            params: { searchParam },
          });
          dispatch({ type: "LOAD_QUICKMESSAGES", payload: data.records });
        } catch (err) {
          toastError(err);
        }
        setLoading(false);
      };
      fetchQuickMessagesEF();
      prevSearchParam.current = searchParam;
    }
  }, [searchParam]);

  useEffect(() => {
    fetchCategories();
  }, []);


  useEffect(() => {
    const companyId = user.companyId;
    const socket = socketConnection({ companyId, userId: user.id });

    socket.on(`company-${companyId}-quickmessage`, (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_QUICKMESSAGE", payload: data.record });
      }
      if (data.action === "delete") {
        dispatch({ type: "DELETE_QUICKMESSAGE", payload: +data.id });
      }
    });

    socket.on(`company-${companyId}-quickmessage-category`, (data) => {
      if (data.action === "create") {
        setCategories((prev) => [...prev, data.category]);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user]);

  const fetchQuickMessages = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/quick-messages", {
        params: { searchParam },
      });
      dispatch({ type: "LOAD_QUICKMESSAGES", payload: data.records });
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  };

  const fetchCategories = async () => {
    try {
      const { data } = await api.get("/quick-message-categories");
      setCategories(data);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenQuickMessageDialog = (category = null) => {
    setSelectedQuickmessage(null);
    setSelectedCategory(category);
    setQuickMessageDialogOpen(true);
  };

  const handleCloseQuickMessageDialog = () => {
    setSelectedQuickmessage(null);
    setSelectedCategory(null);
    setQuickMessageDialogOpen(false);
    fetchQuickMessages();
  };

  const handleOpenCategoryDialog = () => {
    setCategoryModalOpen(true);
  };

  const handleCloseCategoryDialog = () => {
    setCategoryModalOpen(false);
    fetchCategories();
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
    fetchQuickMessages();
  };

  const handleEditQuickMessage = (quickmessage) => {
    setSelectedQuickmessage(quickmessage);
    setQuickMessageDialogOpen(true);
  };

  const handleDeleteQuickMessage = async (quickmessageId) => {
    try {
      await api.delete(`/quick-messages/${quickmessageId}`);
      toast.success(i18n.t("quickMessages.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingQuickmessage(null);
    fetchQuickMessages();
  };

  const handleDeleteCategory = async () => {
    if (!deletingCategory) return;
    try {
      await api.delete(`/quick-message-categories/${deletingCategory.id}`);
      toast.success(i18n.t("quickMessages.toasts.categoryDeleted"));
      setDeletingCategory(null);
      fetchCategories();
    } catch (err) {
      toastError(err);
    }
  };

  const toggleCategory = (categoryId) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryId]: !prev[categoryId],
    }));
  };

  const getFilteredCategories = () => {
    if (!searchParam) return categories;

    return categories.filter((category) => {
      const hasMessages = quickmessages.some(
        (message) =>
          message.categoryId === category.id &&
          message.message.toLowerCase().includes(searchParam.toLowerCase())
      );

      return (
        category.name.toLowerCase().includes(searchParam.toLowerCase()) ||
        hasMessages
      );
    });
  };

  const renderQuickMessages = () => {
    const messagesByCategory = {};

    quickmessages.forEach((message) => {
      const categoryId = message.categoryId || "no-category";
      if (!messagesByCategory[categoryId]) {
        messagesByCategory[categoryId] = [];
      }
      messagesByCategory[categoryId].push(message);
    });

    return getFilteredCategories().map((category) => {
      const messages = messagesByCategory[category.id] || [];

      return (
        <React.Fragment key={category.id}>
          <ListItem
            className={classes.categoryHeader}
            onClick={() => toggleCategory(category.id)}
          >
            <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
              <IconButton size="small">
                {expandedCategories[category.id] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
              <Typography variant="h6" style={{ flex: 1 }}>
                {category.name}
              </Typography>
            </div>
            <IconButton
              size="small"
              className={classes.editButton}
              onClick={(e) => {
                e.stopPropagation();
                handleOpenQuickMessageDialog(category);
              }}
              style={{ marginLeft: "auto", marginRight: "0.3rem" }}
            >
              <AddIcon className={classes.editButton} />
            </IconButton>
            <IconButton
              size="small"
              className={classes.editButton}
              onClick={(e) => {
                e.stopPropagation();
                setDeletingCategory(category);
                setConfirmModalOpen(true);
              }}
            >
              <DeleteOutlineIcon />
            </IconButton>
          </ListItem>
          <Collapse in={expandedCategories[category.id]} timeout="auto" unmountOnExit>
            <div className={classes.titlesContainer}>
              <Typography variant="subtitle1" style={{ width: "20%", textAlign: "center" }}>
                {i18n.t("quickMessages.table.shortcode")}
              </Typography>
              <Typography variant="subtitle1" style={{ width: "60%", textAlign: "left" }}>
                {i18n.t("quickMessages.table.message")}
              </Typography>
              <Typography variant="subtitle1" style={{ width: "20%", textAlign: "center" }}>
                {i18n.t("quickMessages.table.actions")}
              </Typography>
            </div>
            {messages.map((quickmessage) => (
              <div key={quickmessage.id} className={classes.messageDetails}>
                <Typography
                  variant="body1"
                  style={{
                    width: "20%",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {quickmessage.shortcode}
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    width: "60%",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {quickmessage.message}
                </Typography>
                <div style={{ width: "20%", textAlign: "center" }}>
                  <IconButton
                    size="small"
                    className={classes.editButton}
                    onClick={() => handleEditQuickMessage(quickmessage)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    size="small"
                    className={classes.deleteButton}
                    onClick={() => handleDeleteQuickMessage(quickmessage.id)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </div>
              </div>

            ))}
            <Divider className={classes.divider} />
          </Collapse>
        </React.Fragment>
      );
    });
  };

  return (
    <MainContainer>
      <CategoryDialog hideTable={true} open={categoryModalOpen} onClose={handleCloseCategoryDialog} onSuccess={fetchCategories} />
      <ConfirmationModal
        title={
          deletingCategory
            ? `${i18n.t("quickMessages.confirmationModal.deleteCategoryTitle")} ${deletingCategory.name}?`
            : `${i18n.t("quickMessages.confirmationModal.deleteTitle")} ${deletingQuickmessage?.shortcode}?`
        }
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => (deletingCategory ? handleDeleteCategory() : handleDeleteQuickMessage(deletingQuickmessage.id))}
      >
        {deletingCategory
          ? i18n.t("quickMessages.confirmationModal.deleteCategoryMessage")
          : i18n.t("quickMessages.confirmationModal.deleteMessage")}
      </ConfirmationModal>

      <QuickMessageDialog
        reload={fetchQuickMessages}
        open={quickmessageModalOpen}
        hideTable={true}
        onClose={handleCloseQuickMessageDialog}
        aria-labelledby="form-dialog-title"
        quickemessageId={selectedQuickmessage && selectedQuickmessage.id}
        category={selectedCategory}
      />

      <MainHeader>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Title>{i18n.t("quickMessages.title")}</Title>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <TextField
                  className={classes.searchInput}
                  placeholder={i18n.t("quickMessages.searchPlaceholder")}
                  type="search"
                  value={searchParam}
                  onChange={handleSearch}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "gray" }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <IconButton
                  color="primary"
                  className={classes.btnStyle}
                  onClick={handleOpenCategoryDialog}
                >
                  <CategoryIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainHeader>

      <Paper className={classes.mainPaper} variant="outlined">
        {loading ? <TableRowSkeleton columns={4} /> : <List>{renderQuickMessages()}</List>}
      </Paper>
    </MainContainer>
  );
};

export default QuickMessages;
