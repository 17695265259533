import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";

import { i18n } from "../../translate/i18n";
import { makeStyles, useTheme } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  modalTitle: {
    fontFamily: "Poppins",
    color: theme.palette.primary.mainReverseByWhite,
  },
  btnStyles: {
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    color: theme.palette.primary.mainReverseByWhite,
    background: "transparent",
    "&:hover": {
      border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
      background: "transparent",
    },
  },
  noScroll: {
    overflow: "hidden", // Remove o scroll
    maxHeight: "none", // Garante que o conteúdo não seja limitado
  },
}));

const ConfirmationModal = ({
  title,
  children,
  open,
  onClose,
  onConfirm,
  confirmModalInfo,
  setUpdatedWhatsApps,
  setConfirmModalInfo,
  confirmationModalInitialState,
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [deleteConfirmation, setDeleteConfirmation] = useState("");

  const isDeleteDisabled =
    title === "Deletar" && deleteConfirmation !== "EXCLUIR";

  return (
    <Dialog
      open={open}
      PaperProps={{
        style: { border: `2px solid ${theme.palette.primary.mainReverseByWhite}70` },
      }}
      onClose={() => onClose(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog" className={classes.modalTitle}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.noScroll}>
        <Typography>{children}</Typography>
        {title === "Deletar" && (
          <TextField
            label="Digite EXCLUIR para confirmar"
            variant="outlined"
            fullWidth
			style={{marginTop: "1rem"}}
            margin="dense"
            value={deleteConfirmation}
            onChange={(e) => setDeleteConfirmation(e.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.btnStyles}
          variant="contained"
          onClick={() => onClose(false)}
          color="default"
        >
          {i18n.t("confirmationModal.buttons.cancel")}
        </Button>
        <Button
          className={classes.btnStyles}
          variant="contained"
          onClick={() => {
            onClose(false);
            onConfirm(
              confirmModalInfo,
              setUpdatedWhatsApps,
              setConfirmModalInfo,
              confirmationModalInitialState
            );
          }}
          color="secondary"
          disabled={isDeleteDisabled}
        >
          {i18n.t("confirmationModal.buttons.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
