import React from 'react';
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { CheckCircle, HourglassEmpty, AssignmentTurnedIn, Shuffle, PersonAdd } from "@material-ui/icons";
import useStyles from './style';
import Avatar from "@material-ui/core/Avatar";
import PersonIcon from "@material-ui/icons/Person";
import Tooltip from '@material-ui/core/Tooltip'; // Importando Tooltip

// Função de truncamento
const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
        return `${text.substring(0, maxLength)}...`;
    }
    return text;
};

const TimelineTicketDetails = ({ modalOpen, selectedTicket, handleCloseModal }) => {
    const classes = useStyles();

    const renderActionDetails = (actionType, ticket) => {
        const userName = ticket.user?.name || 'Sem atendente vinculado';

        switch (actionType) {
            case "Mudança de status":
                return (
                    <Typography variant="body2">
                        Status alterado para: {mapStatus(ticket.status)}
                    </Typography>
                );
            case "Finalização do ticket":
                return (
                    <Typography variant="body2">
                        Ticket finalizado em: {ticket.finishedAt || 'N/A'}
                    </Typography>
                );
            case "Início do acompanhamento":
                return (
                    <Typography variant="body2">
                        Acompanhamento iniciado em: {ticket.createdAt}
                    </Typography>
                );
            case "Troca de fila":
                return (
                    <Typography variant="body2">
                        Fila alterada para: {ticket.queueName || 'Sem fila'}
                    </Typography>
                );
            case "Troca de responsável":
                return (
                    <Typography variant="body2">
                        Responsável alterado para: {userName}
                    </Typography>
                );
            default:
                return (
                    <Typography variant="body2">
                        Responsável: {userName}
                    </Typography>
                );
        }
    };

    const mapStatus = (status) => {
        switch (status) {
            case 'open':
                return 'Em atendimento';
            case 'pending':
                return 'Aguardando';
            case 'closed':
                return 'Fechado';
            default:
                return status;
        }
    };

    const renderActionIcon = (actionType) => {
        switch (actionType) {
            case "Mudança de status":
                return <AssignmentTurnedIn className={classes.icon} style={{ color: 'blue' }} />;
            case "Finalização do ticket":
                return <CheckCircle className={classes.icon} style={{ color: 'green' }} />;
            case "Início do acompanhamento":
                return <HourglassEmpty className={classes.icon} style={{ color: 'orange' }} />;
            case "Troca de fila":
                return <Shuffle className={classes.icon} style={{ color: 'purple' }} />;
            case "Troca de responsável":
                return <PersonAdd className={classes.icon} style={{ color: 'red' }} />;
            default:
                return null;
        }
    };

    const handleClickOutside = (e) => {
        if (e.target === e.currentTarget) {
            handleCloseModal();
        }
    };

    return (
        <Fade in={modalOpen} timeout={500}>
            <Box className={classes.modalContainer} onClick={handleClickOutside}>
                <div className={classes.timelineWrapper}>
                    <h1 className={classes.title}>Timeline Contatos</h1>

                    {selectedTicket?.data?.map((ticket) => (
                        <div key={ticket.id} className={classes.timelineContainer}>
                            <div className={classes.actionType} style={{ minWidth: '300px' }}>
                                <Typography variant="subtitle1">{ticket.actionType}</Typography>
                                <Box className={classes.actionTypeUserNameWrapper}>
                                    <Avatar
                                        alt={ticket?.user?.name || "Sem atendente"}
                                        src={`${process.env.REACT_APP_BACKEND_URL}${ticket?.user?.profilePicture}`}
                                        className={classes.avatarSmall}
                                    >
                                        {!ticket?.user?.profilePicture && <PersonIcon />}
                                    </Avatar>
                                    <Tooltip title={ticket?.user?.name || "Sem atendente"} arrow>
                                        <Typography variant="span" className={classes.actionTypeUserName}>
                                            {truncateText(ticket?.user?.name || "Sem atendente", 6)}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                            </div>
                            <div className={classes.actionDetails}>
                                {renderActionDetails(ticket.actionType, ticket)}
                            </div>
                            <div>
                                {renderActionIcon(ticket.actionType)} {/* Exibe o ícone de acordo com o actionType */}
                                <div className={classes.actionLine}></div> {/* Linha agora fica entre o actionType e a alteração */}
                            </div>
                        </div>
                    ))}
                </div>
            </Box>
        </Fade>
    );
};

export default TimelineTicketDetails;
