
import {
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    color: theme.palette.primary.mainReverseByWhite,
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    ...theme.scrollbarStyles,
    "& h4": {
      fontFamily: "Poppins",
      color: theme.palette.primary.mainReverseByWhite,
      fontWeight: '500 !important',
      fontSize: "16px"
    },

    "& h6": {
      fontFamily: "Poppins",
      color: theme.palette.primary.mainReverseByWhite,
      fontWeight: '500 !important',
      fontSize: "14px"
    },

    "& svg": {
      color: theme.palette.primary.mainReverseByWhite,
    },

    "& button": {
      color: theme.palette.primary.mainReverseByWhite,
      fontWeigth: "500",
      cursor: "pointer",
      background: "transparent",
      border: `2px solid ${theme.palette.primary.mainReverseByWhite}70 !important`,
      borderRadius: "8px",
      "&:hover": {
        background: "transparent",
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70 !important`,
        borderRadius: "8px",
      }
    },
  },
}));

export default useStyles;