import logoConnect from "../logo-signed-flat.png";
import logoP from "../new-logo-cut.png";
import logo from "../logo.png";

const themeBigLogo = {
    "tarifando": logo,
    "purple": logoP,
    "connect": logoConnect
};

export default themeBigLogo