import React from "react";

import {
    Button,
    Grid,
} from "@material-ui/core";
import ChatMessages from "../../ChatMessages";
import { has, isObject } from "lodash";

import themeBigLogo from "../../../../assets/envAssets/bigLogo";

const MessagesContainer = ({
    classes,
    loading,
    showMobileChat,
    setMobileChat,
    currentChat,
    scrollToBottomRef,
    messagesPageInfo,
    messages,
    sendMessage,
    loadMoreMessages
}) => {
    return (
        <Grid style={{ justifyContent: "center" }} className={`${classes.gridItem} ${showMobileChat ? "cmp-internchat--open" : "cmp-internchat--mobile"}`}>
            <Button className={`cmp-internchat__button ${classes.btnStyles}`} onClick={() => setMobileChat(false)}>
                X
            </Button>
            {isObject(currentChat) && has(currentChat, "id") ? (
                <ChatMessages
                    chat={currentChat}
                    scrollToBottomRef={scrollToBottomRef}
                    pageInfo={messagesPageInfo}
                    messages={messages}
                    loading={loading}
                    handleSendMessage={sendMessage}
                    handleLoadMore={loadMoreMessages}
                />
            ) : (
                <div>
                    <center><img style={{ margin: "auto", width: "auto", maxHeight: "90px" }} src={themeBigLogo[process.env.REACT_APP_THEME_LOGO]} alt="logologin" /></center>
                </div>
            )}

        </Grid>
    );
};

export default MessagesContainer;
