import React from "react";
import { Field } from "formik";
import { TextField } from "@material-ui/core";
import { i18n } from "../../../../translate/i18n";

const AutoInputMessages = ({
  isSocial,
  touched,
  errors,
  classes
}) => {
  return (
    <div className={classes.switchLabels}>
      {!isSocial && (
        <div>
          <Field
            as={TextField}
            label={i18n.t("queueModal.form.greetingMessage")}
            type="greetingMessage"
            multiline
            rows={4}
            fullWidth
            name="greetingMessage"
            error={touched.greetingMessage && Boolean(errors.greetingMessage)}
            helperText={touched.greetingMessage && errors.greetingMessage}
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true, // Mantém o label acima do campo
            }}
            InputProps={{
              style: {
                borderColor: touched.greetingMessage && errors.greetingMessage ? 'red' : 'blue', // Exemplo de cor de borda
                // Adicione mais estilos conforme necessário
              },
            }}
            // Opção para mudar a cor da borda quando o campo é focado
            onFocus={(e) => {
              e.target.style.borderColor = 'blue'; // Muda a borda para azul ao focar
            }}
            onBlur={(e) => {
              e.target.style.borderColor = touched.greetingMessage && errors.greetingMessage ? 'red' : 'transparent'; // Restaura a borda ao desfocar
            }}
          />
        </div>
      )}
      {!isSocial && (
        <div>
          <Field
            as={TextField}
            label={i18n.t("queueModal.form.complationMessage")}
            type="complationMessage"
            multiline
            rows={4}
            fullWidth
            name="complationMessage"
            error={touched.complationMessage && Boolean(errors.complationMessage)}
            helperText={touched.complationMessage && errors.complationMessage}
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true, // Mantém o label acima do campo
            }}
            InputProps={{
              style: {
                borderColor: touched.complationMessage && errors.complationMessage ? 'red' : 'blue', // Exemplo de cor de borda
              },
            }}
            onFocus={(e) => {
              e.target.style.borderColor = 'blue'; // Muda a borda para azul ao focar
            }}
            onBlur={(e) => {
              e.target.style.borderColor = touched.complationMessage && errors.complationMessage ? 'red' : 'transparent'; // Restaura a borda ao desfocar
            }}
          />
        </div>
      )}
      {!isSocial && (
        <div>
          <Field
            as={TextField}
            label={i18n.t("queueModal.form.outOfHoursMessage")}
            type="outOfHoursMessage"
            multiline
            rows={4}
            fullWidth
            name="outOfHoursMessage"
            error={touched.outOfHoursMessage && Boolean(errors.outOfHoursMessage)}
            helperText={touched.outOfHoursMessage && errors.outOfHoursMessage}
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true, // Mantém o label acima do campo
            }}
            InputProps={{
              style: {
                borderColor: touched.outOfHoursMessage && errors.outOfHoursMessage ? 'red' : 'blue', // Exemplo de cor de borda
              },
            }}
            onFocus={(e) => {
              e.target.style.borderColor = 'blue'; // Muda a borda para azul ao focar
            }}
            onBlur={(e) => {
              e.target.style.borderColor = touched.outOfHoursMessage && errors.outOfHoursMessage ? 'red' : 'transparent'; // Restaura a borda ao desfocar
            }}
          />
        </div>
      )}
      {!isSocial && (
        <div>
          <Field
            as={TextField}
            label={i18n.t("queueModal.form.ratingMessage")}
            type="ratingMessage"
            multiline
            rows={4}
            fullWidth
            name="ratingMessage"
            error={touched.ratingMessage && Boolean(errors.ratingMessage)}
            helperText={touched.ratingMessage && errors.ratingMessage}
            variant="outlined"
            margin="dense"
            InputLabelProps={{
              shrink: true, // Mantém o label acima do campo
            }}
            InputProps={{
              style: {
                borderColor: touched.ratingMessage && errors.ratingMessage ? 'red' : 'blue', // Exemplo de cor de borda
              },
            }}
            onFocus={(e) => {
              e.target.style.borderColor = 'blue'; // Muda a borda para azul ao focar
            }}
            onBlur={(e) => {
              e.target.style.borderColor = touched.ratingMessage && errors.ratingMessage ? 'red' : 'transparent'; // Restaura a borda ao desfocar
            }}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(AutoInputMessages);
