import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    borderRadius: "8px",
    minGeight: "calc(100% - 6rem)",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  title: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    color: theme.palette.primary.mainReverseByWhite,
  },
  searchBar: {
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "4px",
    outline: "none",
    fontFamily: "Poppins",
    fontSize: "1rem",
  },
  contactsList: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  contactItem: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.5),
    borderRadius: "8px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    transition: "background-color 0.3s",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selectedContact: {
    backgroundColor: theme.palette.action.selected,
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    display: "flex",
    borderRadius: "8px",
    padding: "1rem",
    marginBottom: "1rem"
  },
  avatarContainer: {
    marginRight: theme.spacing(2),
  },
  avatar: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
  },
  defaultAvatar: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.text.secondary,
  },
  contactInfo: {
    display: "flex",
    flexDirection: "column",
  },
  contactNameAndActivities: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  contactName: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "1.1rem",
    color: theme.palette.text.primary,
    marginBottom: "4px",
  },
  activitiesCount: {
    fontFamily: "Poppins",
    fontSize: "0.9rem",
    color: theme.palette.text.secondary,
  },
  noContactsMessage: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    fontFamily: "Poppins",
    fontSize: "1rem",
    color: theme.palette.text.secondary,
  },
  contactNameAndActivitiesWrapper: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
  },
  activitiesWrapper: {
    marginLeft: theme.spacing(1),
  },

  searchBarContainer: {
    position: "relative"
  },

  dropdown: {
    position: "absolute",
    width: "100%"
  },

  actionTypeUserName: {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "1rem",
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;
