import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { useDate } from "../../hooks/useDate";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    overflowY: "scroll",
    ...theme.scrollbarStyles,

    "& > div": { // Adicionando o operador '>' para aplicar somente a divs filhas diretas
      width: "100%"
    }
  },
  previewTable: {
    position: "relative",
    background: "transparent",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    borderRadius: "8px",
    display: "flex",
    gap: "1rem",
    flexWrap: "wrap",
  },

  headerTexts: {
    color: theme.palette.primary.mainReverseByWhite,
    fontFamily: "Poppins",
    fontWeight: "600"
  }
}));

const PreviewList = () => {
  const classes = useStyles();
  const { datetimeToClient } = useDate();
  const [loading, setLoading] = useState(false);
  const [previews, setPreviews] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  useEffect(() => {
    const fetchPreviews = async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/schedules-preview");
        setPreviews(data);
      } catch (err) {
        toastError(err);
      } finally {
        setLoading(false);
      }
    };
  
    fetchPreviews();
  }, []);

  // Função para abrir o modal de confirmação
  const handleOpenModal = (id) => {
    setSelectedId(id);
    setOpenModal(true);
  };

  // Função para fechar o modal de confirmação
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedId(null);
  };

  // Função para excluir agendamento
  const handleDelete = async () => {
    try {
      await api.delete(`/schedules-preview/${selectedId}`);
      setPreviews((prevPreviews) => prevPreviews.filter((preview) => preview.id !== selectedId));
      handleCloseModal();
    } catch (error) {
      toastError(error);
    }
  };

  return (
    <Paper className={`${classes.mainPaper} ${classes.previewTable}`}>
      <Table style={{width: "100% !imp"}}>
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.headerTexts}`}align="center">Lista de Contatos</TableCell>
            <TableCell className={`${classes.headerTexts}`} align="center">WhatsApp</TableCell>
            <TableCell className={`${classes.headerTexts}`} align="center">Fila</TableCell>
            <TableCell className={`${classes.headerTexts}`} align="center">Concluído</TableCell>
            <TableCell className={`${classes.headerTexts}`} align="center">Agendamento</TableCell>
            <TableCell className={`${classes.headerTexts}`} align="center">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {previews.map((preview) => (
            <TableRow key={preview.id}>
              <TableCell align="center">{preview.contactList?.name || "N/A"}</TableCell>
              <TableCell align="center">{preview.whatsapp?.name || "N/A"}</TableCell>
              <TableCell align="center">{preview.queue?.name || "N/A"}</TableCell>
              <TableCell align="center">{preview.isCompleted ? "Sim" : "Não"}</TableCell>
              <TableCell align="center">{datetimeToClient(preview.scheduledAt) || "Sem agendamento"}</TableCell>
              <TableCell align="center">
                <IconButton size="small" onClick={() => handleOpenModal(preview.id)}>
                  <DeleteOutlineIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {loading && <TableRowSkeleton columns={6} />}
        </TableBody>
      </Table>

      {/* Modal de Confirmação */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Confirmar Exclusão</DialogTitle>
        <DialogContent>
          <DialogContentText>Você tem certeza que deseja excluir este agendamento?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default PreviewList;
