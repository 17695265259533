import React, { createContext } from "react";
import { useWavoip } from "../../hooks/useWavoip";

export const WavoipContext = createContext({
    whatsappInstance: null,
    isCalling: false,
    callDuration: 0,
    audioRef: null,
    handleWavoipCall: () => {},
    handleEndCall: () => {},
    formatPhoneNumber: () => {},
    acceptCall: () => {},
    setWavoipUser: () => {},
    rejectCall: () => {},
    wavoipActiveContact: null,
    setActiveTicket: () => {},
    isOpen: {},
    setIsOpen: () => {},
    activeTicket: {},
    setIsActive: () => {},
    phoneNumber: "",
    setPhoneNumber: () => {},
    exportMsgType: {},
    setExportMsgType: () => {},
    callStatus: {},
    mute: () => {},
    unMute: () => {},
    isMuted: false,
    setIsMuted: ( ) => {}
});

export const WavoipProvider = ({ children }) => {
    const {
        whatsappInstance,
        isCalling,
        callReceived,
        callDuration,
        audioRef,
        handleWavoipCall,
        acceptCall,
        handleEndCall,
        formatPhoneNumber,
        setWavoipUser,
        rejectCall,
        wavoipActiveContact,
        setActiveTicket,
        isOpen,
        setIsOpen,
        activeTicket,
        setIsActive,
        isActive,
        setPhoneNumber,
        phoneNumber,
        exportMsgType,
        setExportMsgType,
        callStatus,
        mute,
        unMute,
        isMuted,
        setIsMuted
    } = useWavoip();

    return (
        <WavoipContext.Provider value={{
            whatsappInstance,
            isCalling,
            callReceived,
            callDuration,
            audioRef,
            handleWavoipCall,
            acceptCall,
            handleEndCall,
            setWavoipUser,
            rejectCall,
            formatPhoneNumber,
            setActiveTicket,
            wavoipActiveContact,
            isOpen,
            setIsOpen,
            activeTicket,
            setIsActive,
            isActive,
            setPhoneNumber,
            phoneNumber,
            exportMsgType,
            setExportMsgType,
            callStatus,
            mute,
            unMute,
            isMuted,
            setIsMuted
        }}>
            {children}
        </WavoipContext.Provider>
    );
};
