import React, { useCallback, useEffect } from "react";
import { Field } from "formik";
import {
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import { i18n } from "../../../../translate/i18n";

const BotConfigs = ({ queues, touched, errors, classes, whatsApp, setSendQueueId, sendQueueId }) => {
    const memoizedSetSendQueueId = useCallback((value) => {
        setSendQueueId(value);
    }, [setSendQueueId]);

    useEffect(() => {
        const initialSendIdQueueValue = whatsApp && whatsApp.sendIdQueue ? whatsApp.sendIdQueue : 0;
        memoizedSetSendQueueId(initialSendIdQueueValue);
    }, [whatsApp, memoizedSetSendQueueId]);

    const handleChange = (event) => {
        memoizedSetSendQueueId(event.target.value);
    };

    return (
        <div className={classes.switchLabels}>
            <p>{i18n.t("whatsappModal.form.queueRedirectionDesc")}</p>
            <Grid spacing={2} container>
                <Grid xs={6} md={6} item>
                    <FormControl
                        variant="outlined"
                        margin="dense"
                        fullWidth
                    >
                        <InputLabel id="sendIdQueue-selection-label">
                            {i18n.t("whatsappModal.form.sendIdQueue")}
                        </InputLabel>
                        <Field
                            as={Select}
                            name="sendIdQueue"
                            id="sendIdQueue"
                            label={i18n.t("whatsappModal.form.sendIdQueue")}
                            labelId="sendIdQueue-selection-label"
                            InputLabelProps={{ shrink: true }}
                            value={sendQueueId}
                            onChange={handleChange} // Adicione esta linha
                        >
                            <MenuItem value={0}>&nbsp;</MenuItem>
                            {queues.map(queue => (
                                <MenuItem key={queue.id} value={queue.id}>
                                    {queue.name}
                                </MenuItem>
                            ))}
                        </Field>
                    </FormControl>
                </Grid>
                <Grid xs={6} md={6} item>
                    <Field
                        as={TextField}
                        label={"Redirecionar em X minutos"}
                        fullWidth
                        name="timeSendQueue"
                        variant="outlined"
                        margin="dense"
                        error={touched.timeSendQueue && Boolean(errors.timeSendQueue)}
                        helperText={touched.timeSendQueue && errors.timeSendQueue}
                        InputLabelProps={{ shrink: true }} // Forçar a label a permanecer ativa
                    />
                </Grid>
            </Grid>
            {/* <Grid spacing={2} container>
                <Grid xs={12} md={6} item>
                    <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.maxUseBotQueues")}
                        fullWidth
                        name="maxUseBotQueues"
                        variant="outlined"
                        margin="dense"
                        error={touched.maxUseBotQueues && Boolean(errors.maxUseBotQueues)}
                        helperText={touched.maxUseBotQueues && errors.maxUseBotQueues}
                        InputLabelProps={{ shrink: true }} // Forçar a label a permanecer ativa
                    />
                </Grid>
                <Grid xs={12} md={6} item>
                    <Field
                        as={TextField}
                        label={i18n.t("whatsappModal.form.timeUseBotQueues")}
                        fullWidth
                        name="timeUseBotQueues"
                        variant="outlined"
                        margin="dense"
                        error={touched.timeUseBotQueues && Boolean(errors.timeUseBotQueues)}
                        helperText={touched.timeUseBotQueues && errors.timeUseBotQueues}
                        InputLabelProps={{ shrink: true }} // Forçar a label a permanecer ativa
                    />
                </Grid>
            </Grid> */}
            {/* <Grid spacing={2} container>
                <Grid xs={12} md={12} item>
                    <Field
                        as={TextField}
                        label={"X minutos entre cada envio"}
                        fullWidth
                        name="expiresTicket"
                        variant="outlined"
                        margin="dense"
                        error={touched.expiresTicket && Boolean(errors.expiresTicket)}
                        helperText={touched.expiresTicket && errors.expiresTicket}
                        InputLabelProps={{ shrink: true }} // Forçar a label a permanecer ativa
                    />
                </Grid>
            </Grid> */}
            <div>
                <Field
                    as={TextField}
                    label={i18n.t("whatsappModal.form.expiresInactiveMessage")}
                    multiline
                    rows={4}
                    fullWidth
                    name="expiresInactiveMessage"
                    error={touched.expiresInactiveMessage && Boolean(errors.expiresInactiveMessage)}
                    helperText={touched.expiresInactiveMessage && errors.expiresInactiveMessage}
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{ shrink: true }} // Forçar a label a permanecer ativa
                />
            </div>
        </div>
    );
};

export default React.memo(BotConfigs);
