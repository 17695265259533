import React from "react";
import { AccessTime, ConfirmationNumber, Block, Cancel } from "@material-ui/icons";
import TMaxAttendants from "../TMax";
import DashboardItem from "../DashboardItem";

const formatTime = (timeStr) => {
  const [hours, minutes] = timeStr.match(/\d+/g).map(Number);
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  return formattedTime;
};

const DashboardSubheader = ({ attendants, counters, classes, reportCount }) => {
  return (
    <div className="cmp-dashboard__raw-data">
      <TMaxAttendants
        classes={classes}
        attendants={attendants}
        time={formatTime(reportCount?.maiorTempoAtendimento || "0h 0m 0s")}
      />

      {/* Tempo máximo na fila formatado */}
      <DashboardItem
        title="T.Max Fila"
        icon={AccessTime}
        value={formatTime(reportCount?.maiorTempoNaFila || "0h 0m 0s")}
        classes={classes}
      />

      <DashboardItem
        title="Total Tickets"
        icon={ConfirmationNumber}
        value={reportCount?.totalTickets ? reportCount?.totalTickets : 0}
        classes={classes}
      />

      <DashboardItem
        title="Abandonadas"
        icon={Cancel}
        value={counters?.abandonedByClient || 0}
        classes={classes}
      />

      <DashboardItem
        title="Bloqueadas"
        icon={Block}
        value={reportCount?.totalBlockedContacts || 0}
        classes={classes}
      />
    </div>
  );
};

export default DashboardSubheader;
