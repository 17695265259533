import React, { useState, useEffect } from "react";
import { Switch, CircularProgress, Typography, makeStyles } from "@material-ui/core";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1rem 0",
        borderTop: `1px solid ${theme.palette.primary.mainReverseByWhite}70`
    },
    title: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: "bold",
        color: `${theme.palette.primary.mainReverseByWhite}`,
        display: "flex",
        alignItems: "center",
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    switchContainer: {
        display: "flex",
        alignItems: "center",
    },
    statusText: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: "500",
    },
}));

const BotSwitch = ({ contactId, initialDisableBot, contact }) => {
    const [disableBot, setDisableBot] = useState(initialDisableBot);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    useEffect(() => {
        setDisableBot(initialDisableBot); // Atualiza quando o valor inicial muda
    }, [initialDisableBot]);

    const handleToggle = async () => {
        setDisableBot(!disableBot);
        setLoading(true);
        try {
            await api.put(`/contacts/${contactId}`, { ...contact, disableBot: !disableBot });
        } catch (err) {
            toastError(err);
            setDisableBot(disableBot); // Volta ao estado anterior se a requisição falhar
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.container}>
            <h3 className={classes.title}>ChatBot</h3>
            <Switch
                size="small"
                checked={!disableBot}
                onChange={handleToggle}
                disabled={loading}
            />
            {loading && <CircularProgress size={24} />}
            <Typography variant="subtitle1">
                {disableBot
                    ? "Off"
                    : "On"}
            </Typography>
        </div>
    );
};

export default BotSwitch;
