import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DonutChart = ({ pendent, done, ongoing, classes, fontColor }) => {

  const data = {
    labels: ['Pendentes', 'Realizados', 'Em Andamento'],
    datasets: [
      {
        data: [pendent, done, ongoing],
        backgroundColor: [
          '#FF4500', // Laranja quase vermelho para pendentes
          'rgba(54, 162, 235, 0.2)', // Azul claro transparente para realizados
          '#FFA500', // Laranja amarelado para em andamento
        ],
        hoverBackgroundColor: [
          '#E63900', // Laranja mais escuro no hover para pendentes
          'rgba(54, 162, 235, 0.5)', // Azul mais forte no hover para realizados
          '#FF8C00', // Laranja mais escuro no hover para em andamento
        ],
        borderWidth: 1, // Adiciona borda para melhor contraste
        borderColor: '#ffffff', // Borda branca para destacar as fatias
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '50%', // Garante que o gráfico seja de estilo donut
    legend: {
      labels: {
          fontColor: "black"
      }
    },
    plugins: {
      tooltip: {
        enabled: false, // Desabilita as tooltips, se necessário
      },
    },
  };

  return (
    <div className={classes.graphsBox} style={{ width: '100%', height: '252px', display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '1rem' }}>
      <label>Atendimentos</label>
      <div style={{ width: '100%', height: '252px' }}>
        <Doughnut data={data} options={options} />
      </div>
    </div>
  );
};

export default DonutChart;
