import React, { useState } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box
} from '@material-ui/core';

const ParticipantsTable = ({ participants }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (participants.length === 0) {
    return <Typography>Nenhum participante encontrado.</Typography>;
  }

  const formatPhoneNumber = (id) => id.replace('@s.whatsapp.net', '');

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Número</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell align="right">Admin Status</TableCell>
              <TableCell align="right">Nome do Grupo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((participant, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {formatPhoneNumber(participant.id)}
                </TableCell>
                <TableCell>
                  {participant.name || 'Desconhecido'}
                </TableCell>
                <TableCell align="right">
                  {participant.isAdmin === 'superadmin'
                    ? 'Super Admin'
                    : participant.isAdmin === 'admin'
                    ? 'Admin'
                    : 'Não'}
                </TableCell>
                <TableCell align="right">
                  {participant.groupName}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={2}>
        <TablePagination
          component="div"
          count={participants.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
        />
      </Box>
    </>
  );
};

export default ParticipantsTable;
