import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },

  multFieldLine: {
    display: "flex",
    flexDirection: "column",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },

  btnWrapper: {
    position: "relative",
    borderRadius: "8px",
    background: "transparent",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    color: theme.palette.primary.mainReverseByWhite,
    margin: "0 1rem",
    "& :hover": {
      background: "transparent",
      color: theme.palette.primary.mainReverseByWhite,
    }
  },

  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },

  titleStyles: {
    color: theme.palette.primary.mainReverseByWhite,
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "16px"
  },

  closeBtnStyles: {
    position: "absolute",
    top: "1rem",
    right: "1.5rem",
    padding: "0",
    borderRadius: "4px",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    color: theme.palette.primary.mainReverseByWhite,
  },
  switchLabels: {

    borderRadius: "8px",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    padding: "1rem",
    margin: "1rem 0",
    "& span ": {
      fontFamily: "Poppins",
      fontWeight: "500",
      color: theme.palette.primary.mainReverseByWhite,
    },

    "& .cmp-default ": {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      color: theme.palette.primary.mainReverseByWhite,
    },

    "& .cmp-default label ": {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      color: `${theme.palette.primary.mainReverseByWhite} !important`,
    },
  },
  spanColumn: {
    display: "flex",
    margin: "1rem auto",
    maxWidth: "150px",
    alignItems: "center",
    gap: "8px",
    "& span": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "0.5rem"
    }
  }
}));

export default useStyles;
