import React from "react";
import { toast } from "react-toastify";
import api from "../../../services/api";
import toastError from "../../../errors/toastError";
import { i18n } from "../../../translate/i18n";

export const handleRequestNewQrCode = async (whatsAppId, setUpdatedWhatsApps) => {
    try {
        setUpdatedWhatsApps(prevState =>
            prevState.map(item =>
                item.id === whatsAppId ? { ...item, status: "qrcode" } : item
            )
        );
        await api.put(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
        toastError(err);
    }
};

export const handleOpenFacebookAppModal = (setWhatsAppModalOpen, setModalType) => {
    setModalType("facebook");
    setWhatsAppModalOpen(true);
};

export const handleOpenInstagramAppModal = (setWhatsAppModalOpen, setModalType) => {
    setModalType("instagram");
    setWhatsAppModalOpen(true);
};

export const handleOpenWhatsAppModal = (setWhatsAppModalOpen, setModalType) => {
    setModalType("whatsapp");
    setWhatsAppModalOpen(true);
};

export const handleOpenWhatsAppModalOfficial = (setWhatsAppModalOpen, setModalType) => {
    setModalType("official");
    setWhatsAppModalOpen(true);
};

// Novo handle para Facebook Oficial
export const handleOpenFacebookModalOfficial = (setWhatsAppModalOpen, setModalType) => {
    setModalType("facebookOfficial");
    setWhatsAppModalOpen(true);
};

// Novo handle para Instagram Oficial
export const handleOpenInstagramModalOfficial = (setWhatsAppModalOpen, setModalType) => {
    setModalType("instagramOfficial");
    setWhatsAppModalOpen(true);
};

export const handleCloseWhatsAppModal = (setWhatsAppModalOpen, setSelectedWhatsApp) => {
    setWhatsAppModalOpen(false);
    setSelectedWhatsApp(null);
};

export const handleOpenQrModal = (whatsApp, setSelectedWhatsApp, setQrModalOpen) => {
    setSelectedWhatsApp(whatsApp);
    setQrModalOpen(true);
};

export const handleCloseQrModal = (setQrModalOpen, setSelectedWhatsApp) => {
    setSelectedWhatsApp(null);
    setQrModalOpen(false);
};

export const handleEditWhatsApp = (whatsApp, modalType, setSelectedWhatsApp, setModalType, setWhatsAppModalOpen) => {
    setSelectedWhatsApp(whatsApp);
    setModalType(modalType || "whatsapp");
    setWhatsAppModalOpen(true);
};

export const handleOpenConfirmationModal = (action, whatsAppId, setConfirmModalInfo, setConfirmModalOpen) => {
    const titlesAndMessages = {
        disconnect: {
            title: i18n.t("connections.confirmationModal.disconnectTitle"),
            message: i18n.t("connections.confirmationModal.disconnectMessage"),
        },
        delete: {
            title: i18n.t("connections.confirmationModal.deleteTitle"),
            message: (
                <>
                  {i18n.t("connections.confirmationModal.deleteMessage")}{" "}
                  <strong>Irá deletar TODOS os contatos relativos à conexão!</strong>
                </>
              ),
        },
        closedImported: {
            title: i18n.t("connections.confirmationModal.closedImportedTitle"),
            message: i18n.t("connections.confirmationModal.closedImportedMessage"),
        }
    };

    if (titlesAndMessages[action]) {
        setConfirmModalInfo({
            action,
            title: titlesAndMessages[action].title,
            message: titlesAndMessages[action].message,
            whatsAppId,
        });
        setConfirmModalOpen(true);
    }
};

export const handleSubmitConfirmationModal = async (confirmModalInfo, setUpdatedWhatsApps, setConfirmModalInfo, confirmationModalInitialState) => {
    try {
        if (confirmModalInfo.action === "disconnect") {
            await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
            setUpdatedWhatsApps(prevState =>
                prevState.map(item =>
                    item.id === confirmModalInfo.whatsAppId ? { ...item, status: "DISCONNECTED" } : item
                )
            );
        }

        if (confirmModalInfo.action === "delete") {
            await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
            toast.success(i18n.t("connections.toasts.deleted"));
            setUpdatedWhatsApps(prevState =>
                prevState.filter(item => item.id !== confirmModalInfo.whatsAppId)
            );
        }

        if (confirmModalInfo.action === "closedImported") {
            await api.post(`/closedimported/${confirmModalInfo.whatsAppId}`);
            toast.success(i18n.t("connections.toasts.closedimported"));
        }
    } catch (err) {
        toastError(err);
    }

    setConfirmModalInfo(confirmationModalInitialState);
};
