const SECRET_KEY = process.env.REACT_APP_SECRET_KEY || "SUA PALAVRA SECRETA";
const CHARACTERES_BEHIND = parseInt(process.env.REACT_APP_SECRET_COUNT_BEHIND, 10) || 16;
const CHARACTERES_AFTER = parseInt(process.env.REACT_APP_SECRET_COUNT_AFTER, 10) || 24;

const getRandomCharacters = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomChars = '';
  for (let i = 0; i < length; i++) {
    randomChars += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return randomChars;
};

const encryptPassword = (password) => {
  const randomPrefix = getRandomCharacters(CHARACTERES_BEHIND);
  const randomSuffix = getRandomCharacters(CHARACTERES_AFTER);
  const passwordWithRandomChars = randomPrefix + password + randomSuffix;

  return passwordWithRandomChars
    .split('')
    .map((char, index) => 
      String.fromCharCode(char.charCodeAt(0) + SECRET_KEY.charCodeAt(index % SECRET_KEY.length))
    )
    .join('');
};

const decryptPassword = (encryptedPassword) => {
  const decryptedPassword = encryptedPassword
    .split('')
    .map((char, index) => 
      String.fromCharCode(char.charCodeAt(0) - SECRET_KEY.charCodeAt(index % SECRET_KEY.length))
    )
    .join('');

  const strippedPassword = decryptedPassword.slice(CHARACTERES_BEHIND, -CHARACTERES_AFTER);
  return strippedPassword;
};

const savePasswordToLocalStorage = (password) => {
  const encryptedPassword = encryptPassword(password);
  localStorage.setItem('user_launch_key', encryptedPassword);
};

const getPasswordFromLocalStorage = () => {
  const encryptedPassword = localStorage.getItem('user_launch_key');
  if (encryptedPassword) {
    return decryptPassword(encryptedPassword);
  }
  return null;
};

const getEmailFromLocalStorage = () => {
  return localStorage.getItem('user_email') || "";
};

export {
    encryptPassword,
    decryptPassword,
    savePasswordToLocalStorage,
    getPasswordFromLocalStorage,
    getEmailFromLocalStorage
};
