import React from "react";
import {
    Paper,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
} from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { i18n } from "../../../../translate/i18n";
import useStyles from "../../style";

export function PlansManagerGrid(props) {
    const { records, onSelect, setOpenDialog } = props;
    const classes = useStyles();

    const renderCampaigns = (row) => {
        return row.useCampaigns === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderSchedules = (row) => {
        return row.useSchedules === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderInternalChat = (row) => {
        return row.useInternalChat === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderExternalApi = (row) => {
        return row.useExternalApi === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderKanban = (row) => {
        return row.useKanban === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderOpenAi = (row) => {
        return row.useOpenAi === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    const renderIntegrations = (row) => {
        return row.useIntegrations === false ? `${i18n.t("plans.form.no")}` : `${i18n.t("plans.form.yes")}`;
    };

    return (
        <Paper className={classes.tableContainer}>
            <Table
                className={classes.fullWidth}
                padding="none"
                aria-label="a dense table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell className={`${classes.headerTexts}`} align="center" style={{ width: '5%', padding: '10px' }}>#</TableCell>
                        <TableCell className={`${classes.headerTexts}`} align="left" style={{ padding: '10px' }}>{i18n.t("plans.form.name")}</TableCell>
                        <TableCell className={`${classes.headerTexts}`} align="center" style={{ padding: '10px' }}>{i18n.t("plans.form.public")}</TableCell>
                        <TableCell className={`${classes.headerTexts}`} align="center" style={{ padding: '10px' }}>{i18n.t("plans.form.users")}</TableCell>
                        <TableCell className={`${classes.headerTexts}`} align="center" style={{ padding: '10px' }}>{i18n.t("plans.form.connections")}</TableCell>
                        <TableCell className={`${classes.headerTexts}`} align="center" style={{ padding: '10px' }}>Filas</TableCell>
                        <TableCell className={`${classes.headerTexts}`} align="center" style={{ padding: '10px' }}>{i18n.t("plans.form.messages")}</TableCell>
                        <TableCell className={`${classes.headerTexts}`} align="center" style={{ padding: '10px' }}>Valor</TableCell>
                        <TableCell className={`${classes.headerTexts}`} align="center" style={{ padding: '10px' }}>{i18n.t("plans.form.campaigns")}</TableCell>
                        <TableCell className={`${classes.headerTexts}`} align="center" style={{ padding: '10px' }}>{i18n.t("plans.form.schedules")}</TableCell>
                        <TableCell className={`${classes.headerTexts}`} align="center" style={{ padding: '10px' }}>Chat Interno</TableCell>
                        <TableCell className={`${classes.headerTexts}`} align="center" style={{ padding: '10px' }}>API Externa</TableCell>
                        <TableCell className={`${classes.headerTexts}`} align="center" style={{ padding: '10px' }}>Kanban</TableCell>
                        <TableCell className={`${classes.headerTexts}`} align="center" style={{ padding: '10px' }}>Open.Ai</TableCell>
                        <TableCell className={`${classes.headerTexts}`} align="center" style={{ padding: '10px' }}>Integrações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {records.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell align="center" style={{ padding: '10px' }}>
                                <IconButton onClick={() => {
                                    setOpenDialog(true);
                                    onSelect(row);
                                }} aria-label="delete">
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell align="left" style={{ padding: '10px' }}>{row.name || '-'}</TableCell>
                            <TableCell align="center" style={{ padding: '10px' }}>{row.isPublic ? "Sim" : "Não" || '-'}</TableCell>
                            <TableCell align="center" style={{ padding: '10px' }}>{row.users || '-'}</TableCell>
                            <TableCell align="center" style={{ padding: '10px' }}>{row.connections || '-'}</TableCell>
                            <TableCell align="center" style={{ padding: '10px' }}>{row.queues || '-'}</TableCell>
                            <TableCell align="center" style={{ padding: '10px' }}>{i18n.t("plans.form.money")} {row.messages ? row.messages.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '00.00'}</TableCell>
                            <TableCell align="center" style={{ padding: '10px' }}>{i18n.t("plans.form.money")} {row.value ? row.value.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '00.00'}</TableCell>
                            <TableCell align="center" style={{ padding: '10px' }}>{renderCampaigns(row)}</TableCell>
                            <TableCell align="center" style={{ padding: '10px' }}>{renderSchedules(row)}</TableCell>
                            <TableCell align="center" style={{ padding: '10px' }}>{renderInternalChat(row)}</TableCell>
                            <TableCell align="center" style={{ padding: '10px' }}>{renderExternalApi(row)}</TableCell>
                            <TableCell align="center" style={{ padding: '10px' }}>{renderKanban(row)}</TableCell>
                            <TableCell align="center" style={{ padding: '10px' }}>{renderOpenAi(row)}</TableCell>
                            <TableCell align="center" style={{ padding: '10px' }}>{renderIntegrations(row)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}
