import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  TextField,
  Checkbox,
  makeStyles,
  useTheme
} from "@material-ui/core";
import { toast } from "react-toastify";
import moment from "moment";
import useWhatsApps from "../../hooks/useWhatsApps";
import useContactLists from "../../hooks/useContactLists";
import useQueues from "../../hooks/useQueues";
import api from "../../services/api";
import PersonIcon from "@material-ui/icons/Person";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import "./style.css";

const useStyles = makeStyles((theme) => ({
  iconColor: {
    color: theme.palette.primary.mainReverseByWhite,
  },
  conflictItem: {
    display: "flex",
    alignItems: "center",
    gap: "1rem"
  },
  queueWrapper: {
    display: "flex",
    alignItems: "center"
  },
  currentQueue: {
    border: "2px solid #00acc170", // Borda azul para a fila atual
    borderRadius: '4px',
    padding: "0.1rem",
  },
  newQueue: {
    borderRadius: '4px',
    padding: "0.1rem",
    border: "2px solid #fb8c0080", // Borda laranja para a nova fila
  },
  scrollBar: {
    ...theme.scrollbarStyles,
  }
}));

const PreviewModal = ({ isOpen, onClose, onConfirm, onUpdate }) => {
	const theme = useTheme();
  const classes = useStyles(theme);
  const [selectedConnection, setSelectedConnection] = useState("");
  const [selectedContactList, setSelectedContactList] = useState("");
  const [selectedQueue, setSelectedQueue] = useState("");
  const [selectedQueueName, setSelectedQueueName] = useState("");
  const [scheduledAt, setScheduledAt] = useState(moment().format('YYYY-MM-DDTHH:mm'));
  const [contactLists, setContactLists] = useState([]);
  const [queues, setQueues] = useState([]);
  const [ticketsToConfirm, setTicketsToConfirm] = useState([]);
  const [isConfirmingChanges, setIsConfirmingChanges] = useState(false);
  const [selectedConflicts, setSelectedConflicts] = useState([]);
  const [selectedConnectionName, setSelectedConnectionName] = useState("");
  const { whatsApps, loading: loadingWhatsApps } = useWhatsApps();
  const { list: fetchContactLists } = useContactLists();
  const { findAll: fetchQueues } = useQueues();

  const fetchContactListsRef = useRef(fetchContactLists);
  const fetchQueuesRef = useRef(fetchQueues);

  useEffect(() => {
    fetchContactListsRef.current = fetchContactLists;
    fetchQueuesRef.current = fetchQueues;
  }, [fetchContactLists, fetchQueues]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const companyId = localStorage.getItem("companyId");
  
        const contactData = await fetchContactListsRef.current({ companyId });
        setContactLists(contactData);
  
        const queueData = await fetchQueuesRef.current({ companyId });
        setQueues(queueData);
      } catch (error) {
        toast.error("Erro ao buscar dados.");
      }
    };

    if (isOpen) {
      fetchData();
    }

    if (selectedConnectionName) {
      console.log(selectedConnectionName);
    }
  }, [isOpen, selectedConnectionName]); 

  const handleConfirm = async () => {
    if (!selectedConnection || !selectedContactList || !selectedQueue) {
      toast.error("Por favor, selecione todas as opções.");
      return;
    }

    try {
      const formattedScheduledAt = moment(scheduledAt).format('YYYY-MM-DD HH:mm:ss');

      const response = await api.post("/preview-create", {
        whatsappId: selectedConnection,
        contactListId: selectedContactList,
        queueId: selectedQueue,
        status: "pending",
        scheduledAt: formattedScheduledAt,
      });

      if (response.data.tickets) {
        setTicketsToConfirm(response.data.tickets);
        setIsConfirmingChanges(true);
      } else {
        toast.success("Operação agendada com sucesso!");
        onConfirm();
        onUpdate();
        onClose();
      }
    } catch (error) {
      toast.error("Erro ao confirmar operação.");
    }
  };

  const handleConfirmChanges = async () => {
    try {
      const formattedScheduledAt = moment(scheduledAt).format('YYYY-MM-DD HH:mm:ss');

      const conflictsToConfirm = ticketsToConfirm.filter((ticket, index) => selectedConflicts.includes(index));

      await api.post("/preview-confirm-changes", {
        ticketsToConfirm: conflictsToConfirm,
        contactListId: selectedContactList,
        whatsappId: selectedConnection,
        queueId: selectedQueue,
        status: "pending",
        scheduledAt: formattedScheduledAt,
      });

      toast.success("Mudanças confirmadas e agendamento criado com sucesso!");
      setIsConfirmingChanges(false);
      setTicketsToConfirm([]);
      onConfirm();
      onClose();
    } catch (error) {
      toast.error("Erro ao confirmar mudanças.");
    }
  };

  const handleCancelChanges = () => {
    setIsConfirmingChanges(false);
    setTicketsToConfirm([]);
    toast.info("Mudanças canceladas.");
  };

  const handleConflictSelection = (index) => {
    setSelectedConflicts((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((i) => i !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  const handleSelectAllConflicts = () => {
    if (selectedConflicts.length === ticketsToConfirm.length) {
      setSelectedConflicts([]);
    } else {
      setSelectedConflicts(ticketsToConfirm.map((_, index) => index));
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Selecione Opções</DialogTitle>
      <DialogContent>
        <FormControl fullWidth style={{ marginBottom: "1rem" }}>
          <InputLabel>Conexão</InputLabel>
          <Select
            value={selectedConnection}
            onChange={(e) => {
              setSelectedConnection(e.target.value);
              const selected = whatsApps.find((wpp) => wpp.id === e.target.value);
              setSelectedConnectionName(selected ? selected.name || selected.number : "");
            }}
            disabled={loadingWhatsApps}
          >
            {loadingWhatsApps ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              whatsApps.map((whatsApp) => (
                <MenuItem key={whatsApp.id} value={whatsApp.id}>
                  {whatsApp.name || whatsApp.number}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginBottom: "1rem" }}>
          <InputLabel>Lista de Contatos</InputLabel>
          <Select
            value={selectedContactList}
            onChange={(e) => setSelectedContactList(e.target.value)}
          >
            {contactLists.length > 0 ? (
              contactLists.map((list) => (
                <MenuItem key={list.id} value={list.id}>
                  {list.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>Nenhuma lista encontrada</MenuItem>
            )}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginBottom: "1rem" }}>
          <InputLabel>Fila</InputLabel>
          <Select
            value={selectedQueue}
            onChange={(e) => {
              setSelectedQueue(e.target.value);
              const selected = queues.find((q) => q.id === e.target.value);
              setSelectedQueueName(selected ? selected.name : "");
            }}
          >
            {queues.length > 0 ? (
              queues.map((queue) => (
                <MenuItem key={queue.id} value={queue.id}>
                  {queue.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>Nenhuma fila encontrada</MenuItem>
            )}
          </Select>
        </FormControl>

        <TextField
          label="Agendar para"
          type="datetime-local"
          value={scheduledAt}
          onChange={(e) => setScheduledAt(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          style={{ marginBottom: "1rem" }}
        />

        {isConfirmingChanges && (
          <div className="cmp-compaing__conflict-container">
            <h4>Conflitos encontrados:</h4>
            <Button style={{margin: "1rem 0", display: "flex", width: "auto"}} onClick={handleSelectAllConflicts} color="primary">
              {selectedConflicts.length === ticketsToConfirm.length ? "Desselecionar Todos" : "Selecionar Todos"}
            </Button>
            <ul className={classes.scrollBar}>
              {ticketsToConfirm.map((ticket, index) => (
                <li key={index}>
                  <div className={classes.conflictItem}>
                    <Checkbox
                      checked={selectedConflicts.includes(index)}
                      onChange={() => handleConflictSelection(index)}
                    />
                    <span><PersonIcon className={classes.iconSpacing} />{ticket?.contactName}</span>
                    <div className={classes.queueWrapper}>
                      <span className={classes.currentQueue}>
                        {ticket.currentQueueName}
                      </span>
                      <ArrowForwardIcon />
                      <span className={classes.newQueue}>
                        {selectedQueueName}
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {isConfirmingChanges ? (
          <>
            <Button onClick={handleCancelChanges} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleConfirmChanges} color="primary">
              Confirmar Mudanças
            </Button>
          </>
        ) : (
          <>
            <Button onClick={onClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleConfirm} color="primary">
              Confirmar
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PreviewModal;
