import { useContext } from "react";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";

const useDashboard = () => {
    const { user } = useContext(AuthContext);

    const find = async (params) => {
        if (user.super) {
            const { data } = await api.request({
                url: `/superadmin/dashboard`,
                method: "GET",
                params,
            });
            return data;
        }
        if (!params?.date_from && !params?.days) return;
        const { data } = await api.request({
            url: `/dashboard`,
            method: "GET",
            params,
        });
        return data;
    };

    const getReport = async (params) => {
        const { data } = await api.request({
            url: `/ticket/reports`,
            method: "GET",
            params,
        });
        return data;
    };

    const getServiceQuality = async (companyId) => {
        const { data } = await api.request({
            url: "/service-quality",
            method: "POST",
            data: { companyId },
        });
        return data;
    };

    return {
        find,
        getReport,
        getServiceQuality,
    };
};

export default useDashboard;
