import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dialog, DialogTitle, DialogActions, Button, Box, useTheme } from '@material-ui/core';
import { i18n } from '../../translate/i18n';
import { makeStyles } from '@material-ui/core';
import api from "../../services/api";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import * as XLSX from "xlsx";
import toastError from '../../errors/toastError';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  multFieldLine: {
    display: "flex",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
    marginTop: 8,
  },
  uploadInput: {
    display: "none",
  },

  btns: {

    margin: 15,

  },
  label: {
    padding: 18,
    width: "100%",
    textTransform: 'uppercase',
    display: 'block',
    marginTop: 10,
    border: "solid 2px grey",
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: 8

  },
  modalTitle: {
		fontFamily: "Poppins",
		color: theme.palette.primary.mainReverseByWhite,
	},
	btnStyles: {
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
		borderRadius: "8px",
		color: theme.palette.primary.mainReverseByWhite,
		background: "transparent",
		"&:hover": {
			border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
			background: "transparent",
		},
	},
}));;

const ContactImportWpModal = ({ isOpen, handleClose, selectedTags, hideNum, userProfile }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const initialContact = { name: "", number: "", error: "" }

  const [contactsToImport, setContactsToImport] = useState([])
  const [statusMessage, setStatusMessage] = useState("")
  const [currentContact, setCurrentContact] = useState(initialContact)

  const handleCloseRef = useRef(handleClose);
  handleCloseRef.current = handleClose;

  const handleClosed = useCallback(() => {
    
    setContactsToImport([]);
    setStatusMessage("");
    setCurrentContact({ name: "", number: "", error: "" });
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    const initialContactEF = { name: "", number: "", error: "" };
    if (contactsToImport?.length) {
      contactsToImport.map(async (item, index) => {
        setTimeout(async () => {
          try {
            if (index >= contactsToImport?.length - 1) {
              setStatusMessage(`importação concluída com exito a importação`);
              setContactsToImport([]);
              setCurrentContact(initialContactEF);

              setTimeout(() => {
                handleClosed();
              }, 15000);
            }
            if (index % 5 === 0) {
              setStatusMessage(`importação em andamento ${index} de ${contactsToImport?.length} não saia desta tela até concluir a importação`);
            }

            await api.post(`/contactsImport`, {
              name: item.name,
              number: item.number.toString(),
              email: item.email,
            });

            setCurrentContact({ name: item.name, number: item.number, error: "success" });
          } catch (err) {
            toastError("Escolha alguma conexão Whats como padrão para importar os contatos");
            setCurrentContact({ name: item.name, number: item.number, error: err });
          }
        }, 330 * index);
      });
    }
  }, [contactsToImport, handleClosed]);

  const handleImportChange = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws);
      setContactsToImport(data)
    };
    reader.readAsBinaryString(file);
    handleClose();
    toast.success("Contatos importados");
  };

  return (
    <Dialog PaperProps={{
      style: { border: `2px solid ${theme.palette.primary.mainReverseByWhite}70` }
  }} fullWidth open={isOpen} onClose={handleClosed}>
      <DialogTitle className={classes.modalTitle}>{i18n.t("Importação de contatos")}</DialogTitle>
      <div>
        <Box style={{ padding: "0px 10px 10px" }} >

          {/* <div className={classes.multFieldLine}>
            <Button
              fullWidth
              size="small"
              color="primary"
              variant="contained"
              onClick={() => handleOnExportContacts(false)}
            >
              {i18n.t("contactImportWpModal.title")}
            </Button>
          </div>

          <div className={classes.multFieldLine}>
            <Button
              fullWidth
              size="small"
              color="primary"
              variant="contained"
              onClick={() => handleOnExportContacts(true)}
            >
              {i18n.t("contactImportWpModal.buttons.downloadModel")}
            </Button>
          </div> */}

          <div className={classes.multFieldLine}>
            <div style={{ minWidth: "100%" }}>
              {contactsToImport?.length ?
                <>
                  <div className={classes.label}>
                    <h4>{statusMessage}</h4>
                    {currentContact?.name ?
                      <Button
                        fullWidth
                        disabled
                        className={classes.btnStyles}
                        variant="text"
                      >
                        {`${currentContact?.name} => ${currentContact?.number} `}
                      </Button>
                      : <></>}
                  </div>
                </> :
                <>
                  <label className={classes.label} for="contacts"> <AttachFileIcon /> <div> {i18n.t("contactImportWpModal.buttons.import")}</div> </label>
                  <input className={classes.uploadInput} name='contacts' id='contacts' type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={handleImportChange}
                  />
                </>
              }
            </div>
          </div>
        </Box>
      </div>

      <DialogActions>
        <Button className={classes.btnStyles} onClick={handleClose} color="primary">
          {i18n.t("contactImportWpModal.buttons.closed")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactImportWpModal;
