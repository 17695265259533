import React, { useState, useRef, useCallback } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { head } from "lodash";
import {
  Button,
  Dialog,
  DialogTitle,
  useTheme,
} from "@material-ui/core";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import moment from "moment";
import ConfirmationModal from "../ConfirmationModal";
import initialState from "./WhatsAppModalComponents/mocks/initialState";
import handleSaveWhatsApp from "./WhatsAppModalComponents/handles/handleSaveWhatsapp";
import useStyles from "./style";
import getModelLabel from "./WhatsAppModalComponents/eachModal/getModelLabel";
import FormComponent from "./WhatsAppModalComponents/components/FormComponent";
import "./style.css";
import {
  useSelectedChannelEffect,
  useWhatsAppProxyEffect,
  usePromptsEffect,
  useFetchSessionEffect,
  useQueuesEffect,
  useLocalStorageCallsEffect,
  useModelTypeEffect
} from "./WhatsAppModalComponents/hooks/useWhatsAppModalEffects";
import { Instagram } from "@material-ui/icons";

const SessionSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
});

const WhatsAppModal = ({ open = true, onClose = () => { }, whatsAppId, modelType = "", setUpdatedWhatsApps, setWhatsAppModalOpen, setSelectedWhatsApp }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [whatsApp, setWhatsApp] = useState({ ...initialState, isOfficial: modelType === "official" });
  const [selectedQueueIds, setSelectedQueueIds] = useState([]);
  const [queues, setQueues] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [prompts, setPrompts] = useState([]);
  const [enableImportMessage, setEnableImportMessage] = useState(false);
  const [importOldMessagesGroups, setImportOldMessagesGroups] = useState(false);
  const [closedTicketsPostImported, setClosedTicketsPostImported] = useState(false);
  const [importOldMessages, setImportOldMessages] = useState(moment().add(-1, "days").format("YYYY-MM-DDTHH:mm"));
  const [importRecentMessages, setImportRecentMessages] = useState(moment().add(-1, "minutes").format("YYYY-MM-DDTHH:mm"));
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isCallsEnabled, setIsCallsEnabled] = useState(false);
  const [enableLocalProxy, setEnableLocalProxy] = useState(false);
  const [proxyHost, setProxyHost] = useState("");
  const [proxyPort, setProxyPort] = useState("");
  const [proxyUsername, setProxyUsername] = useState("");
  const [proxyPassword, setProxyPassword] = useState("");
  const attachmentFile = useRef(null);
  const [isHubSelected, setIsHubSelected] = useState(true);
  const [selectedChannel, setSelectedChannel] = useState("");
  const [availableChannels, setAvailableChannels] = useState([]);
  const [sendQueueId, setSendQueueId] = useState(0);

  const fetchChannels = useCallback(async () => {
    try {
      const { data } = await api.get("/hub-channel/");
      setAvailableChannels(data);
    } catch (err) {
      toastError(err);
    }
  }, []);

  const handleEnableLocalProxy = (e) => {
    setEnableLocalProxy(e.target.checked);
  };

  const handleEnableImportMessage = (e) => {
    setEnableImportMessage(e.target.checked);
  };

  const handleChangeQueue = (e) => {
    setSelectedQueueIds(e);
    setSelectedPrompt(null);
  };

  const handleChangePrompt = (e) => {
    setSelectedPrompt(e.target.value);
    setSelectedQueueIds([]);
  };

  const handleClose = () => {
    onClose(setWhatsAppModalOpen, setSelectedWhatsApp);
    setWhatsApp(initialState);
    setEnableImportMessage(false);
    setSelectedChannel("");
    setEnableLocalProxy(false);
    setProxyHost("")
    setProxyPort("");
    setProxyUsername("");
    setProxyPassword("")
  };

  const removeMedia = async () => {
    await api.delete(`/whatsapp/${whatsAppId}/media-upload`);
    handleClose();
  };

  const handleAttach = (e, setFile) => {
    const file = head(e.target.files);

    if (file) {
      setFile('attachment', file);
    }
  };

  const isSocial = modelType === "instagram" || modelType === "facebook" || modelType === "official";
  const model = getModelLabel(modelType);
  const label = whatsAppId
    ? <>{model.icon}Editar canal</>
    : <>{model.icon}Adicionar canal</>;

  const handleSubmit = (values, actions) => {
    setTimeout(() => {
      handleSaveWhatsApp(
        values, modelType, selectedQueueIds, selectedPrompt,
        enableImportMessage, importOldMessages, importRecentMessages,
        importOldMessagesGroups, closedTicketsPostImported, enableLocalProxy,
        proxyHost, proxyPort, proxyUsername, proxyPassword, isHubSelected,
        selectedChannel, availableChannels, whatsAppId, setUpdatedWhatsApps,
        whatsApp, setWhatsApp, handleClose, sendQueueId
      );
      actions.setSubmitting(false);
    }, 400);
  };

  useSelectedChannelEffect(selectedChannel);
  useWhatsAppProxyEffect(whatsApp, setProxyHost, setProxyPort, setProxyUsername, setProxyPassword, setEnableLocalProxy);
  usePromptsEffect(whatsAppId, setPrompts);
  useFetchSessionEffect(whatsAppId, setWhatsApp, setSelectedPrompt, setSelectedQueueIds, setEnableImportMessage, setImportOldMessages, setImportRecentMessages, setClosedTicketsPostImported, setImportOldMessagesGroups, fetchChannels);
  useQueuesEffect(setQueues);
  useLocalStorageCallsEffect(setIsCallsEnabled);
  useModelTypeEffect(modelType, setWhatsApp, fetchChannels);
  const handleFacebookLogin = () => {
    console.log("")
  }

  const handleInstagramLogin = () => {
    console.log("")
  }
  return (
    <div className={classes.root}>
      <ConfirmationModal
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={removeMedia}
        title={i18n.t("whatsappModal.confirmation.title")}>
        {i18n.t("whatsappModal.confirmation.message")}
      </ConfirmationModal>
      <Dialog
        open={open}
        onClose={() => handleClose(setWhatsAppModalOpen, setSelectedWhatsApp)}
        fullWidth
        className="cmp-dialog__whatsapp-modal">
        <DialogTitle>
          <div className={classes.titleStyles} style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            {label}
          </div>
        </DialogTitle>
        {modelType === "facebookOfficial" && (
          <Button
            variant="outlined"
            onClick={handleFacebookLogin}
            className={classes.spanColumn}
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg"
              alt="Facebook Official Icon"
              style={{ width: "24px", height: "24px" }}
            />
            <span>Conectar ao Facebook</span>
          </Button>
        )}

        {modelType === "instagramOfficial" && (
          <Button
            variant="outlined"
            onClick={handleInstagramLogin}
            className={classes.spanColumn}
          >
            <div
              style={{
                background: "linear-gradient(45deg, #feda75, #fa7e1e, #d62976, #962fbf, #4f5bd5)",
                borderRadius: "50%", // Forma circular
                padding: "4px", // Espaço interno ao redor do ícone
                display: "flex",
                maxWidth: "150px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Instagram style={{ fontSize: "20px", color: "white" }} /> {/* Ajuste conforme necessário */}
            </div>
            <span>Conectar ao Instagram</span>
          </Button>
        )}

        <Formik
          initialValues={whatsApp}
          enableReinitialize={true}
          validationSchema={SessionSchema}
          onSubmit={(values, actions) => handleSubmit(values, actions)}
        >
          {({ values, touched, errors, isSubmitting, setFieldValue }) => (
            <FormComponent
              setWhatsApp={setWhatsApp}
              classes={classes} open={open}
              handleClose={handleClose} confirmationOpen={confirmationOpen}
              setConfirmationOpen={setConfirmationOpen} removeMedia={removeMedia} label={label}
              whatsApp={whatsApp} modelType={modelType} isHubSelected={isHubSelected}
              setIsHubSelected={setIsHubSelected} fetchChannels={fetchChannels}
              selectedChannel={selectedChannel} setSelectedChannel={setSelectedChannel}
              availableChannels={availableChannels} enableImportMessage={enableImportMessage}
              handleEnableImportMessage={handleEnableImportMessage} importOldMessagesGroups={importOldMessagesGroups}
              setImportOldMessagesGroups={setImportOldMessagesGroups} closedTicketsPostImported={closedTicketsPostImported}
              setClosedTicketsPostImported={setClosedTicketsPostImported} importOldMessages={importOldMessages}
              setImportOldMessages={setImportOldMessages} importRecentMessages={importRecentMessages}
              setImportRecentMessages={setImportRecentMessages} enableLocalProxy={enableLocalProxy}
              handleEnableLocalProxy={handleEnableLocalProxy} proxyHost={proxyHost}
              setProxyHost={setProxyHost} proxyPort={proxyPort} setProxyPort={setProxyPort}
              proxyUsername={proxyUsername} setProxyUsername={setProxyUsername}
              proxyPassword={proxyPassword} setProxyPassword={setProxyPassword}
              isSocial={isSocial} isCallsEnabled={isCallsEnabled}
              selectedQueueIds={selectedQueueIds} handleChangeQueue={handleChangeQueue}
              selectedPrompt={selectedPrompt} handleChangePrompt={handleChangePrompt}
              prompts={prompts} queues={queues} handleSubmit={handleSubmit}
              attachmentFile={attachmentFile} handleAttach={handleAttach}
              setWhatsAppModalOpen={setWhatsAppModalOpen} setSelectedWhatsApp={setSelectedWhatsApp}
              values={values} touched={touched} errors={errors}
              isSubmitting={isSubmitting} setFieldValue={setFieldValue}
              whatsAppId={whatsAppId}
              setSendQueueId={setSendQueueId}
              sendQueueId={sendQueueId}
            />
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default React.memo(WhatsAppModal);
