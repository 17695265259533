import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import {
  Table, TableBody, TableCell, TableHead, TableRow,
  Button, TextField, MenuItem, Grid, Paper, Typography,
  useTheme,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme: any): any  => ({
	root: {
		width: '100%'
	},
    mainPaper: {
		width: '100%',
		flex: 1,
    },
	fullWidth: {
		width: '100%',
		overflow: "auto",
		overflowX: "hidden",
		marginTop: "0.5rem",
		...theme.scrollbarStyles,
	},
    tableContainer: {
		width: '100%',
		overflowX: "scroll",
        border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
		...theme.scrollbarStyles,
		"& .rbc-btn-group": {
            borderRadius: "8px",
            border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        },
        "& h5": {
            fontFamily: "Poppins",
            color: theme.palette.primary.mainReverseByWhite,
            fontWeight: '500',
            fontSize: "16px"
        },
        "& button": {
            color: theme.palette.primary.mainReverseByWhite,
        },

        "& h6": {
            fontFamily: "Poppins",
            color: theme.palette.primary.mainReverseByWhite,
            fontWeight: '500 !important',
            fontSize: "14px"
        },

        "& svg": {
            color: theme.palette.primary.mainReverseByWhite,
        },

        "& .MuiPaper-rounded": {
            border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
        },

        "& th, p, & span,& td": {
            fontFamily: "Poppins",
            fontWeight: '500 !important',
            fontSize: "14px"
        },

        "& .MuiTablePagination-actions": {
            display: "flex",
            gap: "1rem"
        }
    },
    headerTexts: {
      color: theme.palette.primary.mainReverseByWhite,
      fontFamily: "Poppins",
      fontWeight: "600"
    }
}));

interface SimpleCompany {
  id: number;
  name: string;
}

interface Company {
  companyId: number;
  companyName: string;
  planId: number;
  planName: string;
  messageValue: number | string;
  messageCount: number;
  totalMessageValue: number | string;
  planMonthlyValue: number | string;
  totalValue: number | string;
}

const MessagesByCompany = () => {
  const classes: any = useStyles();
  const [companies, setCompanies] = useState<Company[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [availableCompanies, setAvailableCompanies] = useState<SimpleCompany[]>([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('all'); // "all" como padrão para selecionar todas
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const theme: any = useTheme();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await api.get('/api/messages/companies');
        setAvailableCompanies([{ id: 'all', name: 'Todas as Empresas' }, ...response.data]); // Adiciona "Todas as Empresas"
      } catch (err) {
        console.error('Erro ao buscar empresas', err);
      }
    };
    fetchCompanies();
  }, []);

  // Função chamada ao clicar em "Filtrar"
  const handleFilter = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const params = {
        startDate,
        endDate,
        ...(selectedCompanyId !== 'all' && { companyId: selectedCompanyId }) // Só envia companyId se não for "all"
      };

      const response = await api.get('/api/messages/messagesByCompany', {
        params,
      });
      setCompanies(response.data);
    } catch (err) {
      setError('Erro ao buscar mensagens por empresa');
      console.error('Erro na requisição:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Carregando...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <Typography style={{ fontSize: "14px", fontFamily: "Poppins", color: theme.palette.primary.mainReverseByWhite, fontWeight: "500", marginBottom: "1rem" }} variant="h4" gutterBottom>
        Mensagens por Empresa
      </Typography>

      <Paper elevation={3} style={{ border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`, padding: '1rem', marginBottom: '2rem' }}>
        <form onSubmit={handleFilter}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={4}>
              <TextField
                select
                label="Empresa"
                value={selectedCompanyId}
                onChange={(e) => setSelectedCompanyId(e.target.value)}
                fullWidth
              >
                {availableCompanies.map((company) => (
                  <MenuItem key={company.id} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                label="Data de Início"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                label="Data de Fim"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={2}>
              <Button style={{
                border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
                borderRadius: "8px",
                color: theme.palette.primary.mainReverseByWhite,
                background: "transparent",
              }} type="submit" fullWidth>
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      {companies.length === 0 ? (
        <Typography variant="body1" color="textSecondary">
          Nenhuma mensagem encontrada.
        </Typography>
      ) : (
        <Paper elevation={3}>
          <Table className={classes.tableContainer}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerTexts} align="left">Nome da Empresa</TableCell>
                <TableCell className={classes.headerTexts} align="left">ID da Empresa</TableCell>
                <TableCell className={classes.headerTexts} align="left">Nome do Plano</TableCell>
                <TableCell className={classes.headerTexts} align="left">ID do Plano</TableCell>
                <TableCell className={classes.headerTexts} align="left">Total de Mensagens (Baseado em 24h)</TableCell>
                <TableCell className={classes.headerTexts} align="left">Valor por Mensagem</TableCell>
                <TableCell className={classes.headerTexts} align="left">Valor Total das Mensagens</TableCell>
                <TableCell className={classes.headerTexts} align="left">Valor Mensal do Plano</TableCell>
                <TableCell className={classes.headerTexts} align="left">Valor Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.map((company) => (
                <TableRow key={company.companyId}>
                  <TableCell align="left">{company.companyName || 'Sem Nome'}</TableCell>
                  <TableCell align="left">{company.companyId}</TableCell>
                  <TableCell align="left">{company.planName || 'Sem Plano'}</TableCell>
                  <TableCell align="left">{company.planId}</TableCell>
                  <TableCell align="left">{company.messageCount}</TableCell>
                  <TableCell align="left">
                    {Number(company.messageValue).toFixed(2)}
                  </TableCell>
                  <TableCell align="left">
                    R$ {Number(company.totalMessageValue).toFixed(2)}
                  </TableCell>
                  <TableCell align="left">
                    R$ {Number(company.planMonthlyValue).toFixed(2)}
                  </TableCell>
                  <TableCell align="left">
                    R$ {Number(company.totalValue).toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </div>
  );
};

export default MessagesByCompany;
