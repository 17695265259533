import React from "react";

import Chart from "../../pages/Dashboard/Chart";

import ScatterChart from "../ScatterChart";
import SellerRankingChart from "../SellerRankingChart";
import DonutChart from "../PieChart";
import SuperAdminDonutChart from "../SuperAdminDonut";
import SuperLineChart from "../SupperScatterChart";

const superWait = [
    {
        "hour": "2024-11-16 23",
        "count": "0"
    },
    {
        "hour": "2024-11-16 22",
        "count": "0"
    },
    {
        "hour": "2024-11-16 21",
        "count": "0"
    },
    {
        "hour": "2024-11-16 20",
        "count": "0"
    },
    {
        "hour": "2024-11-16 19",
        "count": "0"
    },
    {
        "hour": "2024-11-16 18",
        "count": "0"
    },
    {
        "hour": "2024-11-16 17",
        "count": "0"
    },
    {
        "hour": "2024-11-16 16",
        "count": "0"
    },
    {
        "hour": "2024-11-16 15",
        "count": "0"
    },
    {
        "hour": "2024-11-16 14",
        "count": "0"
    },
    {
        "hour": "2024-11-16 13",
        "count": "0"
    },
    {
        "hour": "2024-11-16 12",
        "count": "0"
    },
    {
        "hour": "2024-11-16 11",
        "count": "0"
    },
    {
        "hour": "2024-11-16 10",
        "count": "0"
    },
    {
        "hour": "2024-11-16 09",
        "count": "0"
    },
    {
        "hour": "2024-11-16 08",
        "count": "0"
    },
    {
        "hour": "2024-11-16 07",
        "count": "0"
    },
    {
        "hour": "2024-11-16 06",
        "count": "0"
    },
    {
        "hour": "2024-11-16 05",
        "count": "0"
    },
    {
        "hour": "2024-11-16 04",
        "count": "0"
    },
    {
        "hour": "2024-11-16 03",
        "count": "0"
    },
    {
        "hour": "2024-11-16 02",
        "count": "0"
    },
    {
        "hour": "2024-11-16 01",
        "count": "0"
    },
    {
        "hour": "2024-11-16 00",
        "count": "0"
    }
];

const DashboardGraphs = ({ attendants, counters, tickets, lastTickets, classes, theme, isSuper}) => {
    return (
        <div className="cmp-dashboard__graphs">
            <div className="cmp-dashboard__graphs-box--left">
                {!isSuper && <div className={`cmp-dashboard__graphs-box--seller-rank ${classes.graphsBox}`}>
                    {attendants.length ? (
                        <SellerRankingChart
                            attendants={attendants}
                        />
                    ) : <SellerRankingChart
                        attendants={[{ name: "Loading", tickets: 10 }]}
                    />}

                </div>}
                {!isSuper && <div className={`cmp-dashboard__graphs-box--pie ${classes.graphsBox}`}>
                    <DonutChart
                        fontColor={theme.palette.blackOrWhite}
                        classes={classes}
                        pendent={counters.supportPending ? counters.supportPending : 0}
                        ongoing={counters.supportHappening ? counters.supportHappening: 0}
                        done={counters.supportFinished ? counters.supportFinished : 0}
                    />
                </div>}
                {isSuper && <div className={`cmp-dashboard__graphs-box--pie ${classes.graphsBox}`}>
                    <SuperAdminDonutChart
                        classes={classes}
                        connectionCounts={counters?.connectionCounts || 0}
                    />
                </div>}
            </div>
            <div className="cmp-dashboard__graphs-new-container" style={{
                display: 'flex',
                flexDirection: "column",
                alignItems: 'center',
                width: '79.5%',
                gap: '2rem'
            }}>
                {!isSuper &&  <div className={`cmp-dashboard__graphs-box--first ${classes.graphsBox}`}>
                    <ScatterChart fontColor={theme.palette.blackOrWhite} tickets={lastTickets} />
                </div>}
                {isSuper && (
                    <SuperLineChart
                        data={counters?.ticketsByHour ? counters.ticketsByHour : superWait}
                    />
                )}
                {!isSuper &&  <div className={`cmp-dashboard__graphs-box ${classes.graphsBox}`}>
                    <Chart classes={classes} tickets={tickets} />
                </div>}
            </div>

        </div>
    );
};

export default DashboardGraphs;
