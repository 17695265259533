import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "flex-start",
    gap: theme.spacing(2),
    overflowX: "auto",
    padding: "1rem",
    "&::-webkit-scrollbar": {
      width: "12px"
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c0c0c070",
      borderRadius: "20px",
    }
  },
  laneContainer: {
    padding: "1rem",
    backgroundColor: "#e3e1e170",
    borderRadius: "16px",
    height: "calc(100vh - 114px)",
    minWidth: "250px",
    maxWidth: "300px",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    flex: "1 1 250px",
    boxShadow: "5px 5px 10px 2px rgba(0, 0, 0, 0.3)",
    overflow: "hidden",
    "& h3": {
      fontFamily: "Poppins",
      fontWeight: "500",
      color: theme.palette.primary.mainReverseByWhite,
    }
  },
  lane: {
    height: "100%",
    overflowX: "hidden",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "12px"
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#c0c0c070",
      borderRadius: "20px",
    }
  },
  card: {
    padding: theme.spacing(1),
    marginBottom: "1rem",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)",
  },
  emptyCard: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: "#e0e0e070",
    borderRadius: "5px",
    textAlign: "center",
  },
  button: {
    background: "transparent",
    border: `2px solid ${theme.palette.primary.main}70`,
    padding: "10px",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    fontFamily: "Poppins",
    borderRadius: "8px",
    "&:hover": {
      background: "transparent",
      cursor: "pointer",
      color: `${theme.palette.primary.mainReverseByWhite}70`,
    }
  },
  addColumnButton: {
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.dark,
    textAlign: "center",
    borderRadius: "16px",
    cursor: "pointer",
    fontWeight: "bold",
    padding: "1rem",
    minWidth: "250px",
    maxWidth: "300px",
    border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 1 250px",
    boxShadow: "5px 5px 10px 2px rgba(0, 0, 0, 0.3)",
    overflow: "hidden",
    "& h4": {
      fontFamily: "Poppins",
      fontWeight: "400",
      color: theme.palette.primary.mainReverseByWhite,
    }
  },
  moreIcon: {
    padding: "0"
  }
}));

export default useStyles;