import React from "react";

import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    makeStyles,
    useTheme,
} from "@material-ui/core";

import api from "../../../../services/api";
import toastError from "../../../../errors/toastError";

const useStyles = makeStyles(theme => ({

	modalTitle: {
		fontFamily: "Poppins",
		color: theme.palette.primary.mainReverseByWhite,
	},
	btnStyles: {
		border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
		borderRadius: "8px",
		color: theme.palette.primary.mainReverseByWhite,
		background: "transparent",
		"&:hover": {
			border: `2px solid ${theme.palette.primary.mainReverseByWhite}70`,
			background: "transparent",
		},
	},
}));

export function DeleteCategoryModal({
    handleDelete,
    open,
    category,
    handleClose
}) {
    const theme = useTheme()
    const classes = useStyles(theme);
    const deleteCategorie = async () => {
        try {
            const { data } = await api.delete(`/chat-categories/${category.id}`);
            handleDelete(category);
        } catch (err) {
            toastError(err);
        }
    }
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: { border: `2px solid ${theme.palette.primary.mainReverseByWhite}70` }
              }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className={classes.modalTitle} id="alert-dialog-title">Tem certeza que quer deletar essa Categoria?</DialogTitle>
            <DialogActions>
                <Button className={classes.btnStyles} onClick={handleClose} color="primary">
                    Fechar
                </Button>
                <Button className={classes.btnStyles} onClick={deleteCategorie} color="primary" variant="contained">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
