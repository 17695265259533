import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    title: {
        fontFamily: "Poppins",
        fontWeight: 500,
        fontSize: "16px",
        color: theme.palette.primary.mainReverseByWhite,
    },
    modalContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        zIndex: 1300,
        padding: '20px',
    },
    timelineWrapper: {
        display: 'flex',
        overflowX: 'auto',
        padding: '20px',
        width: '80%',
        height: '50vh',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        ...theme.scrollbarStyles
    },
    timelineContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        padding: '20px 10px',
    },
    actionType: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column'
    },
    actionTypeUserNameWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px', // Espaço entre a imagem e o nome
    },
    actionTypeUserName: {
        fontWeight: 'bold',
    },
    avatarSmall: {
        width: '24px',  // Ajuste o tamanho do avatar pequeno
        height: '24px', // Ajuste o tamanho do avatar pequeno
    },
    actionDetails: {
        textAlign: 'center',
        fontSize: '14px',
        color: theme.palette.text.secondary,
        marginTop: '5rem',
    },
    actionLine: {
        height: '2px',
        backgroundColor: theme.palette.grey[300],
        position: 'absolute',
        top: '50%',
        left: 0,
        right: 0,
        zIndex: 0,  // Certifica-se de que a linha fique atrás do ícone
    },
    actionCircle: {
        height: '20px',
        width: '20px',
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main,
        border: '3px solid white',
        position: 'relative',
        zIndex: 1,
    },
    icon: {
        fontSize: '24px',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',  // Posiciona o ícone no centro da linha
        zIndex: 1,
    },
}));

export default useStyles;