import React, { useEffect, useRef, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Chip,
  Tooltip,
  TextField,
  InputAdornment
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { i18n } from "../../../translate/i18n";
import truncateText from "../../../utils/truncateText";
import api from "../../../services/api";
import toastError from "../../../errors/toastError";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../components/ConfirmationModal";
import TagModal from "../../../components/TagModal";
import Button from "@material-ui/core/Button";
import MainHeaderButtonsWrapper from "../../../components/MainHeaderButtonsWrapper";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination"; // Importação do componente de paginação

const TagsTable = ({ classes, route, title, user }) => {
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tags, setTags] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [selectedTag, setSelectedTag] = useState(null);
  const [deletingTag, setDeletingTag] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [kanbanModalOpen, setKanbanModalOpen] = useState(false);
  const pageNumberRef = useRef(1);

  const fetchTags = (async (page = 1, reload = false, searchParam = "") => {
    try {
      setLoading(true);
      const { data } = await api.get(route, {
        params: {
          searchParam,
          pageNumber: page,
          sortBy: "createdAt",
          sortOrder: "DESC"
        },
      });

      setTags(data?.tags || []);

      const itemsPerPage = 20;
      const totalPagesCalculated = Math.ceil(data?.count / itemsPerPage);

      setTotalPages(totalPagesCalculated);
      setLoading(false);

      if (reload) {
        pageNumberRef.current = 1;
      }
      if(false) {
        console.log(loading)
      }
    } catch (err) {
      toastError(err);
      setLoading(false);
    }
  });


  useEffect(() => {
    fetchTags(pageNumber);
  }, [pageNumber]);

  const handleEditTag = (tag) => {
    setSelectedTag(tag);
    setTagModalOpen(true);
  };

  const handleDeleteTag = async (tagId) => {
    try {
      await api.delete(`/tags/${tagId}`);
      toast.success(i18n.t("tags.toasts.deleted"));
      fetchTags(pageNumber, true);
    } catch (err) {
      toastError(err);
    }
    setDeletingTag(null);
  };

  const handleCloseTagModal = (tagId, tag, newTagId) => {
    setSelectedTag(null);
    setTagModalOpen(false);
    setKanbanModalOpen(false);
    if (tagId) {
      setTags(prevState => prevState.map((tagState) => tagState.id === tagId ? { ...tagState, ...tag } : tagState))
    } else if (tag?.name) {
      setTags(prevState => [{ ...tag, id: newTagId }, ...prevState])
    }
  };

  const handleOpenTagModal = () => {
    setSelectedTag(null);
    setTagModalOpen(true);
  };

  const handleOpenKanbanModal = () => {
    setSelectedTag(null);
    setKanbanModalOpen(true);
  };

  const handleSearch = async (event) => {
    setSearchParam(event?.target?.value);
    fetchTags(1, true, event?.target?.value);
  };

  const handlePageChange = (_, newPage) => {
    setPageNumber(newPage);
  };

  return (
    <div>
      <TagModal
        open={tagModalOpen}
        onClose={handleCloseTagModal}
        reload={() => { }}
        aria-labelledby="form-dialog-title"
        tagId={selectedTag ? selectedTag.id : null}
      />
      <ConfirmationModal
        title={deletingTag && `${i18n.t("tags.confirmationModal.deleteTitle")}`}
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDeleteTag(deletingTag.id)}
      >
        {i18n.t("tags.confirmationModal.deleteMessage")}
      </ConfirmationModal>
      <div className={classes.tableLabel}>{title === "Kanban" ? (
        <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
          <h4>
            {title}
          </h4>
          <div>
            <MainHeaderButtonsWrapper>
            </MainHeaderButtonsWrapper>
            {(user.profile === "admin" || user.profile === "supervisor") && <Button
              variant="contained"
              color="primary"
              className={classes.btnStyles}
              onClick={() => handleOpenKanbanModal()}
            >
              + Kanban
            </Button>}
          </div>
        </div>
      ) : (
        <>
          <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
            <h4>
              {title}
            </h4>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.btnStyles}
                onClick={handleOpenTagModal}
              >
                {user.profile === "admin" || user.profile === "supervisor" ? "+ Tag" : "+"}
              </Button>
            </div>
          </div>

        </>
      )}</div>
      <MainHeaderButtonsWrapper >
        <TextField
          placeholder={i18n.t("contacts.searchPlaceholder")}
          type="search"
          value={searchParam}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ color: "gray" }} />
              </InputAdornment>
            ),
          }}
        />
      </MainHeaderButtonsWrapper>
      <Paper className={classes.tableContainer} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={`${classes.headerTexts} ${classes.fixedColumn}`} align="center">
                {i18n.t("tags.table.name")}
              </TableCell>
              <TableCell className={`${classes.headerTexts} ${classes.fixedColumn}`} align="center">
                {i18n.t("tags.table.tickets")}
              </TableCell>
              <TableCell className={`${classes.headerTexts} ${classes.actionsColumn}`} align="center">
                {i18n.t("tags.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tags.map((tag) => (
              <TableRow key={tag.id}>
                <TableCell align="center">
                  <Tooltip title={tag.name}>
                    <Chip
                      variant="outlined"
                      style={{
                        backgroundColor: `${tag.color ? tag.color : "#000000"}70`,
                        border: `2px solid ${tag.color ? tag.color : "#000000"}`,
                        textShadow: "1px 1px 1px #000",
                        borderRadius: "8px",
                        color: "white",
                      }}
                      label={truncateText(tag.name)}
                      size="small"
                    />
                  </Tooltip>
                </TableCell>
                <TableCell align="center">{tag.tickets}</TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => handleEditTag(tag)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteTag(tag.id)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Pagination
        count={totalPages}
        page={pageNumber}
        onChange={handlePageChange}
        color="primary"
        style={{ marginTop: "20px", display: "flex", justifyContent: "center" }}
      />
      {kanbanModalOpen && <TagModal
        open={kanbanModalOpen}
        onClose={handleCloseTagModal}
        reload={() => { }}
        kanbanSelected={true}
        aria-labelledby="form-dialog-title"
        tagId={selectedTag && selectedTag.id}
      />}
    </div>
  );
};

export default TagsTable;
