import React, { useState } from "react";

import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";

import api from "../../../../services/api";
import ConfirmationModal from "../../../../components/ConfirmationModal";

import { i18n } from "../../../../translate/i18n";
import toastError from "../../../../errors/toastError";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";

import { Tooltip } from "@material-ui/core";

const DeleteContacts = ({ onReload, classes }) => {


    const [confirmOpen, setConfirmOpen] = useState(false);
  
    const handleDeleteContact = async () => {
      try {
        await api.delete(`/delete-contacts`);
        toast.success(i18n.t("contacts.toasts.deleted"));
        onReload();
      } catch (error) {
        toastError(error);
      }
    }
  
    return (
      <>
        <ConfirmationModal
          title={`${i18n.t("contacts.confirmationModal.deleteTitleAll")}`}
          open={confirmOpen}
          onClose={setConfirmOpen}
          onConfirm={(e) =>
            handleDeleteContact()
          }
        >
          {i18n.t("contacts.confirmationModal.deleteAllMessage")}
  
        </ConfirmationModal>
        <Button
          variant="contained"
          color="secondary"
          className={classes.buttonStyles}
          onClick={(e) => setConfirmOpen(true)}
        >
          <Tooltip title={i18n.t("contacts.buttons.remove")}>
            <DeleteSweepIcon />
          </Tooltip>
        </Button>
      </>
    )
  }

  export default DeleteContacts;