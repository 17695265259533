function transformToEnvVars(data) {
  const result = {};

  Object.entries(data).forEach(([key, value]) => {
    switch (key) {
      // Mapeamento para o modo claro (LIGHT)
      case 'main_color':
        result.REACT_APP_LIGHT_MAIN_COLOR = value;
        break;
      case 'second_color':
        result.REACT_APP_LIGHT_SECOND_COLOR = value;
        break;
      case 'third_color':
        result.REACT_APP_LIGHT_THIRD_COLOR = value;
        break;
      case 'text_color':
        result.REACT_APP_LIGHT_TEXT_COLOR = value;
        break;
      case 'links_color':
        result.REACT_APP_LIGHT_LINKS_COLOR = value;
        break;
      case 'main_gradient':
        result.REACT_APP_LIGHT_MAIN_GRADIENT = value;
        break;
      case 'second_gradient':
        result.REACT_APP_LIGHT_SECOND_GRADIENT = value;
        break;
      case 'reverse_white_by_main':
        result.REACT_APP_LIGHT_REVERSE_WHITE_BY_MAIN = value;
        break;
      case 'main_reverse_by_white':
        result.REACT_APP_LIGHT_MAIN_REVERSE_BY_WHITE = value;
        break;

      // Mapeamento para o modo escuro (DARK)
      case 'dark_main_color':
        result.REACT_APP_DARK_MAIN_COLOR = value;
        break;
      case 'dark_second_color':
        result.REACT_APP_DARK_SECOND_COLOR = value;
        break;
      case 'dark_third_color':
        result.REACT_APP_DARK_THIRD_COLOR = value;
        break;
      case 'dark_text_color':
        result.REACT_APP_DARK_TEXT_COLOR = value;
        break;
      case 'dark_links_color':
        result.REACT_APP_DARK_LINKS_COLOR = value;
        break;
      case 'dark_main_gradient':
        result.REACT_APP_DARK_MAIN_GRADIENT = value;
        break;
      case 'dark_second_gradient':
        result.REACT_APP_DARK_SECOND_GRADIENT = value;
        break;
      case 'dark_reverse_white_by_main':
        result.REACT_APP_DARK_REVERSE_WHITE_BY_MAIN = value;
        break;
      case 'dark_main_reverse_by_white':
        result.REACT_APP_DARK_MAIN_REVERSE_BY_WHITE = value;
        break;

      // Propriedades adicionais
      case 'logo':
        result.REACT_APP_LOGO = value;
        break;

      // Ignorar campos que não são configuráveis
      case 'id':
      case 'companyId':
      case 'createdAt':
      case 'updatedAt':
        break;

      // Tratamento padrão
      default:
        result[`REACT_APP_${key.toUpperCase()}`] = value;
        break;
    }
  });

  return result;
}
export default transformToEnvVars;