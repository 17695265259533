import React from "react";
import { Form } from "formik";
import { Field } from "formik";

import {
    DialogContent,
    TextField,
    useTheme,
} from "@material-ui/core";
import MultifieldInputs from "./MultifieldInputs";
import ImportMessage from "./ImportMessage";
import WhatsAppProxy from "./WhatsAppProxy";
import AutoInputMessages from "./AutoInputMessages";
import QueueAndOtherInputs from "./QueueAndOtherInputs";
import BotConfigs from "./BotConfigs";
import EndButtons from "./EndButtons";
import InfoRecomendationsTabs from "./InfoRecomendationsTabs";

const FormComponent = (props) => {
    const {
        classes,
        handleClose,
        whatsApp,
        modelType,
        isHubSelected,
        setIsHubSelected,
        fetchChannels,
        selectedChannel,
        setSelectedChannel,
        availableChannels,
        enableImportMessage,
        handleEnableImportMessage,
        importOldMessagesGroups,
        setImportOldMessagesGroups,
        closedTicketsPostImported,
        setClosedTicketsPostImported,
        importOldMessages,
        setImportOldMessages,
        importRecentMessages,
        setImportRecentMessages,
        enableLocalProxy,
        handleEnableLocalProxy,
        proxyHost,
        setProxyHost,
        proxyPort,
        setProxyPort,
        proxyUsername,
        setProxyUsername,
        proxyPassword,
        setProxyPassword,
        isSocial,
        isCallsEnabled,
        selectedQueueIds,
        handleChangeQueue,
        selectedPrompt,
        handleChangePrompt,
        prompts,
        queues,
        errors,
        touched,
        setFieldValue,
        values,
        isSubmitting,
        whatsAppId,
        setWhatsApp,
        sendQueueId,
        setSendQueueId
    } = props;

    const theme = useTheme();
    return (
        <Form>
            <DialogContent>
                <div style={{display: "flex", flexDirection: "column"}}>
                    <label style={{fontFamily: "Poppins", fontWeight: "700", fontSize: "12px", color: theme.palette.primary.mainReverseByWhite}} htmlFor="name">Nome do canal</label>
                    <Field
                        as={TextField}
                        name="name"
                        id="name"
                        error={touched.name && Boolean(errors.name)}
                        helperText={touched.name && errors.name}
                        variant="outlined"
                        margin="dense"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
                {!(modelType === "facebookOfficial" || modelType === "instagramOfficial") && <>
                    <label style={{display: "flex", marginTop: "1rem", fontFamily: "Poppins", fontWeight: "700", fontSize: "12px", color: theme.palette.primary.mainReverseByWhite}}>Preferências</label>
                    <div className={classes.switchLabels}>
                        <MultifieldInputs
                            whatsApp={whatsApp}
                            modelType={modelType}
                            touched={touched}
                            errors={errors}
                            values={values}
                            setFieldValue={setFieldValue}
                            isHubSelected={isHubSelected}
                            setIsHubSelected={setIsHubSelected}
                            fetchChannels={fetchChannels}
                            selectedChannel={selectedChannel}
                            setSelectedChannel={setSelectedChannel}
                            availableChannels={availableChannels}
                            classes={classes}
                        />
                        <ImportMessage
                            modelType={modelType}
                            enableImportMessage={enableImportMessage}
                            handleEnableImportMessage={handleEnableImportMessage}
                            importOldMessagesGroups={importOldMessagesGroups}
                            setImportOldMessagesGroups={setImportOldMessagesGroups}
                            closedTicketsPostImported={closedTicketsPostImported}
                            setClosedTicketsPostImported={setClosedTicketsPostImported}
                            importOldMessages={importOldMessages}
                            setImportOldMessages={setImportOldMessages}
                            importRecentMessages={importRecentMessages}
                            setImportRecentMessages={setImportRecentMessages}
                            touched={touched}
                            errors={errors}
                            classes={classes}
                        />
                        <WhatsAppProxy
                            modelType={modelType}
                            enableLocalProxy={enableLocalProxy}
                            handleEnableLocalProxy={handleEnableLocalProxy}
                            proxyHost={proxyHost}
                            proxyPort={proxyPort}
                            proxyUsername={proxyUsername}
                            proxyPassword={proxyPassword}
                            setProxyHost={setProxyHost}
                            setProxyPort={setProxyPort}
                            setProxyUsername={setProxyUsername}
                            setProxyPassword={setProxyPassword}
                        />
                    </div>
                </>}
                {(modelType === "whatsapp" || modelType === "official") && <InfoRecomendationsTabs />}
                <label style={{display: "flex", marginTop: "1rem", fontFamily: "Poppins", fontWeight: "700", fontSize: "12px", color: theme.palette.primary.mainReverseByWhite}}>Mensagens automáticas</label>
   
                <AutoInputMessages
                    isSocial={false}
                    classes={classes}
                    touched={touched}
                    errors={errors}
                />
                <label style={{display: "flex", marginTop: "1rem", fontFamily: "Poppins", fontWeight: "700", fontSize: "12px", color: theme.palette.primary.mainReverseByWhite}}>Filas e Token</label>
                <QueueAndOtherInputs
                    isSocial={isSocial}
                    modelType={modelType}
                    classes={classes}
                    isCallsEnabled={isCallsEnabled}
                    selectedQueueIds={selectedQueueIds}
                    handleChangeQueue={handleChangeQueue}
                    selectedPrompt={selectedPrompt}
                    handleChangePrompt={handleChangePrompt}
                    prompts={prompts}
                />
                <label style={{display: "flex", marginTop: "1rem", fontFamily: "Poppins", fontWeight: "700", fontSize: "12px", color: theme.palette.primary.mainReverseByWhite}}>Redirecionamentno de fila</label>
                <BotConfigs
                    setWhatsApp={setWhatsApp}
                    whatsApp={whatsApp}
                    sendQueueId={sendQueueId}
                    classes={classes}
                    queues={queues}
                    setSendQueueId={setSendQueueId}
                    touched={touched}
                    errors={errors}
                />
            </DialogContent>
            <EndButtons
                whatsAppId={whatsAppId}
                isSubmitting={isSubmitting}
                handleClose={handleClose}
                classes={classes}
            />
        </Form>
    );
};

export default React.memo(FormComponent);
